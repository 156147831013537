import React, { useState } from "react";
import AllInvoices from "../componant/InvoiceComponant/AllInvoices";
import InvoiseSetting from "../componant/InvoiceComponant/InvoiseSetting";
import Preloader from "../componant/Preloader";
import { Navigate } from "react-router-dom";

export default function Invoice(props) {
  const [modal, setmodal] = useState(false);
  const open = () => {
    setmodal(!modal);
  };

  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }

  const [preloader, setPreloader] = useState("");

  const openLoader = () => {
    setPreloader("preShow");
  }
  const closeLoader = () => {
    setPreloader("");
  }
  return (
    <>
      <Preloader show={preloader} />
      {modal && <InvoiseSetting open={open} openLoader={openLoader} closeLoader={closeLoader} showAlert={props.showAlert} />}
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <h4 className="card-header c_heading">
              <div className="row">
                <div className="col-lg-3">
                  <span className="headingcontent">Invoices (0)</span>
                </div>
              </div>

              <div className="heading_buttons"></div>
            </h4>
            <AllInvoices openSetting={open} />
          </div>
        </div>
      </div>
    </>
  );
}

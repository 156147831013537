import React from "react";
export default function AddNewOffca(props) {
  return (
    <>
      <div
        class={"offcanvas offcanvas-end " + props.show}
        style={{ visibility: "visible" }}
        tabindex="-1"
        id="offcanvasAddUser"
        aria-labelledby="offcanvasAddUserLabel"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasAddUserLabel" class="offcanvas-title">
            Add New Staff
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            onClick={props.close}
          ></button>
        </div>
        <div class="offcanvas-body mx-0 flex-grow-0">
          <form
            class="add-new-user pt-0"
            id="addNewUserForm"
            onsubmit="return false"
          >
            <div className=" align-items-sm-center text-center">
              <img
                src="../assets/img/avatars/1.png"
                alt="user-avatar"
                className=" rounded-circle "
                height="60"
                width="60"
                id="uploadedAvatar"
              />

              <div className="button-wrapper text-align-center">
                {/* <label
                        htmlFor="upload"
                        className="btn btn-primary orange-btn me-2"
                        tabIndex="0"
                      >
                        <span className="d-none d-sm-block">
                          Upload
                        </span>
                        <i className="bx bx-upload d-block d-sm-none"></i>
                        <input
                          type="file"
                          id="upload"
                          className="account-file-input"
                          hidden
                          accept="image/png, image/jpeg"
                        />
                      </label> */}
              </div>
            </div>

            <div class="mb-3">
              <label class="form-label" for="add-user-fullname">
                Staff Name
              </label>
              <input
                type="text"
                class="form-control"
                id="add-user-fullname"
                placeholder="Enter Staff Name"
                name="userFullname"
                aria-label="Enter Staff Name"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="add-user-email">
                Email ID
              </label>
              <input
                type="text"
                id="add-user-email"
                class="form-control"
                placeholder="Enter Staff Email Id"
                aria-label="Enter Staff Email Id"
                name="userEmail"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="add-user-contact">
                Contact No.
              </label>
              <input
                type="text"
                id="add-user-contact"
                class="form-control phone-mask"
                placeholder="Enter Staff Phone Number"
                aria-label=""
                name="userContact"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="country">
                Role
              </label>
              <select id="country" class="select2 form-select">
                <option value="">Select Role</option>
                <option value="Australia">Australia</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Belarus">Belarus</option>
                <option value="Brazil">Brazil</option>
                <option value="Canada">Canada</option>
                <option value="China">China</option>
                <option value="France">France</option>
                <option value="Germany">Germany</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Japan">Japan</option>
                <option value="Korea">Korea, Republic of</option>
                <option value="Mexico">Mexico</option>
                <option value="Philippines">Philippines</option>
                <option value="Russia">Russian Federation</option>
                <option value="South Africa">South Africa</option>
                <option value="Thailand">Thailand</option>
                <option value="Turkey">Turkey</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">
                  United Arab Emirates
                </option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
              </select>
            </div>

            <div class="mb-3">
              <label class="form-label" for="user-role">
                Staff ID
              </label>
              <input
                type="password"
                id="add-user-contact"
                class="form-control phone-mask"
                placeholder="Generate Staff ID"
                aria-label=""
                name="userContact"
                autoComplete="off"
              />
            </div>
            <div class="mb-4">
              <label class="form-label" for="user-plan">
                Password
              </label>
              <input
                type="password"
                id="add-user-contact"
                class="form-control phone-mask"
                placeholder="Generate Password"
                aria-label="john.doe@example.com"
                name="userContact"
              />
              <small>
                Staff Id & Password generated by you will be use by your staff
                to login into the system.
              </small>
            </div>

            <button
              type="submit"
              class="btn btn-primary me-sm-3 me-1 data-submit orange-btn"
            >
              Submit
            </button>
            <button
              type="reset"
              class="btn btn-label-primary my-btn"
              data-bs-dismiss="offcanvas"
              onClick={props.close}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
      {props.show_dalog && <div class="offcanvas-backdrop fade show"></div>}
    </>
  );
}

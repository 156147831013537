import { useState } from "react";
import React from "react";

export default function MyButton(props) {
  const [mystyle, setMystyle] = useState({
    left: "0%",
  });
  const setActive = (type) => {
    console.log(type);
    if (type === "active") {
      const data = {
        left: "0%",
      };
      setMystyle(data);
    } else if (type === "pending") {
      const data = {
        left: "33.33%",
      };
      setMystyle(data);
    } else if (type === "block") {
      const data = {
        left: "66.67%",
      };
      setMystyle(data);
    }
  };
  return (
    <>
      <div className="wraper">
        <div className="btn_group">
          <p
            className="btn_two  text-black"
            onClick={() => {
              setActive("active");
            }}
            id="active"
          >
            <span>Active</span>
            <span className="btn_bg" style={mystyle}></span>
          </p>
          <p
            className="btn_one slid_btn_active text-black"
            onClick={() => {
              setActive("pending");
            }}
            id="pending"
          >
            <span>Pending</span>
          </p>
          <p
            className="btn_three"
            onClick={() => {
              setActive("block");
            }}
            id="block"
          >
            <span>Block</span>
          </p>
        </div>
      </div>
    </>
  );
}

export const CartButton = ({
  btnShow = true,
  show = false,
  callback = (type, value) => {},
  value = 0,
  showDelete = false,
}) => {
  return (
    <div className="buttonBadges">
      {show === true ? (
        <>
          <span
            className="mx-1 px-2 py-1"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => {
              if (btnShow) {
                callback("min", 1);
              }
            }}
          >
            {btnShow &&
              (showDelete === true ? <i className="fa fa-trash"></i> : "-")}
          </span>
          <span
            className="mx-1 px-2 py-1"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => {
              if (btnShow) {
                callback("min", 0);
              }
            }}
          >
            {value}
          </span>
          <span
            className="mx-1 px-2 py-1"
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => {
              if (btnShow) {
                callback("plus", 1);
              }
            }}
          >
            {btnShow && "+"}
            {/* + */}
          </span>{" "}
        </>
      ) : (
        <span
          className="mx-3 px-3 py-1"
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={() => {
            show = true;
            callback("addCart", 0);
          }}
        >
          Add
        </span>
      )}
    </div>
  );
};

import React from "react";

const ProductCard = ({
  navigation,
  data,
  show_and_hide,
  setEditObject,
  out_of_stock,
  openDelete,
  show_con,
}) => {
  let price = parseFloat(data.price).toFixed(2);
  let pkg_price = parseFloat(price * data.pkg).toFixed(2);

  let mpkg_price = parseFloat(price * data.pkg * data.mpkg).toFixed(2);

  if (data.p_price.length !== 0) {
    price = parseFloat(data.p_price[0].value).toFixed(2);
    pkg_price = parseFloat(price * data.pkg).toFixed(2);

    mpkg_price = parseFloat(price * data.pkg * data.mpkg).toFixed(2);
  }

  return (
    <>
      <div className="card product-card">
        <div className="row p-2">
          <div className="col-4 d-flex align-items-center">
            <div className="product_card_image">
              <img
                src={data.p_gallery_image[0].link}
                className="p_card-img-top img-fluid "
                alt="..."
              />
            </div>
          </div>
          <div className="col-8">
            <div className="product-card-container">
              <div className="prod-card-title d-flex align-items-center">
                <div className="prod-card-title me-2">{data.p_name}</div>
                <span className="prod-card-brand">{data.brand}</span>
              </div>
              <div className="prod-card-category d-flex ">
                <div className="prod-card-category me-2">
                  {" "}
                  {data.cat_id ? data.cat_id.cat_name : "N/A"}
                </div>
                <div className="prod-card-sub-category">
                  {" ("}
                  {data?.cat_id?.sub_items?.length > 0
                    ? data.cat_id.sub_items.map((item, index) => (
                        <span key={item._id || index}>
                          {item.name}
                          {index < data.cat_id.sub_items.length - 1 && ", "}
                        </span>
                      ))
                    : "N/A"}
                  {")"}
                </div>
              </div>

              <div className="prod-card-attr">
                {data.p_price.length == 0 ? (
                  "N/A"
                ) : (
                  <>
                    {data.p_price[0].name} <i className="fa fa-caret-down" />
                  </>
                )}
              </div>

              <div className="prod-card-price d-flex flex-wrap">
                <div className="prod-card-price me-2">
                  {price}/
                  <span className="per_text">
                    {data.unit_value} {data.unit_name}
                  </span>
                </div>
                <div className="prod-card-pkg-price me-2">
                  {pkg_price}/<span className="per_text">{data.pkg_unit}</span>
                </div>
                <div className="prod-card-mpkg-price">
                  {mpkg_price}/
                  <span className="per_text">{data.mpkg_unit}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dropdown  menu_btn">
          <button
            type="button"
            className="btn p-0 dropdown-toggle hide-arrow"
            data-bs-toggle="dropdown"
          >
            <i className="bx bx-dots-vertical-rounded prod"></i>
          </button>
          <div className="dropdown-menu">
            {data.visibility ? (
              <>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    navigation("/editProduct", {
                      id: data._id,
                    });
                    setEditObject("edit", data);
                  }}
                >
                  <i className="bx bx-edit-alt me-1"></i> Edit
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    show_and_hide(data._id, "hide");
                  }}
                >
                  <i className="bx bx-hide me-1"></i> Hide
                </p>
                {data.hide_inventory ? (
                  <p
                    className="dropdown-item"
                    onClick={() => {
                      out_of_stock(data._id, "show");
                    }}
                  >
                    <i className="bx bx-hide me-1"></i> back in stock
                  </p>
                ) : (
                  <p
                    className="dropdown-item"
                    onClick={() => {
                      out_of_stock(data._id, "hide");
                    }}
                  >
                    <i className="bx bx-hide me-1"></i> out of stock
                  </p>
                )}
                <p
                  className="dropdown-item"
                  onClick={() => {
                    if (typeof show_con === "function") {
                      show_con(data._id);
                    }
                  }}
                >
                  <i className="bx bx-trash me-1"></i> Delete
                </p>
              </>
            ) : (
              <p
                className="dropdown-item"
                onClick={() => {
                  show_and_hide(data._id, "show");
                }}
              >
                <i className="bx bx-show me-1"></i> Show
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;

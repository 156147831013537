import React, { useState } from "react";
import StepNavigation from "../componant/process/StepNavigation";

export default function StepsProcess() {
  const labelArray = ["Step 1", "Step2", "Step3","Step4"];
  const [currentStep, setCurrentStep] = useState(4);
  const updateStep =(step)=>{
    if(step !== 0 && step <= 4){
        setCurrentStep(step)
    }
    
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            
            <StepNavigation labelArray={labelArray} currentStep={currentStep} updateStep={updateStep}></StepNavigation>            
            
            
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import CustomButton from "./CustomButton";
import catContext from "../context/Category/catContext";

const Categoryfilter = ({ clickFunc }) => {
  const [catdata, setcatdata] = useState([]);

  const host = process.env.REACT_APP_URL;

  const getAllCategory = async () => {
    const com_id = localStorage.getItem("com_id");
    if (com_id == null) {
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/category/getCategory/${localStorage.getItem(
        "com_id"
      )}?all=true`,
      requestOptions
    );
    const datares = await response.json();

    let data = datares.data.map((c) => {
      return { ...c, title: c.cat_name };
    });

    data.unshift({ cat_id: 0, cat_name: "All", title: "All" });

    setcatdata(data);
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <div className="category_filer_container">
      <CustomButton
        buttondata={catdata}
        filterfunction={(id) => {
          if (typeof clickFunc === "function") {
            clickFunc(id);
          } else {
            console.log("no function");
          }
        }}
        widthM={"100%"}
        cat_filter={true}
      />
    </div>
  );
};

export default Categoryfilter;

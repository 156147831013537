import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import ComContext from "../context/Company/CompanyContext";
import ConfirmAlert from "../componant/ConfirmAlert/ConfirmAlert";

export default function MyCompany(props) {
  const context = useContext(ComContext);
  const {
    company,
    getallCompany,
    deleteCompany,
    editCompany,
    selectedCompany,
    setSelectedCompany,
    setComImage,
  } = context;

  const primary_c_id = localStorage.getItem("com_id");
  if (primary_c_id === null) {
    // props.showAlert("Please choose Company", "warning");
  } else {
    // setSelectedCompany({"id":primary_c_id, "name":localStorage.getItem("com_name")})
  }

  const [updatCompany, setUpdateCompany] = useState({
    firstName: "",
    lastName: "",
    bus_type: "",
    indus_type: "",
    email: "",
    phone: "",
    address: "",
    state: "",
  });

  // show modal
  const [modal, setModal] = useState(false);
  const [chooseprimary, setPrimary] = useState(false);

  const style = {
    background: "#00000080",
    display: "block",
  };

  const openData = (com_data) => {
    setUpdateCompany(com_data);
    setModal(!modal);

    //console.log(com_data);
  };

  // show modal

  const handleClick = async (e) => {
    e.preventDefault();
    setModal(!modal);
    const restr = await editCompany(updatCompany);
    if (restr.status === "Success") {
      if (com_image !== null) {
        const data = new FormData();
        data.append("file", com_image);
        const uploadImage = await setComImage(data, restr.data._id);
      }
    }
    console.log(restr);
    //console.log(updatCompany);
  };

  useEffect(() => {
    getallCompany();
    if (primary_c_id === null) {
      setPrimary(!chooseprimary);
    }
  }, []);

  const deleteCompanydata = (id) => {
    props.showCon(
      "Are you sure, you want to delete your company? All the associated data will be deleted.",
      true,
      () => {
        props.showCon("", false, {});
        deleteCompany(id);
      }
    );
  };

  const onChange = (e) => {
    setUpdateCompany({ ...updatCompany, [e.target.name]: e.target.value });
  };

  // set companyprimary

  const setPrimaryCompany = async (c_id, c_name) => {
    localStorage.setItem("com_id", c_id);
    localStorage.setItem("com_name", c_name);
    setSelectedCompany({ id: c_id, name: c_name });
    // getallCompany();
    if (chooseprimary) {
      setPrimary(!chooseprimary);
    }
  };

  // upload image
  const inputFile = useRef(null);
  const openField = () => {
    inputFile.current.click();
  };

  const [com_image, setCom_image] = useState(null);

  const selecteFileHandler = (e) => {
    e.preventDefault();
    let images = [];
    console.log(e.target.files);
    setCom_image(e.target.files[0]);
  };

  // confirm Alert data

  return (
    <>
      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Company
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form
                  id="formAccountSettings"
                  method="POST"
                  onSubmit={handleClick}
                >
                  <div className="d-flex align-items-start align-items-sm-center gap-4">
                    <img
                      src={
                        com_image === null
                          ? updatCompany.com_image === ""
                            ? "../assets/img/avatars/1.png"
                            : updatCompany.com_image
                          : URL.createObjectURL(com_image)
                      }
                      alt="user-avatar"
                      className=" rounded-circle "
                      height="140"
                      width="140"
                      id="uploadedAvatar"
                    />
                    <div className="button-wrapper text-align-center">
                      <label
                        className="btn btn-primary orange-btn me-2 mb-2 "
                        tabIndex="0"
                      >
                        <span className="d-none d-sm-block" onClick={openField}>
                          Upload Company Logo
                        </span>
                      </label>
                      <input
                        type="file"
                        id="upload"
                        className="account-file-input d-none"
                        accept="image/png, image/jpeg"
                        ref={inputFile}
                        onChange={selecteFileHandler}
                      />
                      {/* <button
                        type="button"
                        className="btn btn-outline-secondary account-image-reset mb-4"
                      >
                        <i className="bx bx-reset d-block d-sm-none"></i>
                        <span className="d-none d-sm-block">Reset</span>
                      </button> */}

                      <p className="text-muted m-0  instruction">
                        Allowed JPG, JPEG or PNG.
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="mb-3 col-md-6">
                      <label htmlFor="com_name" className="form-label">
                        Company Name <span className="important">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="com_name"
                        name="com_name"
                        onChange={onChange}
                        value={updatCompany.com_name}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="bus_type" className="form-label">
                        Business Type <span className="important">*</span>
                      </label>
                      <select
                        id="bus_type"
                        className="select2 form-select"
                        onChange={onChange}
                        name="bus_type"
                      >
                        <option value="">Choose Type</option>
                        <option value="manufacturer">Manufacturer</option>
                        <option value="wholesaler">Wholesaler</option>
                        <option value="distributor">Distributor</option>
                        <option value="retailer">Retailer</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="indus_type" className="form-label">
                        Industry Type
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="indus_type"
                        id="indus_type"
                        onChange={onChange}
                        value={updatCompany.indus_type}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="email" className="form-label">
                        E-mail
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="email"
                        name="email"
                        onChange={onChange}
                        value={updatCompany.email}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="gst_no" className="form-label">
                        GST No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="gst_no"
                        name="gst_no"
                        onChange={onChange}
                        value={updatCompany.gst_no}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="phone">
                        Phone Number <span className="important">*</span>
                      </label>
                      <div className="input-group input-group-merge">
                        <span className="input-group-text">IN (+91)</span>
                        <input
                          type="text"
                          id="phone"
                          name="phone"
                          className="form-control"
                          onChange={onChange}
                          value={updatCompany.com_phone}
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="address" className="form-label">
                        Address <span className="important">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        name="address"
                        onChange={onChange}
                        value={updatCompany.address}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="state" className="form-label">
                        State <span className="important">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="state"
                        name="state"
                        onChange={onChange}
                        value={updatCompany.state}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="zipCode" className="form-label">
                        Zip Code <span className="important">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="zipCode"
                        name="zipCode"
                        onChange={onChange}
                        value={updatCompany.zipcode}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label className="form-label" htmlFor="country">
                        Country <span className="important">*</span>
                      </label>
                      <select
                        id="country"
                        className="select2 form-select"
                        onChange={onChange}
                        name="country"
                      >
                        <option value="">Select</option>
                        <option value="Australia">Australia</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Brazil">Brazil</option>
                        <option value="Canada">Canada</option>
                        <option value="China">China</option>
                        <option value="France">France</option>
                        <option value="Germany">Germany</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Japan">Japan</option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-2">
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={openData}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                    >
                      Save
                    </button>
                  </div>
                </form>

                {/* <p className="instruction_para">
                  [Customer Name] will recieve an invitation from your Company,
                  when he will accept your invitation your customer will be
                  added.
                </p> */}
              </div>
              {/* <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary orange-btn me-2 mb-2"
                >
                  Create Now
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <Link
              to="/addnewCompany"
              className="btn btn-sm fixed_button btn-outline-primary"
            >
              + Add Company
            </Link>
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Companies ({company.length})
              </span>
            </h4>

            {company.length === 0 && "No Company Found"}
            {company.map((c, i) => {
              let selected = c._id === primary_c_id;

              return (
                <div className="col-lg-6 mb-4 order-0" key={i}>
                  <div
                    className="card"
                    style={{
                      border: `1px solid ${selected ? "orange" : "black"}`,
                    }}
                  >
                    <div className="d-flex align-items-center row">
                      <div className="col-sm-4 px-4 pe-2 text-center ">
                        <img
                          src={
                            c.com_image === ""
                              ? "../assets/img/avatars/1.png"
                              : c.com_image
                          }
                          alt="Avtar"
                          className=" rounded-circle img-fluid "
                          style={{ width: "150px", height: "150px" }}
                        />
                      </div>
                      <div className="col-sm-8 ">
                        <div className="right-edit-align">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="btn p-0 dropdown-toggle hide-arrow"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bx bx-dots-vertical-rounded"></i>
                            </button>
                            <div className="dropdown-menu">
                              <p
                                className="dropdown-item"
                                onClick={() => {
                                  setPrimaryCompany(c._id, c.com_name);
                                }}
                              >
                                <i className="bx bx-check me-1"></i>
                                Set as default
                              </p>
                              <p
                                onClick={() => {
                                  openData(c);
                                }}
                                // to={`/EditCompany/${c._id}`}
                                className="dropdown-item"
                              >
                                <i className="bx bx-edit me-1"></i>
                                Edit
                              </p>

                              <p
                                className="dropdown-item"
                                onClick={() => {
                                  deleteCompanydata(c._id);
                                }}
                              >
                                <i className="bx bx-trash me-1"></i>
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <h5 className="card-title text-primary companyTitle">
                            {c.com_name}
                          </h5>
                          <ul className="customerDetailList">
                            <li>
                              <small className="muted">Add. - </small>
                              {c.address}
                            </li>
                            <li className="ml-5">
                              {c.state}, {c.country} ({c.zipcode})
                            </li>
                            <li>
                              <small className="muted">Ph. - </small>
                              {c.com_phone}
                            </li>
                            <li>
                              <small className="muted">Email - </small>
                              {c.email}
                            </li>
                            <li>
                              <small className="muted">GST - </small>
                              {c.gst_no}
                            </li>
                            <li>
                              <small className="muted">Type - </small>
                              {c.bus_type}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatDateWithComma = (createdAt) => {
  const dateObj = new Date(createdAt);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("en-US", { month: "short" });
  const year = dateObj.getFullYear();
  return `${day} ${month}, ${year}`;
};

export default formatDateTime;

import React, { useContext, useEffect } from "react";

import { Link, Navigate } from "react-router-dom";
import OrderStatics from "./DashboardCard/OrderStatics";
import ComContext from "../context/Company/CompanyContext";

export default function ContentWrapper(props) {
  const comContext = useContext(ComContext);
  const { getDashDatam, getDash } = comContext;

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      getDash();
    }
  }, []);

  if (localStorage.getItem("token") === null) {
    return <Navigate to="/login" />;
  }

  const showalert = () => {
    props.showAlert("danger", "danger");
  };

  return (
    <>
      <div className="content-wrapper">
        {/* <!-- Content --> */}

        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            {/* <div className="col-lg-8 mb-4 order-0">
              <div className="card">
                <div className="d-flex align-items-end row">
                  <div className="col-sm-7">
                    
                  </div>
                  <div className="col-sm-5 text-center text-sm-left">
                    <div className="card-body pb-0 px-0 px-md-4">
                      <img
                        src="../assets/img/illustrations/man-with-laptop-light.png"
                        height="140"
                        alt="View Badge User"
                        data-app-dark-img="illustrations/man-with-laptop-dark.png"
                        data-app-light-img="illustrations/man-with-laptop-light.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 order-1">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="cardOpt3"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardOpt3"
                          >
                            <p className="dropdown-item">View More</p>
                            <p className="dropdown-item">Delete</p>
                          </div>
                        </div>
                      </div>
                      <span className="fw-semibold d-block mb-1">
                        Total Payable
                      </span>
                      <h3 className="card-title mb-2">
                        ₹
                        {parseFloat(
                          getDashDatam.payable ? getDashDatam.payable : 0
                        ).toFixed(2)}
                      </h3>
                      {/* <small className="text-success fw-semibold">
                        <i className="bx bx-up-arrow-alt"></i> +72.80%
                      </small> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/wallet-info.png"
                            alt="Credit Card"
                            className="rounded"
                          />
                        </div>
                        <div className="dropdown">
                          <button
                            className="btn p-0"
                            type="button"
                            id="cardOpt6"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardOpt6"
                          >
                            <p className="dropdown-item">View More</p>
                            <p className="dropdown-item">Delete</p>
                          </div>
                        </div>
                      </div>
                      <span className="fw-semibold d-block mb-1">
                        Total Receivable
                      </span>
                      <h3 className="card-title text-nowrap mb-2">
                        ₹
                        {parseFloat(
                          getDashDatam.out_standing
                            ? getDashDatam.out_standing
                            : 0
                        ).toFixed(2)}
                      </h3>
                      {/* <small className="text-success fw-semibold">
                        <i className="bx bx-up-arrow-alt"></i> +28.42%
                      </small> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <span>Top Selling</span>
                      {getDashDatam.top_p &&
                        getDashDatam.top_p.map((pro, index) => {
                          return (
                            <span className="d-block card-title text-nowrap mb-1">
                              {pro.p_name}
                            </span>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <OrderStatics /> */}
        </div>

        <footer className="content-footer footer bg-footer-theme">
          <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
              ©<script>document.write(new Date().getFullYear());</script>{" "}
              MYORDERSLIP, made with ❤️ by
              <Link to="https://vipsgrow.in" className="footer-link fw-bolder">
                VIPs Grow
              </Link>
            </div>
          </div>
        </footer>

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Preloader from "../../componant/Preloader";
import SupplierContext from "../../context/GetSupplier/SupplierContext";
import { CartButton } from "../../componant/MyButton";
export default function MyCart(props) {
  const context = useContext(SupplierContext);
  const {
    cartProducts,
    getCartProduct,
    setCartProduct,
    removeFromCart,
    updateCartQty,
    createOrder,
  } = context;
  const [preloader, setPreloader] = useState("");
  useEffect(() => {
    getCartProduct();
  }, []);

  let grand = 0;
  const removeItemFromCart = async (id) => {
    setPreloader("preShow");
    const res = await removeFromCart(id);
    if (res === "Success") {
      props.showAlert("Product has been removed", "success");
    } else {
      props.showAlert("Something Went Wrong", "danger");
    }
    setPreloader("");
  };

  const onchangeQty = (newqty, id) => {
    const updateCart = cartProducts.map((pro) => {
      return pro._id === id ? { ...pro, qty: newqty } : pro;
    });
    updateCartQty(id, newqty);
    setCartProduct(updateCart);
  };

  // place order code is here

  const placeOrder = async () => {
    setPreloader("preShow");
    let myArr = [];
    let total_price = 0;
    let price = 0;
    for (let i = 0; i < cartProducts.length; i++) {
      const modal = cartProducts[i];

      const productdata = modal.product_id;
      let catlog_data = modal.catlog_id;

      if (modal.sp_rate !== null) {
        const spdata = modal.sp_rate;
        catlog_data.discount = spdata.discount;
        catlog_data.flattDiscount = spdata.flattDiscount;
      }

      let mrp_price = parseFloat(productdata.price);
      if (modal.attr_id !== "") {
        mrp_price = parseFloat(
          productdata.p_price.filter((p, i) => {
            return p._id === modal.attr_id;
          })[0].value
        );
      }

      price = catlog_data.flattDiscount
        ? mrp_price - catlog_data.discount
        : (mrp_price * (100 - catlog_data.discount)) / 100;

      if (modal.type === "mpkg") {
        price =
          price *
          parseFloat(modal.product_id.mpkg) *
          parseFloat(modal.product_id.pkg);
      } else if (modal.type === "pkg") {
        price = price * parseFloat(modal.product_id.pkg);
      }

      if (myArr.length === 0) {
        myArr.push({
          cart_id: modal._id,
          catlog_id: catlog_data,
          c_id: productdata.c_id,
          catuserid: catlog_data.userid,
          product_id: productdata,
          qty: modal.qty,
          type: modal.type,
          price: modal.price,
          key: modal.key,
          attr_id: modal.attr_id,
          sp_rate: modal.sp_rate,
          supplier_name: "Vishu",
        });

        total_price = price * parseInt(modal.qty);
      } else {
        const check = myArr.some(
          (item) =>
            catlog_data.userid === item.catuserid &&
            catlog_data.c_id === item.c_id
        );
        if (check) {
          myArr.push({
            cart_id: modal._id,
            catlog_id: catlog_data,
            c_id: productdata.c_id,
            catuserid: catlog_data.userid,
            product_id: productdata,
            qty: modal.qty,
            type: modal.type,
            price: modal.price,
            key: modal.key,
            attr_id: modal.attr_id,
            sp_rate: modal.sp_rate,
            supplier_name: "Vishu",
          });

          total_price += price * parseInt(modal.qty);
        }
      }
    }

    const orderarr = {
      supplier_id: myArr[0].catuserid,
      supplier_c_id: myArr[0].c_id,
      order_list: myArr,
      total_price,
      create_by: "user",
    };
    console.log(myArr);
    const response = await createOrder(orderarr);
    if (response.status === "Success") {
      for (let i = 0; i < myArr.length; i++) {
        const element = myArr[i];
        await removeFromCart(element.cart_id);
      }
      props.showAlert("Order Has Been Created", "success");
      setPreloader("");
      getCartProduct();
    } else {
      props.showAlert("Something Went Wrong", "danger");
      setPreloader("");
    }

    // console.log(myArr);
  };

  return (
    <>
      <Preloader show={preloader} />
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className="btn btn-sm fixed_button btn-outline-primary"
              onClick={() => {
                placeOrder();
              }}
            >
              Place order
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-12 ">
              <span className="supplier_name">My Cart</span>
              <br />
              <small className="p-0  ">
                You've selected the products from {"three "}different suppliers
                so your {"three "}
                different orders will be created simultaneously.
              </small>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">
                          Total Products ({cartProducts.length}){" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>S No.</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Total Amount</th>
                        {/* <th className="action">Action</th> */}
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body cart_table">
                      {cartProducts.map((cart, index) => {
                        let price = parseFloat(cart.price);

                        let catlog_data = cart.catlog_id;
                        let qty = cart.qty;

                        if (cart.type === "mpkg") {
                          qty = cart.product_id.mpkg * cart.product_id.pkg;
                        } else if (cart.type === "pkg") {
                          qty = cart.product_id.pkg;
                        } else {
                          qty = parseInt(cart.product_id.unit_value);
                        }

                        if (cart.sp_rate !== null) {
                          const sp_data = cart.sp_rate;
                          catlog_data.flattDiscount = sp_data.flattDiscount;
                          catlog_data.discount = sp_data.discount;
                        }

                        if (cart.attr_id !== "") {
                          const att = cart.product_id.p_price.filter((attr) => {
                            return attr._id === cart.attr_id;
                          });

                          if (att.length > 0) {
                            price = att[0].value;
                          }
                        } else {
                          price = cart.product_id.price;
                        }

                        let salePrice = price;
                        salePrice = catlog_data.flattDiscount
                          ? price - catlog_data.discount
                          : (price * (100 - catlog_data.discount)) / 100;

                        price = price * qty;

                        salePrice = salePrice * qty;

                        const total = salePrice * parseInt(cart.qty);
                        grand = grand + total;

                        let attr_name = "No Attribute";
                        if (cart.attr_id !== undefined && cart.attr_id !== "") {
                          const attr_data = cart.product_id.p_price.filter(
                            (p) => {
                              return p._id === cart.attr_id;
                            }
                          );

                          attr_name =
                            attr_data.length > 0
                              ? attr_data[0].name
                              : "No Attr";
                        }

                        const pkg_level = cart.product_id.pkg_level;

                        const showIcon = deleteIconShow(
                          cart.qty,
                          cart.product_id.m_o_q,
                          cart.type,
                          pkg_level
                        );
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <img
                                src={cart.product_id.p_gallery_image[0].link}
                                alt="image"
                                className="img-fluid"
                                width={50}
                              />
                            </td>
                            <td>
                              {cart.product_id.p_name} ( {attr_name} )
                              <br />
                              <small>
                                <i
                                  className="bx bx-trash"
                                  onClick={() => {
                                    removeItemFromCart(cart._id);
                                  }}
                                ></i>
                              </small>
                            </td>
                            <td>
                              {" "}
                              ₹{parseFloat(salePrice).toFixed(2)} (
                              <small>
                                <strike>₹{parseFloat(price).toFixed(2)}</strike>
                                /{cart.key}
                              </small>
                              )
                            </td>
                            <td className="cartqty">
                              <CartButton
                                show={true}
                                value={cart.qty}
                                showDelete={showIcon}
                                callback={(type, value) => {
                                  console.log(
                                    "type = " + type + " | value = " + value
                                  );

                                  if (type === "min") {
                                    let add_qty = 0;
                                    const moq = parseInt(cart.product_id.m_o_q);
                                    let qtydata = parseInt(cart.qty);
                                    if (
                                      cart.type === "pkg" ||
                                      cart.type === "mpkg"
                                    ) {
                                      if (
                                        pkg_level === 0 ||
                                        pkg_level === 111
                                      ) {
                                        add_qty = 1;
                                      } else if (pkg_level === 211) {
                                        add_qty = cart.type === "pkg" ? moq : 1;
                                      } else if (
                                        pkg_level === 221 &&
                                        cart.type === "mpkg"
                                      ) {
                                        add_qty = moq;
                                      }
                                    } else {
                                      add_qty = parseInt(moq);
                                    }

                                    if (qtydata > add_qty) {
                                      qtydata -= 1;
                                      onchangeQty(parseInt(qtydata), cart._id);
                                    } else {
                                      alert(
                                        "are you sure? you want to delete this product"
                                      );
                                    }
                                  } else if (type === "plus") {
                                    onchangeQty(
                                      parseInt(cart.qty) + value,
                                      cart._id
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td className="cartTotal">
                              ₹{parseFloat(total).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className="cart-footer">
                      <tr>
                        <td colSpan={3}></td>
                        <td>Grand Total</td>
                        <td className="cart_total">
                          ₹{parseFloat(grand).toFixed(2)}
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const deleteIconShow = (qty, moq, label, level) => {
  qty = parseInt(qty);
  moq = parseInt(moq);

  let res = false;

  if (qty === moq) {
    res = true;
    if ((level == 0 || level == 111) && !label === "unit") {
      res = false;
    } else if (level == 211 && !label === "pkg") {
      res = false;
    } else if (level == 221) {
      res = label === "pkg" ? false : true;
    }
  } else {
    if ((level == 211 || level == 0 || level == 111) && qty == 1) {
      res = true;
    } else {
      res = false;
    }
  }

  return res;
};

import React, { useContext } from "react";
import SupplierContext from "../../context/GetSupplier/SupplierContext";
import { useEffect } from "react";
import { CartButton } from "../../componant/MyButton";

const Add_more_product = ({ open, id, type, addProductCallBack }) => {
  const context = useContext(SupplierContext);
  const {
    allSupplierProducts,
    getSupplierproducts,
    setSupplierProducts,
    getToken,
  } = context;

  useEffect(() => {
    const getData = async () => {
      if (allSupplierProducts.length === 0) {
        if (id) {
          if (type !== "pts") {
            getSupplierproducts(false, id);
          } else {
            const user_data = JSON.parse(localStorage.getItem("user_data"));
            const res = await getToken(id);
            getSupplierproducts(
              false,
              user_data._id,
              1,
              null,
              null,
              "",
              res.token
            );
          }
        }
      }
    };

    getData();
  }, []);

  const updateProductPrice = (value, id, p_id, cat_id) => {
    const updatedat = allSupplierProducts.map((pro) => {
      return pro.product_data._id === p_id && pro.catdata._id === cat_id
        ? { ...pro, price: value, attr_id: id }
        : pro;
    });
    setSupplierProducts(updatedat);
  };
  const sendToCart = (
    type,
    price,
    key,
    qty,
    product,
    catlog,
    attr_id,
    sp_rate,
    total_price
  ) => {
    try {
      const data = {
        catlog_id: catlog,
        product_id: product,
        attr_id: attr_id,
        sp_rate: sp_rate,
        type: type,
        price: price,
        key: key,
        qty: qty,
        total_price: total_price,
      };

      addProductCallBack(data);
      // addCart(data);

      // const updateCart = allSupplierProducts.map((pro, i) => {
      //   return pro.product_data._id === product._id;
      // });

      // setMycart({
      //   ...mycart,
      //   product_id: pro.product_data._id,
      //   catlog_id: pro.catdata._id,

      //   type,
      //   key,
      //   price: pro.price,
      // });
    } catch (error) {
      console.log("error - ", error);
    }
  };

  return (
    <div
      className={`modal reviewModal`}
      style={{
        background: "#00000080",
        display: "block",
      }}
    >
      <div className="modal-dialog" style={{ maxWidth: "auto" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="exampleModalLabel"
              style={mystyle.heading}
            >
              Add More Product
            </h5>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={open}
            ></button>
          </div>
          <div
            className="modal-body"
            style={{
              maxHeight: "40em",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {/* <p className="confirmcontent p-0 m-0" style={mystyle.content}>
              
            </p> */}

            <div className="">
              <ul
                className=""
                style={{ listStyle: "none", margin: 0, padding: 0 }}
              >
                <li style={mystyle.list_item} className="px-2 py-2">
                  <span>PRODUCT</span>
                  <span>CHOOSE VARIATION</span>
                  <span>CHOOSE PRICING/PACKAGING</span>
                </li>
                {allSupplierProducts.map((pro, index) => {
                  const m_price =
                    pro.product_data.p_price &&
                    pro.product_data.p_price.length > 0
                      ? pro.attr_id !== ""
                        ? pro.attr_id !== undefined &&
                          pro.product_data.p_price.some(
                            (p) => p._id === pro.attr_id
                          )
                          ? pro.product_data.p_price.filter(
                              (p) => p._id === pro.attr_id
                            )[0].value
                          : parseFloat(pro.product_data.p_price[0].value)
                        : parseFloat(pro.product_data.price)
                      : parseFloat(pro.product_data.price);

                  let catlog = pro.catdata;
                  if (pro.sp !== null && pro.sp !== "") {
                    const spdata = pro.sp;
                    catlog.discount = parseFloat(spdata.discount);
                    catlog.flattDiscount = spdata.flattDiscount;
                  }

                  let pcs_mrp = m_price;
                  let pkg_mrp = m_price * pro.product_data.pkg;
                  let mpkg_mrp =
                    m_price * pro.product_data.pkg * pro.product_data.mpkg;
                  let pkg_level = pro.product_data.pkg_level;
                  let moq_level = "";

                  if (pkg_level == 111 || pkg_level == 0) {
                    moq_level = pro.product_data.unit_name;
                  } else if (pkg_level == 211) {
                    moq_level = pro.product_data.pkg_unit;
                  } else if (pkg_level == 221) {
                    moq_level = pro.product_data.mpkg_unit;
                  } else {
                    moq_level = "null";
                  }

                  let discounted_price = catlog.flattDiscount
                    ? m_price - parseFloat(catlog.discount)
                    : (m_price * (100 - parseFloat(catlog.discount))) / 100;

                  let pcs_price = discounted_price.toFixed(2);

                  let pkg_price = (
                    discounted_price * pro.product_data.pkg
                  ).toFixed(2);

                  let mpkg_price = (
                    discounted_price *
                    pro.product_data.pkg *
                    pro.product_data.mpkg
                  ).toFixed(2);

                  return (
                    <li style={mystyle.list_item} key={index}>
                      <span
                        style={{
                          overflow: "hidden",
                          width: "30%",
                          border: "1px solid",
                        }}
                      >
                        <img
                          src={
                            pro.product_data.p_gallery_image.length > 0
                              ? pro.product_data.p_gallery_image[0].link
                              : "logo"
                          }
                          className="img-fluid me-2"
                          width={50}
                        />
                        <span className="" style={{ overflow: "hidden" }}>
                          {pro.product_data.p_name.length > 10
                            ? pro.product_data.p_name.substring(0, 18) + " ..."
                            : pro.product_data.p_name}
                        </span>
                      </span>
                      <span style={{ width: "20%", border: "1px solid" }}>
                        {pro.product_data.p_price.length === 0 ? (
                          "Attribute N/A"
                        ) : (
                          <ul
                            className="d-flex flex-wrap m-0 p-0"
                            style={{ listStyle: "none" }}
                          >
                            {pro.product_data.p_price.map((attprice, index) => {
                              return (
                                <li className={`variationList me-2 `}>
                                  <span
                                    onClick={() => {
                                      updateProductPrice(
                                        attprice.value,
                                        attprice._id,
                                        pro.product_data._id,
                                        pro.catdata._id
                                      );
                                    }}
                                    className={`variation  ${
                                      attprice._id === pro.attr_id
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    {attprice.name}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </span>
                      <span
                        className="me-2"
                        style={{ width: "40%", border: "1px solid" }}
                      >
                        <div className="row">
                          <div className="col-8">
                            <ul className="pricingList">
                              <li
                                onClick={() => {
                                  //   setAttrPrice(
                                  //     "unit",
                                  //     pro.product_data.unit_name,
                                  //     pro
                                  //   );
                                }}
                              >
                                <strike>
                                  ₹{parseFloat(pcs_mrp).toFixed(2)}
                                </strike>{" "}
                                <span
                                  className="font-bold"
                                  style={{ color: "black" }}
                                >
                                  ₹{parseFloat(pcs_price).toFixed(2)} /{" "}
                                </span>
                                <small>
                                  {pro.product_data.unit_value}{" "}
                                  {pro.product_data.unit_name}
                                </small>
                              </li>

                              <li
                                onClick={() => {
                                  //   setAttrPrice(
                                  //     "pkg",
                                  //     pro.product_data.pkg_unit,
                                  //     pro
                                  //   );
                                }}
                              >
                                <strike>
                                  ₹{parseFloat(pkg_mrp).toFixed(2)}
                                </strike>{" "}
                                <span
                                  className="font-bold"
                                  style={{ color: "black" }}
                                >
                                  ₹{parseFloat(pkg_price).toFixed(2)} /{" "}
                                </span>
                                <small>
                                  {pro.product_data.pkg_unit +
                                    "(" +
                                    pro.product_data.pkg +
                                    " " +
                                    pro.product_data.unit_name +
                                    ")"}
                                </small>
                              </li>

                              {parseInt(pro.product_data.mpkg) !== 0 && (
                                <li
                                  onClick={() => {
                                    // setAttrPrice(
                                    //   "mpkg",
                                    //   pro.product_data.mpkg_unit,
                                    //   pro
                                    // );
                                  }}
                                >
                                  <strike>
                                    ₹{parseFloat(mpkg_mrp).toFixed(2)}
                                  </strike>{" "}
                                  <span
                                    className="font-bold"
                                    style={{ color: "black" }}
                                  >
                                    ₹{parseFloat(mpkg_price).toFixed(2)} /{" "}
                                  </span>
                                  <small>
                                    {pro.product_data.mpkg +
                                      " " +
                                      pro.product_data.mpkg_unit}
                                  </small>
                                </li>
                              )}
                            </ul>
                          </div>

                          <div className="col-4 align-items-center d-flex">
                            <ul className="pricingList">
                              <li style={{ background: "none" }}>
                                <CartButton
                                  show={
                                    pro.showBtn !== undefined &&
                                    pro.showBtn === "unit"
                                  }
                                  callback={(type, value) => {
                                    console.log("cart button - ", type);
                                    if (type === "addCart") {
                                      sendToCart(
                                        "unit",
                                        pcs_mrp,
                                        pro.product_data.unit_name,
                                        1,
                                        pro.product_data,
                                        pro.catdata,
                                        pro.attr_id,
                                        pro.sp,
                                        parseFloat(pcs_price)
                                      );
                                    } else if (type === "min") {
                                      //   unitCartQty -= 1;
                                    }
                                  }}
                                  value={pro.cart_qty}
                                />
                              </li>
                              {parseInt(pro.product_data.pkg) !== 0 && (
                                <li style={{ background: "none" }}>
                                  <CartButton
                                    show={
                                      pro.showBtn1 !== undefined &&
                                      pro.showBtn1 === "pkg"
                                    }
                                    callback={(type, value) => {
                                      sendToCart(
                                        "pkg",
                                        pcs_mrp,
                                        pro.product_data.pkg_unit,
                                        1,
                                        pro.product_data,
                                        pro.catdata,
                                        pro.attr_id,
                                        pro.sp,
                                        parseFloat(pkg_price)
                                      );
                                    }}
                                    value={pro.cart_qty1}
                                  />
                                </li>
                              )}
                              {parseInt(pro.product_data.mpkg) !== 0 && (
                                <li style={{ background: "none" }}>
                                  <CartButton
                                    show={
                                      pro.showBtn2 !== undefined &&
                                      pro.showBtn2 === "mpkg"
                                    }
                                    callback={(type, value) => {
                                      sendToCart(
                                        "mpkg",
                                        pcs_mrp,
                                        pro.product_data.mpkg_unit,
                                        1,
                                        pro.product_data,
                                        pro.catdata,
                                        pro.attr_id,
                                        pro.sp,
                                        parseFloat(mpkg_price)
                                      );
                                    }}
                                    value={pro.cart_qty2}
                                  />
                                </li>
                              )}
                            </ul>

                            <form method="post" className="d-none">
                              <div className="input-group product_qty">
                                <input
                                  type="number"
                                  className="form-control product_no"
                                  aria-label=""
                                  placeholder="QTY"
                                  name="qty"
                                  defaultValue={1}
                                  //   onChange={onQtyChange}
                                />
                                <button
                                  className="btn btn-outline-primary "
                                  type="submit"
                                  onClick={(e) => {
                                    // addToCart(e, pro);
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mystyle = {
  content: {
    color: "black",
    "font-family": "pop_regular",
  },
  heading: {
    color: "black",
    "font-family": "pop_bold",
  },
  button_container: {
    "border-top": "1px solid gray",
  },
  list_item: {
    overflow: "hidden",
    background: "#7272712b",
    marginBottom: 10,
    borderRadius: 5,
    color: "#383838",
    "font-family": "pop_regular",
    fontWeight: 500,
    fontSize: 15,
    cursor: "pointer",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
export default Add_more_product;

import React, { useContext, useEffect, useState } from "react";

import CusContext from "../context/Customer/CustomerContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function CustomerDetails() {
  const mycontext = useContext(CusContext);
  const { myCustomer, getAllCustomer } = mycontext;

  const location = useLocation();
  const navigate = useNavigate();
  const cus_item = location.state;

  console.log("====================================");
  console.log(cus_item);
  console.log("====================================");

  useEffect(() => {
    getAllCustomer();

    if (location.state === null) {
      navigate("/customers");
    }
  }, []);

  //#ff0000
  const getBadgeStyle = (status) => {
    let statusColor = "#6c757d"; // Default color

    switch (status.toLowerCase()) {
      case "pending":
        statusColor = "#273D8E";
        break;
      case "confirmed":
        statusColor = "#FDD835";
        break;
      case "packed":
        statusColor = "#312113";
        break;
      case "dispatched":
        statusColor = "#8900CFE0";
        break;
      case "delivered":
        statusColor = "#49A752";
        break;
      case "rejected":
      case "cancelled":
        statusColor = "#EA2B2B";
        break;
      default:
        statusColor = "#6c757d"; // Secondary default color
        break;
    }

    return { backgroundColor: statusColor, color: "white" };
  };

  return (
    <>
      <div className="content-wrapper mt-4">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-7 mb-4 order-0 mx-auto">
              <div className="card">
                <div className="d-flex align-items-center row">
                  <div className="col-sm-5 p-5 text-center ">
                    <img
                      src="../assets/img/avatars/1.png"
                      alt="Avtar"
                      className=" rounded-circle "
                    />
                  </div>
                  <div className="col-sm-7 ">
                    <div className="card-body">
                      <h4 className="card-title text-primary customerDetailsCompany">
                        {cus_item.nick_name}
                      </h4>
                      <ul className="customerDetailList">
                        <li>
                          {cus_item.com_data
                            ? cus_item.com_data.com_name
                            : "Company N/A"}
                        </li>
                        {/* <li>Saket Colony Hathras utter pradesh </li> */}
                        <li>{cus_item.customer_id.phone}</li>

                        <li>Address - N/A</li>
                        <li>GSTIN</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 order-0">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Outstanding Balance
                      </span>
                      <h3 className="card-title ">
                        ₹{parseFloat(cus_item.out_standing).toFixed(2)}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Payments
                      </span>
                      <h3 className="card-title ">₹{cus_item.out.total_pay}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Total Orders Value
                      </span>
                      <h3 className="card-title ">
                        ₹{parseFloat(cus_item.order).toFixed(2)}
                        {/* {cus_item.out.orders
                          .reduce(
                            (acc, order) => acc + parseFloat(order.total_price),
                            0
                          )
                          .toFixed(2)} */}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Set Limit
                      </span>
                      <h3 className="card-title ">₹{cus_item.order_limit}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <ul className="slider_button slider-white">
                    <li className="slider-active">Order History</li>
                    {/* <li>Payment History</li> */}
                  </ul>
                </h5>
                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Order ID</th>
                        <th>Placed On</th>
                        <th>Total Items</th>
                        <th>Order Value</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {cus_item.out.orders.map((ord, id) => {
                        return (
                          <tr
                            key={id}
                            onClick={() => {
                              navigate("/ordersDetails/" + ord.order_id);
                            }}
                          >
                            <td>#{ord.f_order_id}</td>
                            <td>
                              {new Date(ord.createdAt).toLocaleDateString()}
                            </td>
                            <td>{ord.order_list.length}</td>
                            <td>₹{parseFloat(ord.total_price).toFixed(2)}</td>
                            <td>
                              <span
                                style={getBadgeStyle(ord.order_status)}
                                className="badge me-1"
                              >
                                {ord.order_status}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/*  <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Mode</th>
                        <th>Collected By</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      <tr>
                        <td>5,00,00</td>
                        <td>30/09/2022</td>
                        <td>Cash</td>
                        <td>Collected By</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

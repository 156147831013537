import React, { useEffect, useState } from "react";
import PartyStateMent from "../componant/ReportComponent/PartyStatement";
import SaleSummary from "../componant/ReportComponent/SaleSummary";
import StockSummary from "../componant/ReportComponent/StockSummary";
import { Navigate } from "react-router-dom";
//require("dotenv").config();
export default function Reports() {
  const host = process.env.REACT_APP_URL;

  const [clickEvnt, setClickEvent] = useState(2);
  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }
  return (
    <>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <h4 className="card-header c_heading">
              <div className="row">
                <div className="col-lg-3">
                  <span className="headingcontent">Reports</span>
                </div>
                <div className="col-lg-3 report_filter_card ">
                  <div
                    className={`card ${clickEvnt === 1 ? "active" : ""}`}
                    onClick={() => {
                      setClickEvent(1);
                    }}
                  >
                    <div className="card-body">Sale Summary</div>
                  </div>
                </div>
                <div className="col-lg-3 report_filter_card">
                  <div
                    className={`card ${clickEvnt === 2 ? "active" : ""}`}
                    onClick={() => {
                      setClickEvent(2);
                    }}
                  >
                    <div className="card-body">Party Statement (Ledger)</div>
                  </div>
                </div>
                <div className="col-lg-3 report_filter_card">
                  <div
                    className={`card ${clickEvnt === 3 ? "active" : ""}`}
                    onClick={() => {
                      setClickEvent(3);
                    }}
                  >
                    <div className="card-body">Stock Summary</div>
                  </div>
                </div>
              </div>

              <div className="heading_buttons"></div>
            </h4>

            {clickEvnt === 1 ? <SaleSummary /> : ""}
            {clickEvnt === 2 ? <PartyStateMent /> : ""}
            {clickEvnt === 3 ? <StockSummary /> : ""}
          </div>
        </div>
      </div>
    </>
  );
}

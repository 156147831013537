import React, { useState } from "react";
import productContext from "./productContext";
import Inventory1 from "../../pages/Inventory1";
//require("dotenv").config();

const ProductState = (props) => {
  const host = process.env.REACT_APP_URL;
  const products = [];
  const [product, setProduct] = useState(products);
  const [totalCount, setTotalCount] = useState(0);
  const [totalInventory, setTotalInventory] = useState(0);
  const [editObj, setEditObj] = useState(null);

  const setEditObject = (status, obj) => {
    if (status === "clear") {
      setEditObj(null);
    } else {
      setEditObj(obj);
    }
  };

  const getAllProductInventoy = async (
    loadMore = false,
    page = 1,
    query = "",
    type = "all",
    call_back = (e) => {},
    cat_id = null
  ) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/product/getInventoryData/${localStorage.getItem(
        "com_id"
      )}?filter_type=${type}${
        query === "" ? "" : `&s_query=${query}`
      }&page=${page}${cat_id !== null ? `&cat_id=${cat_id}` : ""}`,
      requestOptions
    );
    const datares = await response.json();

    // console.log(datares);
    // console.log(localStorage.getItem("com_id"));

    if (loadMore) {
      setProduct(product.concat(datares.data));
    } else {
      setTotalCount(datares.totalCount);
      setTotalInventory(datares.totalProducts);
      setProduct(datares.data);
    }

    call_back(datares.data.length);
  };

  const updateInventory = async (sendData, id, callback) => {
    try {
      const host = process.env.REACT_APP_URL;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(sendData),
      };
      const response = await fetch(
        `${host}/api/inventory/updateInventory/${id}`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        setProduct(
          product.map((pro) => {
            if (id === "attr") {
              return pro.p_price && pro.p_price._id === sendData.attr_id
                ? {
                    ...pro,
                    p_price: { ...pro.p_price, inventory: sendData.inventory },
                  }
                : pro;
            } else {
              return pro.p_price._id === sendData.attr_id
                ? {
                    ...pro,
                    inventory: sendData.inventory,
                  }
                : pro;
            }
          })
        );
      }
      if (typeof callback === "function") {
        callback({ s: datares.status, msg: datares.msg });
      }
    } catch (error) {
      console.log("error - ", error);
    }
  };

  //   get all product
  const getAllProduct = async (
    loadMore = false,
    page = 1,
    query = "",
    type = true,
    call_back = (e) => {},
    cat_id = null
  ) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/product/getSaleProducts/${localStorage.getItem(
        "com_id"
      )}?type=${type}${query === "" ? "" : `&s_query=${query}`}&page=${page}${
        cat_id !== null ? `&cat_id=${cat_id}` : ""
      }`,
      requestOptions
    );
    const datares = await response.json();

    console.log(datares);
    // console.log(localStorage.getItem("com_id"));

    if (loadMore) {
      setProduct(product.concat(datares.data));
    } else {
      setTotalCount(datares.totalCount);
      setTotalInventory(datares.totalProducts);
      setProduct(datares.data);
    }

    call_back(datares.data.length);
  };

  const getAllWareHouseProduct = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/product/getGodownProducts/${localStorage.getItem("com_id")}`,
      requestOptions
    );
    const datares = await response.json();

    // console.log(datares);
    // console.log(localStorage.getItem("com_id"));
    setProduct(datares.data);
  };

  // when you want to delete product
  const deleteProduct = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const url = `${host}/api/product/deleteProduct/` + id;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();
      if (datares.status === "Success") {
        const newProduct = product.filter((com) => {
          return com._id !== id;
        });
        setProduct(newProduct);
      } else {
        alert("Something wentwrong");
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  // get by checked
  const getAllProductbyChecked = async (
    data,
    query = "",
    page = 1,
    cat_id = null
  ) => {
    // console.log("my checked data");
    // console.log(data);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    // const response = await fetch(
    //   `${host}/api/product/getProducts/${localStorage.getItem("com_id")}`,
    //   requestOptions
    // );
    const response = await fetch(
      `${host}/api/product/getSaleProducts/${localStorage.getItem(
        "com_id"
      )}?type=${true}${query === "" ? "" : `&s_query=${query}`}&page=${page}${
        cat_id !== null ? `&cat_id=${cat_id}` : ""
      }`,
      requestOptions
    );
    const datares = await response.json();
    const newData = datares.data;
    for (let i = 0; i < newData.length; i++) {
      let element = newData[i];
      for (let j = 0; j < data.products.length; j++) {
        const catData = data.products[j];

        // console.log("match data - " + catData.p_id + " - " + element._id);

        if (catData.p_id.toString() === element._id) {
          element.ischecked = true;
          break;
        } else {
          element.ischecked = false;
        }
      }
    }
    setProduct(newData);
    // console.log(newData);
  };

  //   addnew product
  const addProduct = async (com_name) => {
    try {
      const bodydata = com_name;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/product/createProduct`,
        requestOptions
      );
      const datares = await response.json();

      const comp = datares.data;
      setProduct(product.concat(comp));
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  // set image in product
  const updateProduct = async (formdata, id) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
        body: formdata,
      };
      const response = await fetch(
        `${host}/api/product/uploadProductImage/${id}`,
        requestOptions
      );
      const datares = await response.json();

      const comp = datares.data;
      let oldproduct = JSON.parse(JSON.stringify(product));
      for (let index = 0; index < oldproduct.length; index++) {
        const element = oldproduct[index];
        if (element._id === id) {
          oldproduct[index] = comp;
          //console.log(newCompany);
          break;
        }
      }
      setProduct(oldproduct);
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  const editProduct = async (formdata, id) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(formdata),
      };
      const response = await fetch(
        `${host}/api/product/editproduct/${id}`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Success") {
        const u_data = product.map((pro) => {
          if (pro._id === id) {
            return datares.data;
          } else {
            return pro;
          }
        });

        setProduct(u_data);
      }

      return datares.status;
    } catch (e) {
      console.log(e.message);
    }
  };

  // out of stock request
  const out_of_stock = async (p_id, type) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/product/outofstock/${p_id}/${type}`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Success") {
      }

      const comp = datares.data;
      let oldproduct = JSON.parse(JSON.stringify(product));
      for (let index = 0; index < oldproduct.length; index++) {
        const element = oldproduct[index];
        if (element._id === p_id) {
          element.hide_inventory = comp.hide_inventory;

          break;
        }
      }
      setProduct(oldproduct);
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  //show and hide

  const show_and_hide = async (p_id, type) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/product/productVisible/${p_id}/${type}`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Success") {
      }

      const comp = datares.data;
      let oldproduct = JSON.parse(JSON.stringify(product));
      for (let index = 0; index < oldproduct.length; index++) {
        const element = oldproduct[index];
        if (element._id === p_id) {
          element.visibility = comp.visibility;

          break;
        }
      }
      setProduct(oldproduct);
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  // Inventory data

  return (
    <productContext.Provider
      value={{
        product,
        totalCount,
        totalInventory,
        out_of_stock,
        getAllProduct,
        getAllWareHouseProduct,
        addProduct,
        updateProduct,
        editProduct,
        show_and_hide,
        setProduct,
        deleteProduct,
        getAllProductbyChecked,
        editObj,
        setEditObject,
        getAllProductInventoy,
        updateInventory,
      }}
    >
      {props.children}
    </productContext.Provider>
  );
};
export default ProductState;

import React from "react";
import { Link, Outlet } from "react-router-dom";

const AdminLayout = () => {
  return (
    <div className="content-wrapper">
      {/* <!-- Content --> */}

      <div className="container-xxl flex-grow-1 container-p-y">
        <Outlet />
        

        
      </div>

      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
          <div className="mb-2 mb-md-0">
            ©<script>document.write(new Date().getFullYear());</script>{" "}
            MYORDERSLIP, made with ❤️ by
            <Link to="https://vipsgrow.in" className="footer-link fw-bolder">
              VIPs Grow
            </Link>
          </div>
        </div>
      </footer>

      <div className="content-backdrop fade"></div>
    </div>
  );
};

export default AdminLayout;

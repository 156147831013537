import React, { useState } from "react";
import { CartContext } from "../Catalog/CatalogContext";

const CartManager = (props) => {
  const host = process.env.REACT_APP_URL;

  const [productArray, setProductArray] = useState([]);

  const syncData = () => {
    return localStorage.getItem("syncData") || null;
  };

  const setSyncData = (status) => {
    localStorage.setItem("syncData", status);
  };

  const getCartData = async () => {
    try {
      const p_data = await localStorage.getItem("p_data");

      if (p_data !== null && p_data !== "") {
        setProductArray(JSON.parse(p_data));
      }
      return true;
    } catch (error) {
      console.log("error = ", error);
      return false;
    }
  };

  const setCartDataInLocal = async (newData) => {
    try {
      const p_data = localStorage.setItem("p_data", JSON.stringify(newData));
      console.log("set cart in local = ", p_data);
      if (p_data !== null || p_data !== undefined) {
        setProductArray(newData);
      } else {
        console.log("data not set - ".localStorage.getItem("p_data"));
      }
    } catch (error) {
      console.log("error = ", error);
    }
  };

  const syncFromServer = async (token) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      };
      const response = await fetch(
        `${host}/api/customer/supplier/getCart`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        const data = datares.data;
        setProductArray(
          data.map((pro, i) => {
            return {
              cart_id: pro._id,
              catlog_id: pro.catlog_id,
              key: pro.key,
              price: pro.price,
              product_id: pro.product_id,
              qty: pro.qty,
              type: pro.type,
              attr_id: pro.attr_id,
              sp_rate: pro.sp_rate,
              userid: "",
              _id: "",
              createAt: pro.createAt,
              add_to_server: true,
              update_qty: false,
            };
          })
        );

        setSyncData(true);
      } else {
      }
    } catch (error) {
      console.log("error in cart manager - ", error);
    }
  };

  const addCart = async (object) => {
    console.log("object - ", object);
    console.log("p - ", productArray);
    try {
      let match = false;
      let index = 0;
      await getCartData();

      let product_array = [];
      product_array = productArray;

      console.log("product array = ", product_array);
      for (let i = 0; i < product_array.length; i++) {
        const obj = product_array[i];
        const productdata = obj.product_id;
        if (
          productdata._id === object.product_id._id &&
          obj.type === object.type &&
          obj.key === object.key
        ) {
          if (object.attr_id !== undefined && obj.attr_id === object.attr_id) {
            match = true;
            index = i;
            break;
          }
        }
      }

      if (match) {
        product_array[index].exist_qty = product_array[index].qty;
        const updatevalue =
          parseInt(product_array[index].qty) + parseInt(object.qty);

        if (
          Object.keys(object).includes("qty_type") &&
          object.qty_type === "b"
        ) {
          product_array[index].qty = object.qty;
        } else {
          product_array[index].qty = updatevalue;
        }

        if (product_array[index].add_to_server) {
          product_array[index].update_qty = true;
        } else {
          product_array[index].update_qty = false;
        }
      } else {
        product_array.push(object);
      }
      console.log("add object = ", product_array);
      setProductArray(productArray);
      setCartDataInLocal(product_array);
    } catch (err) {
      console.log("cart manager = ", err);
    }
  };

  const comUpdateServer = async (auth_token) => {
    try {
      let type = true,
        go = false;
      let countRequest = 0,
        requestCounter = 0;
      let updateCount = 0;

      let f_array = [];

      for (let i = 0; i < productArray.length; i++) {
        const obj = productArray[i];

        if (!obj.add_to_server && !Object.keys(obj).includes("cart_id")) {
          requestCounter += 1;
          updateCount++;

          const body = {
            index: i,
            catlog_id: obj.catlog_id._id,
            key: obj.key,
            price: obj.price,
            product_id: obj.product_id._id,
            qty: obj.qty,
            type: obj.type,
            attr_id: obj.attr_id,
            add_to_server: obj.add_to_server,
          };
          f_array.push(body);
        } else if (obj.update_qty) {
          updateCount++;
          requestCounter += 1;

          const exist_qty = parseInt(obj.exist_qty);
          const update_qty = parseInt(obj.qty);

          const qty = update_qty - exist_qty;

          if (update_qty == 0) {
            return;
          }

          const body = {
            index: i,
            add_to_server: obj.add_to_server,
            update_qty: obj.update_qty,
            cart_id: obj.cart_id,
            new_qty: update_qty,
          };
          f_array.push(body);
        }
      }

      const body = { data: f_array };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": auth_token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/customer/supplier/addMProToCart`,
        requestOptions
      );
      const datares = await response.json();
      console.log("com update server - ", datares);
      return true;
    } catch (error) {
      console.log("update server = ", error);
      return false;
    }
  };

  // remove cart form server cart page


  return (
    <CartContext.Provider
      value={{
        productArray,
        syncData,
        setSyncData,
        getCartData,
        syncFromServer,
        addCart,
        comUpdateServer,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};
export default CartManager;

import React, { useState, useContext, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Preloader from "../componant/Preloader";
import ComContext from "../context/Company/CompanyContext";

export default function AddnewCompany(props) {
  // usecontext for add company
  const history = useNavigate();
  const context = useContext(ComContext);
  const { addComapny, setComImage } = context;
  const [company, setCompany] = useState({
    com_name: "",
    bus_type: "",
    indus_type: "",
    email: "",
    phone: "",
    address: "",
    state: "",
    country: "India",
    zipcode: "",
  });
  const [preloader, setPreloader] = useState("");
  const openPreloader = () => {
    if (preloader !== "preShow") {
      setPreloader("preShow");
      setTimeout(() => {
        setPreloader("");
      }, 3000);
    } else {
      setPreloader("");
    }
    
  };

  const handleClick = async(e) => {
    e.preventDefault();
    openPreloader();
    const restr = await addComapny(company);       
    if (restr.status === "Success") {      
      props.showAlert(restr.msg, "success");
      const res_data = restr.data;
      // upload com image
      const data = new FormData();
      data.append("file", com_image);
      const uploadImage = await setComImage(data, res_data._id)
      
      history("/mycompanies")
      // upload com image
      
    } else {      
      props.showAlert(restr.msg, "danger");
    }
    setPreloader("");
  };


  const onChange = (e) => {    
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  // open input file
  const inputFile = useRef(null);
  const openField = () =>{
    inputFile.current.click();
  }

  const [com_image, setCom_image] = useState(null);

  const selecteFileHandler = (e) =>{
    e.preventDefault()
    let images = [];
    console.log(e.target.files);
    setCom_image(e.target.files[0]);
  }

  

  return (
    <>
    <Preloader show={preloader} />
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <h4 className="card-header c_heading">Company Details</h4>

                <div className="card-body">
                  <div className="d-flex align-items-start align-items-sm-center gap-4">
                    <img
                      src={ com_image === null ? "../assets/img/avatars/1.png" : URL.createObjectURL(com_image)}
                      alt="user-avatar"
                      className=" rounded-circle "
                      height="140"
                      width="140"
                      id="uploadedAvatar"
                    />
                    <div className="button-wrapper text-align-center">
                      <label
                        
                        className="btn btn-primary orange-btn me-2 mb-2 "
                        tabIndex="0"
                      >
                        <span className="d-none d-sm-block" onClick={openField}>
                          Upload Company Logo
                        </span>
                        
                        
                      </label>
                      <input
                          type="file"
                          id="upload"
                          className="account-file-input d-none"
                          
                          accept="image/png, image/jpeg"
                          ref={inputFile}
                          onChange={selecteFileHandler}
                        />
                      {/* <button
                        type="button"
                        className="btn btn-outline-secondary account-image-reset mb-4"
                      >
                        <i className="bx bx-reset d-block d-sm-none"></i>
                        <span className="d-none d-sm-block">Reset</span>
                      </button> */}

                      <p className="text-muted m-0  instruction">
                        Allowed JPG, JPEG or PNG.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card-body formdata">
                  <form
                    id="formAccountSettings"
                    method="POST"
                    onSubmit={handleClick}
                  >
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="com_name" className="form-label">
                          Company Name <span className="important">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="com_name"
                          name="com_name"
                          onChange={onChange}
                          placeholder="Your Company Name"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="bus_type" className="form-label">
                          Business Type <span className="important">*</span>
                        </label>
                        <select
                          id="bus_type"
                          className="select2 form-select"
                          onChange={onChange}
                          name="bus_type"
                        >
                          <option value="">Choose Type</option>
                          <option defaultValue="manufacturer">
                            Manufacturer
                          </option>
                          <option value="wholesaler">Wholesaler</option>
                          <option value="distributor">Distributor</option>
                          <option value="retailer">Retailer</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="indus_type" className="form-label">
                          Industry Type
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="indus_type"
                          id="indus_type"
                          onChange={onChange}
                          placeholder="Your Business Industy Type"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="email" className="form-label">
                          E-mail
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="email"
                          name="email"
                          onChange={onChange}
                          placeholder="Business Email"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="gst_no" className="form-label">
                          GST No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="gst_no"
                          name="gst_no"
                          onChange={onChange}
                          placeholder="GST"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="phone">
                          Phone Number <span className="important">*</span>
                        </label>
                        <div className="input-group input-group-merge">
                          <span className="input-group-text">IN (+91)</span>
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            className="form-control"
                            onChange={onChange}
                            placeholder="Your Business Phone"
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="address" className="form-label">
                          Address <span className="important">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          onChange={onChange}
                          placeholder="Your Business Address"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="state" className="form-label">
                          State <span className="important">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="state"
                          name="state"
                          onChange={onChange}
                          placeholder="Select State"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="zipcode" className="form-label">
                          Zip Code <span className="important">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="zipcode"
                          name="zipcode"
                          maxLength="6"
                          onChange={onChange}
                          placeholder="ZipCode"
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="country">
                          Country <span className="important">*</span>
                        </label>
                        <select
                          id="country"
                          className="select2 form-select"
                          onChange={onChange}
                          name="country"
                        >
                          <option value="Australia">Australia</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Canada">Canada</option>
                          <option value="China">China</option>
                          <option value="France">France</option>
                          <option value="Germany">Germany</option>
                          <option value="India" selected>
                            India
                          </option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Japan">Japan</option>
                          <option value="Korea">Korea, Republic of</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Russia">Russian Federation</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2">
                      <button
                        type="reset"
                        className="btn btn-outline-primary me-2 my-btn"
                        onClick={openPreloader}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn orange-btn btn-primary me-2"
                        //onClick={handleClick}
                        disabled={company.com_name.length < 5}
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
}

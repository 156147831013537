import React from "react";
import { useState } from "react";

function CustomButton({
  buttondata,
  filterfunction,
  widthM = 340,
  m_right = 0,
  cat_filter = false,
}) {
  let width = 100 / buttondata.length;

  const [activeIndex, setactiveIndex] = useState(0);

  return (
    <>
      <div className="wraper" style={{ marginRight: m_right }}>
        <div className="btn_group" style={{ width: widthM }}>
          {buttondata.map((btn, i) => {
            const bg = i === activeIndex ? "#FFF8F4" : "";
            const active = i === activeIndex ? "black" : "black";
            const bold = i === activeIndex ? "800" : "100";
            const borderpx = i === activeIndex ? "1 px solid black" : "none";
            return (
              <p
                className="btn_one slid_btn_active "
                style={{
                  width: width + "%",
                  background: bg,
                  color: active,
                  fontWeight: bold,
                  border: i === activeIndex ? "1px solid" : "none",
                }}
                id="block"
                onClick={() => {
                  setactiveIndex(i);
                  if (typeof filterfunction === "function") {
                    filterfunction(cat_filter ? btn._id : btn.title);
                  }
                  else{
                    console.log("filter function not found");
                  }
                }}
              >
                <span>{btn.title}</span>
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CustomButton;

import React from "react";
import ImageSlider from "../../componant/imageSlider/ImageSlider";

const ShowProImage = ({ openData, images }) => {
  const style = {
    background: "#00000080",
    display: "block",
  };

  console.log("image list = ", images);
  return (
    <div className="modal reviewModal" style={style}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"></h5>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={openData}
            ></button>
          </div>
          <div className="modal-body">
            <ImageSlider images={images} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowProImage;

import React, { useState } from "react";

const CatalogManage = ({ buttondata, filterfunction }) => {
  let width = 100 / buttondata.length;

  const [activeIndex, setactiveIndex] = useState(0);
  return (
    /* style={{ float: "right" }} */
    <div className=" ">
      <div className="btn_group">
        {buttondata.map((btn, i) => {
          const bg = i === activeIndex ? "#ef6817" : "";
          const active = i === activeIndex ? "white" : "black";
          return (
            <p
              key={i}
              className="btn_one slid_btn_active "
              style={{
                width: width + "%",
                background: bg,
                color: active,
              }}
              id="block"
              onClick={() => {
                setactiveIndex(i);
                filterfunction(btn.title);
              }}
            >
              <span>{btn.title}</span>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default CatalogManage;

import React from "react";
import {  useNavigate } from "react-router-dom";

const SupplierCard = ({ data }) => {

    const navigate = useNavigate()
  return (
    <>
      <div className="card p-2">
        <div className="row p-2 align-items-center">
          <div className="col-4">
            <div className="sup-image">
              <img
                src="../assets/img/avatars/5.png"
                className="sup-image"
                alt="Cinque Terre"
              />
            </div>
          </div>
          <div className="col-8">
            <div className="sup_details d-flex flex-column ">
              <span className="sup_name_c">{data.supplier_id.fullname}</span>
              <span className="sm-name">{data.com.com_name}</span>
              <span className="sup-phn">{data.supplier_id.phone}</span>
            </div>
          </div>
          <span
            className="cre-ord mt-2 "
            onClick={() => {
              navigate("/createorder/customer/" + data.supplier_id._id);
            }}
          >
            Create Order
          </span>
        </div>
      </div>
    </>
  );
};

export default SupplierCard;

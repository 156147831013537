import React, { useContext, useEffect, useState } from "react";
import AdminContext from "../context/admin/AdminContext";
import formatDateTime from "../staticFunc/DateFormat";
import Search_Query from "../componant/search_query/Search_Query";
import InfiniteScroll from "react-infinite-scroll-component";

const UserList = (props) => {
  const adminuserContext = useContext(AdminContext);
  const {
    AllUsers,
    allUserList,
    AnalyticsData,
    subsData,
    getSubscription,
    upgradeAccount,
  } = adminuserContext;

  useEffect(() => {
    allUserList();
    getSubscription();
  }, []);

  const [query, setQuery] = useState("");
  // load more function
  const [loadMore, setLoadMore] = useState(true);
  const [page, setPage] = useState(1);
  const fetchFunction = (load, page_no, que, callback = () => {}) => {
    const diff = AnalyticsData.total_users - AllUsers.length;
    if (AllUsers.length >= AnalyticsData.total_users) {
      if (diff !== 0) {
        setLoadMore(false);
        allUserList(true, page + diff, que, (length) => {
          setPage(page + diff);
          if (length === 0) {
            setLoadMore(false);
          }
        });
      } else {
        setLoadMore(false);
      }
    } else {
      setLoadMore(true);
      allUserList(true, page + 15, que, (length) => {
        setPage(page + 15);
        if (length === 0) {
          setLoadMore(false);
        }
      });
    }
  };

  const [showEditModal, setShowEditModal] = useState({
    subsc: false,
    edit: false,
    edit_obj: {},
  });
  const style = {
    background: "#00000080",
    display: "block",
  };

  const openData = (key, obj) => {
    setShowEditModal({
      ...showEditModal,
      [key]: !showEditModal[key],
      edit_obj: obj,
    });
  };

  const [updateData, setUpdateData] = useState({
    sub_id: "",
    sub_date: "",
  });
  const onChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const updateSubscription = async () => {
    let body = updateData;
    body.user_id = showEditModal.edit_obj._id;

    if (updateData.sub_date === "") {
      body.sub_date = showEditModal.edit_obj.subcribeAt;
    }

    const res = await upgradeAccount(
      updateData.sub_id,
      showEditModal.edit_obj.token_auto,
      "supplier"
    );
    if (res.status === "Success") {
      openData("subsc", {});
    }
    console.log(body);
  };

  // show information
  const [showInfoTag, setShowInfoTag] = useState(false);
  const [showInfoObj, setShowInfoObj] = useState(null);
  const showInfo = (obj) => {
    setShowInfoTag(!showInfoTag);
    setShowInfoObj(obj);
  };

  return (
    <>
      {showEditModal.subsc && (
        <>
          <div className="modal reviewModal" style={style}>
            <div className="modal-dialog" style={{ width: "40%" }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Subscription Details
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      openData("subsc", {});
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* <form id="formAccountSettings" method="POST" onSubmit={null}> */}
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">
                      <form method="post">
                        <div className="form-group mb-4">
                          <label>Subscription Plan</label>
                          <select
                            className="form-control"
                            name="sub_id"
                            onChange={onChange}
                            defaultValue={showEditModal.edit_obj.subscription}
                          >
                            <option value={null}>Select Subscription</option>
                            {subsData.map((sub, i) => {
                              return (
                                <option key={sub._id} value={sub._id}>
                                  {sub.planName}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group">
                          <label>Subscribed Date</label>
                          <input
                            type="text"
                            name="sub_date"
                            className="form-control"
                            defaultValue={showEditModal.edit_obj.subcribeAt}
                          />
                        </div>
                      </form>
                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3" style={{ textAlign: "right" }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        openData("subsc", {});
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      onClick={updateSubscription}
                    >
                      Update
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showInfoTag && (
        <>
          <div className="modal reviewModal" style={style}>
            <div className="modal-dialog" style={{ width: "40%" }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Bulk Upload Details
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      // openData("subsc", {});
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  {/* <form id="formAccountSettings" method="POST" onSubmit={null}> */}
                  <div className="row">
                    {/* category add section */}
                    <div className="col-md-12  my-1">

                      <label className="form-label">Companies</label>
                      

                    </div>
                    {/* category add section end */}
                  </div>
                  <div className="mt-3" style={{ textAlign: "right" }}>
                    <button
                      type="reset"
                      className="btn btn-outline-primary me-2 my-btn"
                      onClick={() => {
                        showInfo(null);
                      }}
                    >
                      Cancel
                    </button>
                    {/* <button
                      type="submit"
                      className="btn orange-btn btn-primary me-2"
                      
                    >
                      Update
                    </button> */}
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 ">
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                All Users ({AnalyticsData.total_users})
              </span>
              <span className="headingcontent mx-5">
                All Suppliers ({AnalyticsData.total_supplier})
              </span>
              <div className="heading_buttons"></div>
            </h4>

            <div className="col-lg-12 mb-4">
              <div className="card">
                {AllUsers.length > 0 && (
                  <h5 className="card-header">
                    <div className="row">
                      <div className="col-lg-12 col-12 text-right">
                        <Search_Query
                          placeholder="Search user by name, phone..."
                          value={query}
                          show={query !== ""}
                          onQuery={setQuery}
                          clear_data={() => {
                            setQuery("");
                            setPage(1);
                            // fetchFunction(1, "", (l) => {
                            //   setLoadMore(true);
                            //   fetchFunction("");
                            // });
                            allUserList(false, 1, "", (length) => {
                              if (length === 0) {
                                setLoadMore(false);
                              }
                            });
                          }}
                          search_click={() => {
                            setPage(1);
                            allUserList(false, 1, query, (length) => {
                              if (length === 0) {
                                setLoadMore(false);
                              }
                            });
                            setLoadMore(true);
                          }}
                        />
                      </div>
                    </div>
                  </h5>
                )}

                {/* {category.length === 0 ? (
                  <Nodata data_text={"Categories"} />
                ) : ( */}
                <div className="text-nowrap mb-1">
                  <InfiniteScroll
                    dataLength={AllUsers.length}
                    next={() => {
                      fetchFunction(query);
                    }}
                    hasMore={loadMore}
                    loader={
                      <div
                        className="loader"
                        style={{
                          position: "relative",
                          width: 40,
                          height: 40,
                        }}
                      ></div>
                    }
                  >
                    <div class="table-responsive">
                      <table className="table">
                        <thead className="order_history_header">
                          <tr>
                            <th>S.No.</th>
                            <th>Full Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Type</th>

                            {/* <th>Subscription</th> */}
                            <th>Create At</th>
                            <th className="action">Action</th>
                          </tr>
                        </thead>

                        <tbody className="table-border-bottom-0 order_history_body">
                          {AllUsers.map((c, i) => {
                            const date = formatDateTime(c.createAt).split(",");
                            return (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{c.fullname}</td>
                                <td>{c.phone}</td>
                                <td>{c.email}</td>
                                <td>{c.type}</td>
                                {/* <td>{c.subscription}</td> */}
                                <td>{date}</td>

                                <td className="action">
                                  <div className="">
                                    <span
                                      className="badge bg-success me-2"
                                      onClick={() => {
                                        openData("subsc", c);
                                      }}
                                    >
                                      <i className="bx bx-shield-alt "></i>
                                    </span>
                                    <span
                                      className="badge bg-secondary me-2"
                                      onClick={() => {
                                        showInfo(c);
                                      }}
                                    >
                                      <i className="bx bx-show "></i>
                                    </span>
                                    <span className="badge bg-primary me-2">
                                      <i className="bx bx-edit-alt "></i>
                                    </span>

                                    <span className="badge bg-danger">
                                      <i className="bx bx-trash-alt "></i>
                                    </span>
                                  </div>
                                  <div className="dropdown d-none">
                                    <button
                                      type="button"
                                      className="btn p-0 dropdown-toggle hide-arrow"
                                      data-bs-toggle="dropdown"
                                    >
                                      <i className="bx bx-dots-vertical-rounded"></i>
                                    </button>
                                    <div className="dropdown-menu">
                                      <p
                                        className="dropdown-item"
                                        onClick={() => {
                                          // openEditModal(c);
                                        }}
                                      >
                                        <i className="bx bx-edit-alt me-1"></i>{" "}
                                        Edit
                                      </p>
                                      <p
                                        className="dropdown-item"
                                        onClick={() => {
                                          props.showCon(
                                            "Are you sure you want to delete this category?",
                                            true,
                                            async () => {
                                              // deleteCategory(c._id);
                                              props.showCon("", false, {});
                                            }
                                          );
                                        }}
                                      >
                                        <i className="bx bx-trash me-1"></i>{" "}
                                        Delete
                                      </p>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </InfiniteScroll>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;

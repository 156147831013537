import React, { useState, useEffect, useContext } from "react";
import Preloader from "../../componant/Preloader";
import SupplierContext from "../../context/GetSupplier/SupplierContext";
import Noticontext from "../../context/notification/NotifContext";
//require("dotenv").config();

export default function Customer_payment(props) {
  const context = useContext(SupplierContext);
  const { allSuppliers, getSuppliers } = context;
  const [payments, setPayments] = useState([]);
  const [preloader, setPreloader] = useState("");
  const [query, setQuery] = useState("");
  const host = process.env.REACT_APP_URL;
  // api context is write here

  const notiContext = useContext(Noticontext);

  const { pushNotification } = notiContext;

  const getPayments = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/payment/getPayments`,
      requestOptions
    );
    const datares = await response.json();
    setPayments(datares.data);
  };
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      getPayments();
      getSuppliers();
    }
  }, []);

  // Add Payments here started

  const [bodydata, setBodyData] = useState({
    supplier_id: "",
    amt: 0,
    collectedBy: "",
    mode: "",
    desc: "",
    createBy: "user",
  });

  const addPayment = async (data) => {
    setPreloader("preShow");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/payment/createPayment/user`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      props.showAlert("Payment Has Been Created, Wait for Approval", "success");
      pushNotification(
        datares.data.supplier_id,
        "payments",
        "New Payment Recieved",
        "Payment has been added from User. Amt is " + data.amt
      );
    } else {
      props.showAlert(datares.msg, "danger");
    }
    setPreloader("");
    getPayments();
  };

  const onInput = (e) => {
    e.preventDefault();
    const namekey = e.target.name;
    const keyvalue = e.target.value;
    setBodyData({ ...bodydata, [namekey]: keyvalue });
  };

  const clickAddPay = (e) => {
    e.preventDefault();
    addPayment(bodydata);
  };

  console.log(payments);

  return (
    <>
      <Preloader show={preloader} />
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">Add Payment</span>
            </h4>
            <div className="col-lg-12 mb-4 order-0 mx-auto">
              <div className="card">
                <div className="d-flex align-items-center row">
                  <div className="col-sm-12 ">
                    <div className="card-body">
                      <form
                        id="formAccountSettings"
                        method="POST"
                        onSubmit={clickAddPay}
                      >
                        <div className="row">
                          <div className="mb-3 col-md-2">
                            <label for="createAt" className="form-label">
                              Select Date <span className="important">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              id="createAt"
                              name="createAt"
                              onChange={onInput}
                            />
                          </div>
                          <div className="mb-3 col-md-2">
                            <label for="amt" className="form-label">
                              Amount <span className="important">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="amt"
                              name="amt"
                              placeholder="0.0"
                              onChange={onInput}
                            />
                          </div>

                          <div className="mb-3 col-md-2">
                            <label for="supplier_id" className="form-label">
                              Supplier Name <span className="important">*</span>
                            </label>
                            <select
                              className="form-control"
                              type="text"
                              id="supplier_id"
                              name="supplier_id"
                              onChange={onInput}
                            >
                              <option value={0}>Select Supplier</option>
                              {allSuppliers.map((sup) => {
                                return (
                                  <option value={sup._id}>
                                    {sup.fullname}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="mb-3 col-md-2">
                            <label for="email" className="form-label">
                              Give To <span className="important">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="collectedBy"
                              name="collectedBy"
                              placeholder="Give To"
                              onChange={onInput}
                            />
                          </div>

                          <div className="mb-3 col-md-2">
                            <label for="lastName" className="form-label">
                              Payment Mode
                            </label>
                            <select
                              className="form-control"
                              type="text"
                              id="mode"
                              name="mode"
                              onChange={onInput}
                            >
                              <option value={0}>Select Mode</option>
                              <option value="cash">Cash</option>
                              <option value="online">Online</option>
                              <option value="cheque">Cheque</option>
                            </select>
                          </div>
                          <div className="mb-3 col-md-2">
                            <label for="firstName" className="form-label">
                              Description
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              id="desc"
                              name="desc"
                              placeholder="Enter Description"
                              onChange={onInput}
                            />
                          </div>
                        </div>
                        <div className=" text-right mt-3">
                          <button
                            type="reset"
                            className="btn my-scale-btn btn-outline-primary me-2 my-btn"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn my-scale-btn orange-btn btn-primary me-2"
                          >
                            Add
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Payment History</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search Payments by Supplier Name..."
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Supplier Name</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Mode</th>
                        <th>Collected By</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      {payments
                        .filter((payment) =>
                          payment.supplier_id.fullname
                            .toString()
                            .toLowerCase()
                            .includes(query)
                        )
                        .map((pay) => {
                          return (
                            <>
                              {pay.amt > 0 && (
                                <tr>
                                  <td>
                                    {pay.supplier_id.fullname} <br />{" "}
                                    <small> ({pay.supplier_id.phone})</small>
                                  </td>
                                  <td>{pay.amt}</td>
                                  <td>{pay.createAt}</td>
                                  <td>{pay.mode}</td>
                                  <td>{pay.collectedBy}</td>
                                  <td>{pay.desc}</td>
                                </tr>
                              )}
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";

const Setting = () => {
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [black_log_show, set_back_log_show] = useState(false);

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-12">
            <h5 className="card-header c_heading px-0 pt-0">
              <div className="row">
                <div className="col-lg-3">
                  <span className="headingcontent">Settings</span>
                </div>
              </div>
            </h5>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6">
                <div className="card mb-3">
                  <div className="card-body d-flex flex-wrap">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h5 className="m-0">Voice Notification</h5>
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 mt-3">
                      <label>Notification Language</label>
                      <div class="form-check">
                        <label class="form-check-label me-5">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="noti_lang"
                            id=""
                            value="english"
                            checked
                          />
                          English
                        </label>
                        <label class="form-check-label">
                          <input
                            type="radio"
                            class="form-check-input"
                            name="noti_lang"
                            id=""
                            value="hindi"
                          />
                          Hindi
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="card mb-3">
                  <div className="card-body d-flex flex-wrap">
                    <div className="d-flex align-items-center align-content-center justify-content-between w-100">
                      <h5 className="p-0 m-0">Low Stock Alert</h5>
                      <label class="switch">
                        <input type="checkbox" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div
                className="card-header"
                onClick={() => {
                  set_back_log_show(!black_log_show);
                }}
              >
                <div className="d-flex align-items-center align-content-center justify-content-between w-100">
                  <h5 className="p-0 m-0">Back Log Delete</h5>
                  <i className="fa fa-greater-than"></i>
                </div>
              </div>
              {black_log_show && (
                <div className="card-body ">
                  <div className="row">
                    <div className="col-6">
                      <div class="form-group mb-3">
                        <label for="">FROM</label>
                        <input
                          type="date"
                          class="form-control"
                          name=""
                          id=""
                          aria-describedby="helpId"
                          placeholder="Choose starting date"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-group mb-3">
                        <label for="">TO</label>
                        <input
                          type="date"
                          class="form-control"
                          name=""
                          id=""
                          aria-describedby="helpId"
                          placeholder="Choose starting date"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label for="">Customer</label>
                    <select className="form-control">
                      <option value={""}>Select Customer</option>
                    </select>
                  </div>

                  <button className="my-btn orange-btn">Submit</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;

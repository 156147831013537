import React, { useState } from "react";
import CusContext from "./CustomerContext";

//require("dotenv").config();
const CustomerState = (props) => {
  const host = process.env.REACT_APP_URL;

  const [invites, setInvite] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getAllCustomer = async (query = "") => {
    // console.log(localStorage.getItem("token"));
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/customer/getCustomers${
        query !== "" ? `?search_query=${query}` : ""
      }`,
      requestOptions
    );
    const datares = await response.json();
console.log("cus",datares)
    let datasets = await Promise.all(
      datares.data.map(async (cus) => {
        const order = await getAllOrder(cus.customer_id._id);
        return { ...cus, order };
      })
    );

    // if (loadMore) {
    //   setCustomerData(myCustomer.concat(datasets));
    // } else {
    setCustomerData(datasets);
    // }

    // setCustomerData(datasets);

    return datares;
  };

  const updateCustomer = async (id, name) => {
    // console.log(localStorage.getItem("token"));
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/customer/updateCustomer/${id}/${name}`,
      requestOptions
    );
    const datares = await response.json();

    // let datasets = await Promise.all(
    //   datares.data.map(async (cus) => {
    //     const order = await getAllOrder(cus.customer_id._id);
    //     return { ...cus, order };
    //   })
    // );

    setCustomerData(
      myCustomer.map((cus) => {
        if (cus.customer_id._id === id) {
          cus.nick_name = name;
          return cus;
        } else {
          return cus;
        }
      })
    );

    return datares;
  };

  const getAllOrder = async (userid) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/order/getOrderByUser/${userid}`,
      requestOptions
    );
    const datares = await response.json();
    let totalamt = 0;
    for (let i = 0; i < datares.data.length; i++) {
      const element = datares.data[i];
      totalamt = totalamt + parseInt(element.total_price, 10);
    }
    return totalamt;
  };

  

  const customers_data = [
    {
      customer_id: {
        _id: "",
        userid: "",
        phone: "",
        name: "",
        email: "",
        address: "",
        cus_image: "",
        com_name: "",
        gst_no: "",
        bus_type: "",
        __v: 0,
      },
    },
  ];
  const [myCustomer, setCustomerData] = useState(customers_data);

  const addCustomer = async (data) => {
    try {
      //const bodydata = com_name;
      console.log(data);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        `${host}/api/auth/addCustomer`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Failed") {
      } else {
        const comp = datares.data;
        setCustomerData(myCustomer.concat({ customer_id: comp }));
        console.log(comp);
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  const getEnvite = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/invite/getInvites/supplier/${id}`,
      requestOptions
    );
    const datares = await response.json();

    setInvite(datares.data);
  };

  const deleteInvite = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/invite/delinvite/${id}`,
      requestOptions
    );
    const datares = await response.json();

    if (datares.status === "Success") {
      const newinvites = invites.filter((inv) => {
        return inv._id !== id;
      });
      setInvite(newinvites);
    } else {
      alert("Something wentwrong");
    }
  };

  const updateStatus = async (type, id) => {
    try {
      //const bodydata = com_name;

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/customer/changeStatus/${type}/${id}`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Success") {
        const comp = datares.data;
        getAllCustomer();
        // setCustomerData(myCustomer.concat({ customer_id: comp }));
        console.log(comp);
      } else {
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteCustomer = async (id) => {
    try {
      //const bodydata = com_name;

      const requestOptions = {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/customer/deleteFromSupplier/${id}`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Success") {
        const comp = datares.data;

        const updateCustomer = myCustomer.filter((inv) => {
          return inv.customer_id._id !== id;
        });
        setCustomerData(updateCustomer);

        // setCustomerData(myCustomer.concat({ customer_id: comp }));
        console.log(comp);
      } else {
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };


  // selected customer
  const [selectedCustomer, set_selected_customer]  = useState({});
  


  return (
    <CusContext.Provider
      value={{
        myCustomer,
        totalCount,
        deleteCustomer,
        updateCustomer,
        getAllCustomer,
        addCustomer,
        setCustomerData,
        updateStatus,
        getAllOrder,
        invites,
        getEnvite,
        deleteInvite,
        selectedCustomer,
        set_selected_customer,
      }}
    >
      {props.children}
    </CusContext.Provider>
  );
};

export default CustomerState;

import React, { useState, useEffect, useContext } from "react";
import ConfirmAlert from "../componant/ConfirmAlert/ConfirmAlert";
import Preloader from "../componant/Preloader";
import { useParams } from "react-router-dom";
import PaymentContext from "../context/payments/PaymentCon";
//require("dotenv").config();
export default function Subscribe(props) {
  const host = process.env.REACT_APP_URL;
  const token = localStorage.getItem("token");
  const [preloader, setPreloader] = useState("");
  const [subscription, setSubuscription] = useState([]);

  const [upgrade, setUpgrade] = useState("Upgrade");
  let userdata = JSON.parse(localStorage.getItem("user_data"));

  const params = useParams();

  const openPreloader = () => {
    if (preloader !== "preShow") {
      setPreloader("preShow");
    } else {
      setPreloader("");
    }
  };

  const auth_token = localStorage.getItem("token");

  const upgradeAccount = async (id) => {
    openPreloader();
    const body = { subscription: id };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(`${host}/api/auth/editUser`, requestOptions);
    const datares = await response.json();
    if (datares.status === "Success") {
      localStorage.setItem("user_data", JSON.stringify(datares.data));
      props.showAlert(datares.msg, "sucess");
    } else {
      props.showAlert(datares.msg, "danger");
    }
    openPreloader();
    window.location.reload();
    // setSubuscription(datares.data);
  };

  useEffect(() => {
    console.log(userdata + " - " + auth_token);

    if (auth_token === null && userdata === null) {
      if (params.id) {
        getToken(params.id);
      } else {
        alert("Something went wrong");
      }
    } else {
      getSubscription();
    }
  }, []);

  const getSubscription = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": auth_token,
      },
    };
    const response = await fetch(
      `${host}/api/subscription/getSubscription`,
      requestOptions
    );
    const datares = await response.json();
    console.log(datares.data);
    setSubuscription(datares.data);
  };

  const getToken = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": auth_token,
      },
    };
    const response = await fetch(
      `${host}/api/auth/genToken/${id}`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      localStorage.setItem("token", datares.token);
      localStorage.setItem("user_data", JSON.stringify(datares.data));
      userdata = datares.data;
      getSubscription();
    } else {
    }
  };

  // payment intiate
  const payCon = useContext(PaymentContext);
  const { pay_url, paymentIntiate } = payCon;

  const goToPayment = async (obj) => {
    localStorage.setItem("selected_plan", JSON.stringify(obj));
    const res = await paymentIntiate(obj.price);
    if (res) {
      // window.location.href = pay_url;
      console.log("payment intiate - ", pay_url);
    } else {
      alert("Something went wrong");
    }
  };

  useEffect(() => {
    if (pay_url !== null) {
      window.location.href = pay_url;
    }
  }, [pay_url]);

  // payment intiate

  return (
    <>
      <Preloader show={preloader} />
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button"></div>

          <div className="row">
            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <div className="card-body">
                  <div className="rounded-top">
                    <h2
                      className="text-center mb-2 mt-0 mt-md-4"
                      style={{ color: "black" }}
                    >
                      Find the right plan for your company
                    </h2>
                    <p className="text-center pb-3">
                      {" "}
                      Get started with us - Create beautiful catalogs and start
                      accepting orders online. <br />
                      <br />
                      Choose Monthly or Yearly Subscription
                    </p>
                    <div className="d-flex align-items-center justify-content-center flex-wrap gap-2 py-1 mb-0 mb-md-2"></div>

                    <div className="row mx-0 gy-3">
                      {subscription.length === 0 && "No plan found"}
                      {subscription &&
                        subscription.map((sub) => {
                          return (
                            <div className="col-lg mb-md-0 mb-4" key={sub._id}>
                              <div className="card border rounded shadow-none">
                                <div className="card-body position-relative">
                                  <div className="my-3 pt-2 text-center">
                                    {/* <img
                                      src="../assets/img/icons/unicons/bookmark.png"
                                      alt="Starter Image"
                                      height="80"
                                    /> */}
                                  </div>

                                  <div className="text-center mb-5">
                                    <div className="d-flex justify-content-center mb-1">
                                      <sup className="h5 pricing-currency mt-3 mb-0 me-1 text-primary">
                                        <i className="bx bx-rupee"></i>
                                      </sup>
                                      <h1 className="fw-bold h1 mb-0 text-primary">
                                        {sub.price}
                                      </h1>
                                      <sub className="h5 pricing-duration mt-auto mb-2 text-muted fw-normal">
                                        /{sub.duration}
                                      </sub>
                                    </div>
                                    <small className="position-absolute start-0 end-0 m-auto price-yearly price-yearly-toggle text-muted mt-2">
                                      <sup className="h7 pricing-currency mt-3 mb-0 me-1 text-primary">
                                        Only
                                      </sup>
                                      <span className="fw-bold">
                                        {sub.perday}
                                      </span>{" "}
                                      /per day
                                    </small>
                                  </div>

                                  <ul className="ps-3 my-4 list-unstyled">
                                    {sub.features.map((fea) => {
                                      return (
                                        <li className="mb-2">
                                          <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                            <i className="bx bx-check bx-xs"></i>
                                          </span>{" "}
                                          {fea.feature_values}
                                        </li>
                                      );
                                    })}
                                  </ul>

                                  <p
                                    className="btn btn-primary d-grid w-100 orange-btn"
                                    onClick={(e) => {
                                      {
                                        sub._id === userdata.subscription
                                          ? e.preventDefault()
                                          : goToPayment(sub);
                                      }
                                    }}
                                    // upgradeAccount(sub._id);
                                  >
                                    {sub._id === userdata.subscription
                                      ? params.id
                                        ? "Upgrade"
                                        : "Current Plan"
                                      : "Upgrade"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {/* <div className="col-lg mb-md-0 mb-4">
                        <div className="card border-orange border shadow-none">
                          <div className="card-body position-relative">
                            <div className="my-3 pt-2 text-center">
                              <img
                                src="../assets/img/icons/unicons/wallet-round.png"
                                alt="Pro Image"
                                height="80"
                              />
                            </div>
                            <div className="text-center mb-5">
                              <div className="d-flex justify-content-center mb-1">
                                <sup className="h5 pricing-currency mt-3 mb-0 me-1 text-primary">
                                  <i className="bx bx-rupee"></i>
                                </sup>
                                <h1 className="fw-bold h1 mb-0 text-primary">
                                  24,999
                                </h1>
                                <sub className="h5 pricing-duration mt-auto mb-2 text-muted fw-normal">
                                  /Yearly
                                </sub>
                              </div>
                              <small className="position-absolute start-0 end-0 m-auto price-yearly price-yearly-toggle text-muted mt-2">
                                <sup className="h7 pricing-currency mt-3 mb-0 me-1 text-primary">
                                  Only
                                </sup>
                                <span className="fw-bold">70</span> /per day
                              </small>
                            </div>

                            <ul className="ps-3 my-4 list-unstyled">
                              <ul className="ps-3 my-4 list-unstyled">
                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Accept and Manage Unlimited Orders
                                </li>
                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Add Unlimited Products
                                </li>
                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Create Unlimited Catalogs
                                </li>
                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Create Unlimited Customers
                                </li>
                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Add Payments
                                </li>
                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Create Reports
                                </li>

                                <li className="mb-2">
                                  <span className="badge badge-center w-px-20 h-px-20 rounded-pill bg-label-primary me-2">
                                    <i className="bx bx-check bx-xs"></i>
                                  </span>{" "}
                                  Manage Company Staff
                                </li>
                              </ul>
                            </ul>

                            <p
                              className="btn btn-primary d-grid w-100 orange-btn"
                              onClick={upgradeAccount}
                            >
                              Upgrade
                            </p>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import AdminContext from "./AdminContext";
const AdminState = (props) => {
  const host = process.env.REACT_APP_URL;

  const [AllUsers, setAllUsers] = useState([]);

  const [AnalyticsData, setAnalyticsData] = useState({
    total_users: 0,
    total_supplier: 0,
  });

  const allUserList = async (
    loadMore = false,
    page = 1,
    query = "",
    call_back = (e) => {}
  ) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          //   "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/admin/all_users?limit=15&page=${page}&search_query=${query}`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        // const supplier = datares.data.filter((user) => user.type === "supplier");

        setAnalyticsData({
          ...AnalyticsData,
          total_users: datares.totalCount,
          total_supplier: datares.totalsupplier,
        });

        if (loadMore) {
          const combinedSet = new Set([...AllUsers, ...datares.data]);
          const uniqueUsersArray = Array.from(combinedSet);
          setAllUsers(uniqueUsersArray);
        } else {
          setAllUsers(datares.data);
        }
        call_back(datares.data.length);
      } else {
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const [subsData, setSubuscription] = useState([]);
  const getSubscription = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": auth_token,
      },
    };
    const response = await fetch(
      `${host}/api/subscription/getSubscription`,
      requestOptions
    );
    const datares = await response.json();
    console.log(datares.data);
    setSubuscription(datares.data);
  };


  const upgradeAccount = async (id, token, type) => {
    // openPreloader();
    const body = { subscription: id, type:type };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": token,
      },
      body: JSON.stringify(body),
    };
    const response = await fetch(`${host}/api/auth/editUser`, requestOptions);
    const datares = await response.json();
    if (datares.status === "Success") {
      setAllUsers(AllUsers.map((user)=>{
        return user._id === datares.data._id ? {...user, type:datares.data.type, subscription: datares.data.subscription} : user
      }))
      // localStorage.setItem("user_data", JSON.stringify(datares.data));
      // props.showAlert(datares.msg, "sucess");
    } else {
      // props.showAlert(datares.msg, "danger");
    }
    return datares;
    // openPreloader();
    // window.location.reload();
    // setSubuscription(datares.data);
  };

  return (
    <AdminContext.Provider
      value={{
        AllUsers,
        allUserList,
        AnalyticsData,
        subsData,
        getSubscription,
        upgradeAccount
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};
export default AdminState;

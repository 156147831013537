import React, { useContext, useEffect, useState } from "react";
import productContext from "../context/Product/productContext";
import InfiniteScroll from "react-infinite-scroll-component";

export default function ManageProduct(props) {
  const context = useContext(productContext);
  const {
    product,
    getAllProduct,
    setProduct,
    getAllProductbyChecked,
    totalCount,
  } = context;

  useEffect(() => {
    const getData = async () => {
      await getAllProductbyChecked(props.cat_data);
      let myArr = [];
      product.map((pro) => {
        if (pro.ischecked) {
          myArr.push({ p_id: pro._id });
        }

        // return { ...pro, ischecked: checked };
      });
      setSelecteCustomer(myArr);
    };

    getData();
  }, []);

  const [selectCustomer, setSelecteCustomer] = useState([]);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (name.toString() === "all") {
      const myArr = [];
      let tempUser = product.map((pro) => {
        if (checked) {
          myArr.push({ p_id: pro._id });
        }

        return { ...pro, ischecked: checked };
      });
      setProduct(tempUser);
      setSelecteCustomer(myArr);
    } else {
      const myArr = [];
      let tempUser = product.map((pro) =>
        pro._id.toString() === name.toString()
          ? { ...pro, ischecked: checked }
          : pro
      );

      tempUser.map((cus) => {
        if (cus.ischecked) {
          myArr.push({ p_id: cus._id });
        }
      });

      setProduct(tempUser);
      setSelecteCustomer(myArr);
    }
  };

  const assignCustomer = (e) => {
    e.preventDefault();
    props.updateProductCatalog(selectCustomer, props.cat_data._id);
  };

  const mystyle = {
    newText: {
      color: "var(--bs-dark-blue)",
    },
  };
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [p_filter, setFilter] = useState(true);

  const [loadMore, setLoadMore] = useState(true);
  const [cat_filter, set_cat_filter] = useState(null);

  
  const fetchFunction = (que) => {
   
    if (product.length === totalCount) {
      setLoadMore(false);
    } else {
      setLoadMore(true);
      setPage(page + 8);
      getAllProduct(
        true,
        page + 8,
        que,
        p_filter,
        (l_length) => {
          if (l_length === 0) {
            setLoadMore(false);
          }
        },
        cat_filter
      );
    }
  };
  return (
    <>
      <div className="card p-2 mamnageProductCard mt-3">
        {/* {catalogDetails._id} */}
        <div className="card-datatable table-responsive">
          <div className="fixed-scroll-500">
            <InfiniteScroll
              hasMore={loadMore}
              next={() => {
                fetchFunction(query);
              }}
              dataLength={product.length}
              loader={
                <>
                  <div
                    className="loader"
                    style={{
                      position: "relative",
                      width: 40,
                      height: 40,
                    }}
                  ></div>
                  <span>
                    If not loaded data{" "}
                    <strong
                      onClick={() => {
                        // fetchMoreData(page + 8, filter_type);
                      }}
                    >
                      Click Here
                    </strong>
                  </span>
                </>
              }
            >
              <table className="datatables-basic table border-top">
                <thead className="order_history_header">
                  <tr>
                    <th>
                      <div className="form-check ">
                        <input
                          className="form-check-input me-4"
                          type="checkbox"
                          value="mpkg_unit"
                          id="defaultRadio1"
                          name="all"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="defaultRadio1"
                        >
                          Image
                          {/* ({selectCustomer.length}) */}
                        </label>
                      </div>
                    </th>
                    <th>Name</th>

                    <th>
                      Discounted Price{" "}
                      <span className="badge rounded-pill bg-success discount">
                        {props.cat_data.flattDiscount ? "₹ " : ""}
                        {props.cat_data.discount}
                        {props.cat_data.flattDiscount ? " OFF" : "% OFF"}
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {product
                    .filter((pro) => {
                      return (
                        pro.p_name &&
                        pro.p_name
                          .toLowerCase()
                          .includes(props.query.toLowerCase())
                      );
                    })
                    .map((pro) => {
                      let d_price = parseFloat(pro.price);
                      if (props.cat_data.flattDiscount) {
                        d_price = d_price - props.cat_data.discount;
                      } else {
                        d_price =
                          (d_price * (100 - props.cat_data.discount)) / 100;
                      }
                      // console.log("d price = ", d_price);
                      return (
                        <tr key={pro._id}>
                          <td>
                            <div className="form-check mt-3 manage_check">
                              <input
                                // name="default-radio-1"
                                className="form-check-input me-4"
                                type="checkbox"
                                value="mpkg_unit"
                                id="defaultRadio1"
                                name={pro._id}
                                onChange={handleChange}
                                checked={pro.ischecked || false}
                              />
                              <img
                                src={
                                  pro.p_gallery_image.length > 0
                                    ? pro.p_gallery_image[0].link
                                    : "../assets/img/avatars/5.png"
                                }
                                className="img-fluid rounded-circle"
                                width="50"
                              />
                            </div>
                          </td>
                          <td>
                            <label
                              className="form-check-label"
                              htmlFor="defaultRadio1"
                            >
                              {pro.p_name}
                            </label>
                            <small style={{ marginLeft: 10 }}>
                              ({pro.brand})
                            </small>
                            <br />
                            <small>{pro.cat_id && pro.cat_id.cat_name}</small>
                            <br />
                            <small>
                              MRP{" "}
                              <strike>
                                ₹
                                {pro.p_price.length !== 0
                                  ? parseFloat(pro.p_price[0].value).toFixed(2)
                                  : parseFloat(pro.price).toFixed(2)}
                              </strike>
                            </small>
                          </td>
                          <td>
                            {pro.p_price.length !== 0 ? (
                              <ul className="attrContainer">
                                {pro.p_price.map((attr) => {
                                  // {props.cat_data.flattDiscount
                                  //   ? parseFloat(
                                  //       pro.price - props.cat_data.discount
                                  //     ).toFixed(2)
                                  //   : parseFloat(

                                  //     ).toFixed(2)}{" "}
                                  let attr_value = parseFloat(
                                    attr.value
                                  ).toFixed(2);
                                  if (props.cat_data.flattDiscount) {
                                    attr_value =
                                      attr_value - props.cat_data.discount;
                                  } else {
                                    attr_value =
                                      (attr_value *
                                        (100 - props.cat_data.discount)) /
                                      100;
                                  }

                                  const attr_pkg = parseFloat(
                                    attr_value * pro.pkg
                                  ).toFixed(2);

                                  const attr_mpkg = parseFloat(
                                    attr_value * pro.pkg * pro.mpkg
                                  ).toFixed(2);
                                  return (
                                    <li key={attr._id}>
                                      <span className="unitSpan">
                                        {attr.name}{" "}
                                        <ul className="attrprice ">
                                          <li>
                                            ₹ {attr_value}/{" "}
                                            {pro.unit_value +
                                              " " +
                                              pro.unit_name}
                                          </li>
                                          <li>
                                            ₹ {attr_pkg}/ {pro.pkg_unit}
                                          </li>
                                          <li>
                                            ₹ {attr_mpkg}/ {pro.mpkg_unit}
                                          </li>
                                        </ul>
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <div>
                                <ul className="attrContainer">
                                  <li>
                                    <span className="unitSpan">
                                      <ul className="attrprice p-0 ">
                                        <li>
                                          ₹ {parseFloat(d_price).toFixed(2)}/{" "}
                                          {pro.unit_value + " " + pro.unit_name}
                                        </li>
                                        {parseInt(pro.pkg) !== 0 && (
                                          <li>
                                            ₹{" "}
                                            {parseFloat(
                                              d_price * pro.pkg
                                            ).toFixed(2)}
                                            / {pro.pkg_unit}
                                          </li>
                                        )}

                                        {parseInt(pro.mpkg) !== 0 && (
                                          <li>
                                            ₹{" "}
                                            {parseFloat(
                                              d_price * pro.pkg * pro.mpkg
                                            ).toFixed(2)}
                                            / {pro.mpkg_unit}
                                          </li>
                                        )}
                                      </ul>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </td>
                          <td className="d-none">
                            <div>
                              <del className="catlogUnitSpan">
                                MRP ₹ {parseFloat(pro.price).toFixed(2)}
                              </del>
                              <span style={mystyle.newText}>
                                {" ₹ "}
                                {props.cat_data.flattDiscount
                                  ? parseFloat(
                                      pro.price - props.cat_data.discount
                                    ).toFixed(2)
                                  : parseFloat(
                                      (pro.price *
                                        (100 - props.cat_data.discount)) /
                                        100
                                    ).toFixed(2)}{" "}
                                <span className="catlogUnitSpan">
                                  (per {pro.unit_value + " " + pro.unit_name})
                                </span>
                              </span>
                            </div>

                            <div>
                              <del className="catlogUnitSpan">
                                MRP ₹{" "}
                                {parseFloat(pro.price * pro.pkg).toFixed(2)}
                              </del>
                              <span style={mystyle.newText}>
                                {" ₹ "}
                                {props.cat_data.flattDiscount
                                  ? parseFloat(
                                      (pro.price - props.cat_data.discount) *
                                        pro.pkg
                                    ).toFixed(2)
                                  : parseFloat(
                                      (pro.price *
                                        pro.pkg *
                                        (100 - props.cat_data.discount)) /
                                        100
                                    ).toFixed(2)}{" "}
                                <span className="catlogUnitSpan">
                                  (per {pro.pkg_unit})
                                </span>
                              </span>
                            </div>

                            <div>
                              <del className="catlogUnitSpan">
                                MRP ₹{" "}
                                {parseFloat(
                                  pro.price * pro.mpkg * pro.pkg
                                ).toFixed(2)}
                              </del>
                              <span style={mystyle.newText}>
                                {" ₹ "}
                                {props.cat_data.flattDiscount
                                  ? parseFloat(
                                      (pro.price - props.cat_data.discount) *
                                        pro.mpkg *
                                        pro.pkg
                                    ).toFixed(2)
                                  : parseFloat(
                                      (pro.price *
                                        pro.mpkg *
                                        (100 - props.cat_data.discount)) /
                                        100
                                    ).toFixed(2)}{" "}
                                <span className="catlogUnitSpan">
                                  (per {pro.mpkg_unit})
                                </span>
                              </span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
          <p className="btn orange-btn m-3" onClick={assignCustomer}>
            Save
          </p>
        </div>
      </div>
    </>
  );
}

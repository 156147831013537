import React, { useContext, useEffect, useState } from "react";
import "./partialcss.css";
import { CartButton } from "./MyButton";
import OrderContext from "../context/Order/OrderCon";
import { useNavigate, useNavigation } from "react-router-dom";
const PartialOrdermodel = ({
  old_orders,
  edit_body,
  close,
  showCon,
  showPreloader,
  userid,
}) => {
  const [partialOrder, setPartialOrder] = useState([]);

  const ordercon = useContext(OrderContext);
  const { creatPartialOrder } = ordercon;

  console.log("old_orders - ", old_orders);
  console.log("edit body - ", edit_body);

  const indexList = []; //{i:0}

  const orderDeatilsdata = () => {
    setPartialOrder(
      old_orders
        .filter((old_ord, index) => {
          const e_ord_list = edit_body.order_list;

          if (e_ord_list.length > index) {
            const edited = e_ord_list[index];

            if (
              old_ord.product_id._id === edited.product_id._id &&
              old_ord.attr_id === edited.attr_id &&
              old_ord.type === edited.type &&
              old_ord.qty > edited.qty
            ) {
              indexList.push({ i: index });
              return true;
            }
          }
          return false; // If the index doesn't match, filter it out
        })
        .map((old_ord, index) => {
          const e_ord_list = edit_body.order_list;
          const edited = e_ord_list[indexList[index].i];
          const diff = old_ord.qty - edited.qty;

          return { ...old_ord, qty: diff };
        })
    );

    // setPartialOrder(p_data);
  };

  useEffect(() => {
    // console.log("component called - ",old_orders);
    // console.log(edit_body);
    orderDeatilsdata();
  }, []);

  const style = {
    background: "#00000080",
    display: "block",
  };

  let grandTotal = 0;

  const navigation = useNavigate();

  const handleClick = () => {
    close();
    showCon(
      "Are you sure, you want to create this new order of remaining products ?",
      true,
      async () => {
        // deleteCategory(c._id);
        showPreloader("preShow");
        showCon("", false, {});

        const body = {
          supplier_id: userid.supplier_id,
          supplier_c_id: userid.supplier_c_id,
          create_by: "supplier",
          order_status: "pending",
          order_list: partialOrder,
          total_price: grandTotal,
        };

        creatPartialOrder(userid.userid, body, (s) => {
          showPreloader("");
          if (s) {
            navigation("orders");
          }
        });
      }
    );
  };

  return (
    <>
      <div className="modal reviewModal" style={style}>
        <div className="modal-dialog" style={{ width: "60%" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <span className="o_d_vari">Partial Order Remaining</span>
                <small className="d-block">
                  Do you want to create a new Order for the ramaining products ?
                </small>
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={close}
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive text-nowrap ">
                <table className="table table-hover orderDetailTable">
                  <tbody className="table-border-bottom-0 order_history_body order_details">
                    {partialOrder.map((pro, index) => {
                      let price =
                        pro.product_id.p_price.length > 0
                          ? parseFloat(
                              pro.product_id.p_price.filter(
                                (item) => item._id === pro.attr_id
                              )[0].value
                            )
                          : pro.price;

                      let mrp = price;
                      let unitType = pro.product_id.unit_name;

                      let salePrice = pro.catlog_id.flattDiscount
                        ? price - pro.catlog_id.discount
                        : (price * (100 - pro.catlog_id.discount)) / 100;

                      if (pro.sp_rate !== "null") {
                        let sp_data = "";
                        try {
                          sp_data = JSON.parse(pro.sp_rate);
                          salePrice =
                            sp_data.flattDiscount === true
                              ? price - sp_data.discount
                              : (price * (100 - sp_data.discount)) / 100;
                        } catch (err) {
                          sp_data = pro.sp_rate;
                        }
                      }

                      if (pro.type === "mpkg") {
                        price =
                          price * pro.product_id.mpkg * pro.product_id.pkg;
                        salePrice =
                          salePrice * pro.product_id.mpkg * pro.product_id.pkg;
                        unitType = pro.product_id.mpkg_unit;
                      } else if (pro.type === "pkg") {
                        price = price * pro.product_id.pkg;
                        salePrice = salePrice * pro.product_id.pkg;
                        unitType = pro.product_id.pkg_unit;
                      } else {
                        price = price;
                        salePrice = salePrice;
                      }

                      const total = parseFloat(salePrice * pro.qty).toFixed(2);
                      grandTotal = grandTotal + parseFloat(total);

                      return (
                        <>
                          <tr key={index}>
                            <td className="orderd_image_containe">
                              <img
                                src={
                                  pro.product_id.p_gallery_image.length > 0
                                    ? pro.product_id.p_gallery_image[0].link
                                    : ""
                                }
                                alt="Product Image"
                                className="img-fluid"
                              />
                            </td>
                            <td>
                              <p className="o_d_title">
                                {pro.product_id.p_name}
                              </p>
                              <span className="o_d_vari">
                                {" "}
                                {pro.product_id.p_price.length === 0
                                  ? "No Variation"
                                  : pro.product_id.p_price.map((p) => {
                                      return p.value === pro.price
                                        ? p.name
                                        : "";
                                    })}
                              </span>
                              {/* <small className="o_d_sku">
                                    SKU : {pro.product_id.p_sku}
                                  </small> */}
                              <small className="o_d_sku">
                                MRP :{" "}
                                <strike>₹{parseFloat(price).toFixed(2)}</strike>
                              </small>
                            </td>
                            <td className="o_d_price_cell">
                              <small>
                                ₹{parseFloat(salePrice).toFixed(2)}{" "}
                              </small>
                              <span className="o_d_p_cell_value">
                                / {unitType}
                              </span>
                            </td>
                            <td>X</td>
                            <td>
                              <CartButton
                                btnShow={false}
                                show={true}
                                value={pro.qty}
                              />
                            </td>

                            <td className="totalCell">
                              <span>₹{total}</span>
                            </td>
                          </tr>
                          {index < partialOrder.length - 1 && (
                            <tr>
                              <td colSpan={6}>
                                <hr />
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer border-1">
              <table width={"100%"}>
                {/* <tr>
                      <td colSpan={6} className="p-0">
                        <hr className="mb-0 pb-0" />
                      </td>
                    </tr> */}
                <tr>
                  {/* <td className="order_note">Order Note</td>
                      <td className="order_note_content">
                        <small>
                          {orderDetailsData.remark}
                        </small>{" "}
                      </td> */}
                  <td className="order_note">Grand Total</td>
                  <td></td>
                  <td></td>
                  <td className="o_d_title totalCell">
                    ₹{parseFloat(grandTotal).toFixed(2)}
                  </td>
                </tr>
              </table>
              <div className="w-100 " style={{ textAlign: "right" }}>
                <button
                  type="reset"
                  className="btn btn-outline-primary me-3 my-btn"
                  onClick={close}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn orange-btn btn-primary "
                  onClick={handleClick}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartialOrdermodel;

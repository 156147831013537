import React, { useState } from "react";
import SettingContext from "./SettingContext";
//require("dotenv").config();

const SettingState = (props) => {
  const host = process.env.REACT_APP_URL;
  const [setting, setSetting] = useState({
    invoiceSetting: {
      bankData: {
        bankName: "",
        ifsc: "",
        branch: "",
        holdername: "",
      },
      signatory: "",
      invoice_prefix: "",
    },
    _id: "",
    userid: "",
    c_id: "",
    updatedAt: "",
    createdAt: "",
    __v: 0,
  });

  const getinvoiceSetting = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        // body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/settings/getinvoicesetting/${localStorage.getItem(
          "com_id"
        )}`,
        requestOptions
      );
      const datares = await response.json();
      setSetting(datares.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const [showMenu, setShowMenu] = useState(false);  
  const toogle = ()=>{
    setShowMenu(!showMenu);
  }


  const [width, setWidth] = useState(window.innerWidth);

  return (
    <SettingContext.Provider
      value={{
        width, setWidth,
        setting,
        getinvoiceSetting,
        showMenu,
        toogle
      }}
    >
      {props.children}
    </SettingContext.Provider>
  );
};

export default SettingState;

import React, { useContext, useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import SupplierContext from "../context/GetSupplier/SupplierContext";
import SettingContext from "../context/Setting/SettingContext";
import SupplierCard from "../MobilePages/SupplierCard";

export default function Supplier() {
  const [modal, setModal] = useState(false);
  //const [uploadModal, setUploadmodeal] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);
  const navigate = useNavigate();
  const style = {
    background: "#00000080",
    display: "block",
  };
  const { width } = useContext(SettingContext);

  const openData = () => {
    setModal(!modal);
  };

  // const openUploadModal = () => {
  //   setUploadmodeal(!uploadModal);
  // };

  const openconfirmModal = () => {
    setconfirmModal(!confirmModal);
  };

  // get suppliers
  const supllier_context = useContext(SupplierContext);
  const { allSuppliers, getSuppliers } = supllier_context;

  useEffect(() => {
    getSuppliers();
  }, []);

  const SupData = allSuppliers.map((sup, index) => {
    return (
      <>
        {width < 767 ? (
          <SupplierCard data={sup}/>
        ) : (
          <tr key={index}>
            <td className="px-0">
              <img
                src="../assets/img/avatars/5.png"
                alt="Avatar"
                width="60"
                height="60"
                className="rounded-circle customer_image my-auto"
              />
            </td>
            <td>
              {sup.supplier_id.fullname}
              <br />
              <span className="com_name">({sup.com.com_name})</span>
            </td>
            <td>{sup.supplier_id.phone}</td>
            <td>{sup.com.address}</td>
            <td>
              <button
                className="btn btn-primary placebutton orange-btn  me-2 mb-2"
                onClick={() => {
                  navigate("/createorder/customer/" + sup.supplier_id._id);
                }}
              >
                Create Order
              </button>
            </td>
          </tr>
        )}
      </>
    );
  });

  return (
    <>
      {/* modal add customer */}

      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Order
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Customer Name :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="review_name"
                      placeholder="Customer Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message-text" className="col-form-label">
                      Customer Phone No.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="review_name"
                      placeholder="Customer Phone No."
                    />
                  </div>
                </form>

                <p className="instruction_para">
                  [Customer Name] will recieve an invitation from your Company,
                  when he will accept your invitation your customer will be
                  added.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary orange-btn me-2 mb-2"
                >
                  Create Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* modal add customer */}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <h4 className="card-header c_heading px-3 pt-0">
              <span className="headingcontent">
                My Suppliers ({allSuppliers.length})
              </span>

              <div className="heading_buttons"></div>

              {/* <div className="heading_buttons">
              <Slider_button/>                
              </div> */}
            </h4>
            {/* <input
              className="form-control search mb-4"
              placeholder="Search Supplier..."
            /> */}
          </div>

          <div className="card">
            <h5 className="card-header">
              <div className="row">
                <div className="col-lg-12 text-right">
                  <input
                    className="form-control search mb-4"
                    placeholder="Search Supplier..."
                  />
                </div>
              </div>
            </h5>
            <div className="card-body">
              <div className="table-responsive text-nowrap mb-1">
                {width < 767 ? (
                  SupData
                ) : (
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Image</th>
                        <th>Supplier Name</th>
                        <th>Phone</th>
                        <th>Address</th>

                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {SupData}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

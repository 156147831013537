import React, { useContext, useEffect } from "react";
import SettingContext from "../context/Setting/SettingContext";

const Wrapper = ({ children }) => {
  const { showMenu, toogle, width, setWidth } = useContext(SettingContext);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div
      className="layout-wrapper layout-content-navbar"
      onClick={() => {
        if (showMenu) {
          toogle();
        }
      }}
    >
      {children}
    </div>
  );
};

export default Wrapper;

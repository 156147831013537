import React from 'react'

const Upgrade_App = () => {
  return (
    <>
    <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">

        </div>
        </div>
    </>
  )
}

export default Upgrade_App
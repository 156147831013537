import React, { useState } from "react";
import CatContext from "./catContext";
//require("dotenv").config();

const CatState = (props) => {
  const host = process.env.REACT_APP_URL;
  // attribute related code start form here

  const attributes = [];
  const [attribute, setAttribute] = useState(attributes);

  const getAttr = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(`${host}/api/attr/getAttr`, requestOptions);
    const datares = await response.json();

    //console.log(datares.data);
    setAttribute(datares.data);
  };

  const addNewAttribute = async (data) => {
    try {
      const bodydata = data;
      console.log(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/attr/createAttr`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Failed") {
      } else {
        const comp = datares.data;
        setAttribute(attribute.concat(comp));
      }

      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  const getAllCategory = async (
    loadMore = false,
    page = 1,
    query = "",
    call_back = (e) => {}
  ) => {
    const com_id = localStorage.getItem("com_id");
    if (com_id == null) {
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/category/getCategory/${localStorage.getItem("com_id")}?${
        query === "" ? "" : `search_query=${query}&`
      }page=${page}&limit=20`,
      requestOptions
    );
    const datares = await response.json();

    setTotalCount(datares.totalCount);
    if (loadMore) {
      setCategory(category.concat(datares.data));
    } else {
      setCategory(datares.data);
    }
    call_back(datares.data.length);
  };

  const categories = [
    {
      _id: "",
      userid: "",
      cat_name: "",
      sub_items: [],
      createdAt: "",
      __v: 0,
    },
  ];
  const [category, setCategory] = useState([]);

  const [totalCount, setTotalCount] = useState(0);

  // add category
  const addCategory = async (com_name) => {
    try {
      const bodydata = com_name;
      console.log(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/category/createCategory`,
        requestOptions
      );
      const datares = await response.json();

      const comp = datares.data;
      setCategory(category.concat(comp));
      return "success";
    } catch (e) {
      console.log(e.message);
    }
  };

  // update category

  const updateCategory = async (id, com_name) => {
    try {
      const bodydata = com_name;
      console.log(bodydata);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/category/updateCategory/${id}`,
        requestOptions
      );
      const datares = await response.json();

      const comp = datares.data;

      let m_cat = category.filter((cat) => {
        return cat._id === id;
      });

      const update = category.map((cat) => {
        return cat._id === id ? comp : cat;
      });

      setCategory(update);

      return datares.status;
    } catch (e) {
      console.log(e.message);
    }
  };

  // addSubCat
  const addSubCategory = async (com_name, id) => {
    try {
      const bodydata = com_name;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/category/addSubCategory/${id}`,
        requestOptions
      );
      const datares = await response.json();
      console.log(category);
      if (datares.status === "Sucess") {
        const comp = datares.data;
        console.log(comp);
        let oldCategory = JSON.parse(JSON.stringify(category));
        for (let index = 0; index < oldCategory.length; index++) {
          const element = oldCategory[index];
          if (element._id === id) {
            oldCategory[index] = comp;
            //console.log(newCompany);
            break;
          }
        }
        setCategory(oldCategory);
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  // update sub category
  const updateSubCategoryApi = async (com_data) => {
    try {
      const bodydata = com_data;
      console.log(bodydata);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/category/updateSubCategory/`,
        requestOptions
      );
      const datares = await response.json();

      if (datares.status === "Success") {
        const comp = datares.data;

        const update = category.map((cat) => {
          return cat._id === com_data.p_id ? comp : cat;
        });

        setCategory(update);
      }

      return datares.status;
    } catch (e) {
      console.log(e.message);
    }
  };

  // delete cateory
  const deleteCategoryapi = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const url = `${host}/api/category/deleteCategory/` + id;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();

      if (datares.status === "Success") {
        const newCategory = category.filter((com) => {
          return com._id !== id;
        });
        setCategory(newCategory);
      } else {
        alert("Something wentwrong");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  //Delete SubCategory
  const deleteSubCategoryapi = async (data, id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(data),
      };
      const url = `${host}/api/category/deleteSubCategory/${id}/${data.id}`;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();

      //console.log(datares);

      if (datares.status === "Sucess") {
        let oldCategory = JSON.parse(JSON.stringify(category));
        for (let index = 0; index < oldCategory.length; index++) {
          const comp = datares.data;
          const element = oldCategory[index];
          if (element._id === id) {
            oldCategory[index] = comp;
            console.log(oldCategory);
            break;
          }
        }
        setCategory(oldCategory);
      }
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <CatContext.Provider
      value={{
        category,
        totalCount,
        getAllCategory,
        addCategory,
        addSubCategory,
        deleteCategoryapi,
        deleteSubCategoryapi,
        updateCategory,
        updateSubCategoryApi,

        attribute,
        addNewAttribute,
        getAttr,
      }}
    >
      {props.children}
    </CatContext.Provider>
  );
};

export default CatState;

import React, { useState } from "react";
import Noticontext from "./NotifContext";

//require("dotenv").config();
const NotiState = (props) => {
  const host = process.env.REACT_APP_URL;

  const [notification, setnotification] = useState([]);

  const getAllNotificaiton = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/notification/getAllNotification`,
        requestOptions
      );
      const datares = await response.json();
      setnotification(datares.data);
    } catch (error) {}
  };

  const pushNotification = async (body) => {
    const requestOptions2 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(body),
    };
    // const response2 = await fetch(
    //   `${host}/notification/createNoti`,
    //   requestOptions2
    // );

    const response2 = await fetch(
      `${host}/notification/push_noti`,
      requestOptions2
    );

    const notires = await response2.json();

    console.log("notification - ", notires);
  };

  return (
    <Noticontext.Provider
      value={{
        notification,
        getAllNotificaiton,
        pushNotification,
      }}
    >
      {props.children}
    </Noticontext.Provider>
  );
};
export default NotiState;

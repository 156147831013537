import React, { useContext } from "react";
import logo from "../mylogo.png";

import { Link, NavLink, useLocation } from "react-router-dom";
import SettingContext from "../context/Setting/SettingContext";

export default function NavbarData() {
  let location = useLocation();

  const { showMenu, toogle } = useContext(SettingContext);

  return (
    <>
      <aside
        id="layout-menu"
        className={`${
          showMenu ? "layout-menu-vips" : "layout-menu"
        } menu-vertical menu bg-menu-theme`}
      >
        <div className="app-brand demo">
          <Link to="/" className="app-brand-link">
            <span className="app-brand-logo demo">
              <img src={logo} alt="logo" width="120" />
            </span>
            {/* <span className="app-brand-text demo menu-text fw-bolder ms-2">
              MYORDERSLIP
            </span> */}
          </Link>

          <Link
            to="#"
            onClick={toogle}
            className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
          >
            <i className="bx bx-chevron-left bx-sm align-middle"></i>
          </Link>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <NavLink
            to="/dashboard"
            className={`menu-item ${location.pathname === "/" ? "active" : ""}`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-home-circle"></i>
              <div data-i18n="Analytics">Dashboard</div>
            </li>
          </NavLink>

          <NavLink
            to="/mycompanies"
            className={`menu-item ${
              location.pathname === "/mycompanies" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-layout"></i>
              <div data-i18n="Layouts">My Companies</div>
            </li>
          </NavLink>

          <NavLink
            to="/orders"
            className={`menu-item ${
              location.pathname === "/orders" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div data-i18n="Layouts">My Orders</div>
            </li>
          </NavLink>

          <NavLink
            to="/catelog"
            className={`menu-item ${
              location.pathname === "/catelog" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-book-open"></i>
              <div data-i18n="Layouts">Catalogs</div>
            </li>
          </NavLink>

          <NavLink
            to="/category"
            className={`menu-item ${
              location.pathname === "/category" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-category"></i>
              <div data-i18n="Layouts">Category</div>
            </li>
          </NavLink>

          <NavLink
            to="/products"
            className={`menu-item ${
              location.pathname === "/products" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-cart"></i>
              <div data-i18n="Layouts">Products</div>
            </li>
          </NavLink>

          <NavLink
            to="/inventory"
            className={`menu-item ${
              location.pathname === "/inventory" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-category"></i>
              <div data-i18n="Layouts">inventory</div>
            </li>
          </NavLink>

          {/* <NavLink
            to="/rawMaterial"
            className={`menu-item ${
              location.pathname === "/rawMaterial" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-collection"></i>
              <div data-i18n="Layouts">Manage Raw Material</div>
            </li>
          </NavLink> */}

          <NavLink
            to="/customers"
            className={`menu-item ${
              location.pathname === "/customers" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-user"></i>
              <div data-i18n="Layouts">Customers</div>
            </li>
          </NavLink>

          <NavLink
            to="/suppliers"
            className={`menu-item ${
              location.pathname === "/suppliers" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-group"></i>
              <div data-i18n="Layouts">Suppliers</div>
            </li>
          </NavLink>

          <NavLink
            to="/payments"
            className={`menu-item ${
              location.pathname === "/payments" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-money"></i>
              <div data-i18n="Layouts">Payments</div>
            </li>
          </NavLink>

          {/* <NavLink
            to="/reports"
            className={`menu-item ${
              location.pathname === "/reports" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div data-i18n="Layouts">Reports</div>
            </li>
          </NavLink> */}

          {/* <NavLink
            to="/invoice"
            className={`menu-item ${
              location.pathname === "/invoice" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-file"></i>
              <div data-i18n="Layouts">Invoice</div>
            </li>
          </NavLink> */}

          <NavLink
            to="/emplyoee"
            className={`menu-item ${
              location.pathname === "/emplyoee" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-user"></i>
              <div data-i18n="Layouts">Staff</div>
            </li>
          </NavLink>

          <NavLink
            to="/roles-permission"
            className={`menu-item ${
              location.pathname === "/roles-permission" ? "active" : ""
            }`}
          >
            <li className="menu-link">
              <i className="menu-icon tf-icons bx bx-shield"></i>
              <div data-i18n="Layouts">Roles & Permission</div>
            </li>
          </NavLink>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Setting</span>
          </li>

          <li className="menu-item">
            <Link tp="#" className="menu-link menu-toggle">
              <i className="menu-icon tf-icons bx bx-detail"></i>
              <div data-i18n="Form Elements">Settings</div>
            </Link>
            <ul className="menu-sub">
              <li className="menu-item">
                <a href="forms-basic-inputs.html" className="menu-link">
                  <div data-i18n="Basic Inputs">Basic Inputs</div>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <Link to="/" className="menu-link">
              <i className="menu-icon tf-icons bx bx-detail"></i>
              <div data-i18n="Form Layouts">Terms & Condition</div>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="" className="menu-link">
              <i className="menu-icon tf-icons bx bx-table"></i>
              <div data-i18n="Tables">Privacy Policy</div>
            </Link>
          </li>

          <li className="menu-header small text-uppercase">
            <span className="menu-header-text">Misc</span>
          </li>
          <li className="menu-item">
            <Link
              to="https://github.com/themeselection/sneat-html-admin-template-free/issues"
              className="menu-link"
            >
              <i className="menu-icon tf-icons bx bx-support"></i>
              <div data-i18n="Support">Support</div>
            </Link>
          </li>
        </ul>
      </aside>
      {/* <div className="layout-overlay layout-menu-toggle"></div> */}
    </>
  );
}

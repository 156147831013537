import React, {useState, useContext } from "react";
import ComContext from "../context/Company/CompanyContext";

export default function EditCompany(props) {
  
    
  const context = useContext(ComContext);
  const { addComapny } = context;
  const [company, setCompany] = useState({
    firstName:"",
    lastName:"",
    bus_type:"",
    indus_type:"",
    email:"",
    phone:"",
    address:"",
    state:""
  });

  const handleClick = (e) => {
    e.preventDefault();
    const restr = addComapny(company);
    console.log(restr);
  };

  const onChange = (e)=>{
    setCompany({...company, 
      [e.target.name]:e.target.value
     })
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <h4 className="card-header c_heading">Company Details</h4>

                <div className="card-body">
                  <div className="d-flex align-items-start align-items-sm-center gap-4">
                    <img
                      src="../assets/img/avatars/1.png"
                      alt="user-avatar"
                      className=" rounded-circle "
                      height="140"
                      width="140"
                      id="uploadedAvatar"
                    />
                    <div className="button-wrapper text-align-center">
                      <label
                        htmlFor="upload"
                        className="btn btn-primary orange-btn me-2 mb-2 "
                        tabindex="0"
                      >
                        <span className="d-none d-sm-block">
                          Upload Company Logo
                        </span>
                        <i className="bx bx-upload d-block d-sm-none"></i>
                        <input
                          type="file"
                          id="upload"
                          className="account-file-input"
                          hidden
                          accept="image/png, image/jpeg"
                        />
                      </label>
                      {/* <button
                        type="button"
                        className="btn btn-outline-secondary account-image-reset mb-4"
                      >
                        <i className="bx bx-reset d-block d-sm-none"></i>
                        <span className="d-none d-sm-block">Reset</span>
                      </button> */}

                      <p className="text-muted mb-0  instruction">
                        Allowed JPG, JPEG or PNG.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="card-body formdata">
                  <form
                    id="formAccountSettings"
                    method="POST"
                    onsubmit="return false"
                  >
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label htmlFor="com_name" className="form-label">
                          Company Name <span className="important">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="com_name"
                          name="com_name"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="bus_type" className="form-label">
                          Business Type <span className="important">*</span>
                        </label>
                        <select id="bus_type" className="select2 form-select"
                        onChange={onChange} name="bus_type"
                        >
                          <option value="">Choose Type</option>
                          <option value="manufacturer">Manufacturer</option>
                          <option value="wholesaler">Wholesaler</option>
                          <option value="distributor">Distributor</option>
                          <option value="retailer">Retailer</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="indus_type" className="form-label">
                          Industry Type
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="indus_type"
                          id="indus_type"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="email" className="form-label">
                          E-mail
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="email"
                          name="email"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="gst_no" className="form-label">
                          GST No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="gst_no"
                          name="gst_no"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="phone">
                          Phone Number <span className="important">*</span>
                        </label>
                        <div className="input-group input-group-merge">
                          <span className="input-group-text">IN (+91)</span>
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            className="form-control"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="address" className="form-label">
                          Address <span className="important">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="address"
                          name="address"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="state" className="form-label">
                          State <span className="important">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          id="state"
                          name="state"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label htmlFor="zipCode" className="form-label">
                          Zip Code <span className="important">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="zipCode"
                          name="zipCode"
                          maxlength="6"
                          onChange={onChange}
                        />
                      </div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="country">
                          Country <span className="important">*</span>
                        </label>
                        <select id="country" className="select2 form-select">
                          <option value="">Select</option>
                          <option value="Australia">Australia</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Brazil">Brazil</option>
                          <option value="Canada">Canada</option>
                          <option value="China">China</option>
                          <option value="France">France</option>
                          <option value="Germany">Germany</option>
                          <option value="India" selected>
                            India
                          </option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Japan">Japan</option>
                          <option value="Korea">Korea, Republic of</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Russia">Russian Federation</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2">
                      <button
                        type="reset"
                        className="btn btn-outline-primary me-2 my-btn"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn orange-btn btn-primary me-2"
                        onClick={handleClick}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {/* delete card */}

              <div className="card">
                <h5 className="card-header text-black">Delete Company</h5>
                <div className="card-body">
                  <div className="mb-3 col-12 mb-0">
                    <div className="alert alert-warning">
                      <h6 className="alert-heading fw-bold mb-1">
                        Are you sure you want to delete your company?
                      </h6>
                      <p className="mb-0">
                        Once you delete your company, you'll lose all your company data and there is no going back.
                        Please be certain.
                      </p>
                    </div>
                  </div>
                  <form id="formAccountDeactivation" onsubmit="return false">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="accountActivation"
                        id="accountActivation"
                        
                      />
                      <label
                        className="form-check-label"
                        htmlFor="accountActivation"
                      >
                        I confirm my company deletion
                      </label>
                    </div>
                    <p
                    
                      type="submit"
                      className="btn btn-danger  disabled" id="mydeleteButton"
                    >
                      Delete
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-backdrop fade"></div>
      </div>
    </>
  );
}

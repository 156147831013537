import React, { useContext, useEffect } from "react";
import SupplierContext from "../../context/GetSupplier/SupplierContext";


async function nofiyuser(noficationText = "asdf"){
  if(!"Notification" in window){
    alert("Browser does not support");
  }else if(Notification.permission === "granted"){
    const notification = new Notification(noficationText);
  }else if(Notification.permission !== "denied"){
    Notification.requestPermission().then((permission)=>{
      if(permission === "granted"){
        const notification = new Notification(noficationText);
      }
    });
  }else{
    alert("Not permiss")
    Notification.requestPermission().then((permission)=>{
      if(permission === "granted"){
        const notification = new Notification(noficationText);
      }
    });
  }
}


export default function Customer_orders(props) {
  const context = useContext(SupplierContext);
  const { orderProduct, getOrderDetails } = context;
  useEffect(() => {
    getOrderDetails();
  }, []);

  console.log(orderProduct);

  const openOrderSummary = (id)=>{
    console.log(id);
  }


  


  return (
    <>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button"></div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Orders ({orderProduct.length})
              </span>

              <div className="heading_buttons"></div>
            </h4>

            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row">
                    <div className="col-lg-6 text-right">                      
                    </div>

                    <div className="col-lg-6 font-bold">
                      {/* <MyButton /> */}
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Order ID</th>
                        <th>Supplier Details</th>
                        <th>Total Item</th>
                        <th>Order Value</th>
                        
                        <th>Order Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {orderProduct.map((order) => {
                        const date = order.createdAt.split("T");
                        return (
                          <tr key={order._id} onClick={()=>{openOrderSummary(order._id )}}>
                            <td>#{order.order_id}</td>
                            <td>{order.supplier_id.fullname} - ({order.supplier_id.phone})</td>
                            <td>{order.order_list.length}</td>
                            <td>₹ {order.total_price}</td>
                            
                            <td>{order.order_status}</td>
                            <td>{date[0]}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

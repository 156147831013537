import React, { useState } from "react";
import PaymentContext from "./PaymentCon";
import CryptoJS from "crypto-js";

//require("dotenv").config();
const PaymentGateway = (props) => {
  const host = process.env.REACT_APP_URL;
  const [pay_url, set_pay_url] = useState(null);

  const paymentIntiate = async (amount) => {
    try {
      let body = {
        amount: amount,
        MUID: "MUID" + Date.now(),
        transactionid: "T" + Date.now(),
      };

      const url = `${host}/api/phonepe/intiate`;
      const options = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      };

      const res = await fetch(url, options);
      const datares = await res.json();
      if (datares.status === "Success") {
        set_pay_url(datares.url);
        return true;
      } else {
        console.log("something went wrong!");
        return false;
      }
    } catch (err) {
      console.log("error intiate - ", err);
    }
  };

  return (
    <PaymentContext.Provider value={{ pay_url, paymentIntiate }}>
      {props.children}
    </PaymentContext.Provider>
  );
};

export default PaymentGateway;

import React, { useContext, useState, useEffect } from "react";
import productContext from "../context/Product/productContext";

export default function AddnewCatlog() {
  const [choosemodal, setChooseModal] = useState(false);

  const style = {
    background: "#00000080",
    display: "block",
  };

  const context = useContext(productContext);
  const { product, getAllProduct } = context;
  const [query, setQuery] = useState("");
  const [selectPorduct, setSelectProduct] = useState([]);
  const [newCatalog, setNewCatalog] = useState({
    c_id: localStorage.getItem("com_id"),
    catalog_name: "",
    flattDiscount: false,
    discount: "0",
    products: [],
    customers:[]
  });
  useEffect(() => {
    getAllProduct();
  }, []);

  const onproductSelect = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      const data = { p_id: e.target.value };
      selectPorduct.push(data);
    } else {
      for (let i = 0; i < selectPorduct.length; i++) {
        const element = selectPorduct[i];
        if (element.p_id === e.target.value) {
          selectPorduct.splice(i, 1);
        }
      }
    }
  };

  const saveProduct = (e) => {
    e.preventDefault();
    newCatalog.products = selectPorduct;
    setChooseModal(!choosemodal);
  };

  const onInputCatelogData = (e) => {
    setNewCatalog({ ...newCatalog, [e.target.name]: [e.target.value] });
  };

  const onDiscountFeature = (e) => {
    if (e.target.checked) {
      setNewCatalog({ ...newCatalog, [e.target.name]: true });
    } else {
      setNewCatalog({ ...newCatalog, [e.target.name]: false });
    }
  };

  const uploadFormData = (e) => {
    e.preventDefault();

    console.log(newCatalog);
  };

  return (
    <>
      {choosemodal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Choose Products
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setChooseModal(!choosemodal);
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control search"
                  placeholder="Search Product"
                  onChange={(e) => setQuery(e.target.value)}
                />
                <div className="row chooseProductModal">
                  {product
                    .filter(
                      (pro) =>
                        pro.p_name.toLowerCase().includes(query) ||
                        pro.p_sku.toLowerCase().includes(query)
                    )
                    .map((p, i) => {
                      return (
                        <div className="col-md-12 mx-auto my-1" key={i}>
                          <div className="card  p-0">
                            <div className="card-header p-2">
                              <div className="row ">
                                <div className="col-md-1">
                                  <div className="form-check mt-3">
                                    <input
                                      name="default-radio-1"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={p._id}
                                      id="defaultRadio1"
                                      onChange={onproductSelect}
                                    />
                                    {/* <input
                                      name="selectedProduct"
                                      className="form-check-input"
                                      type="checkbox"
                                      value={p._id}                                      
                                      onChange={onproductSelect}
                                    /> */}
                                  </div>
                                </div>

                                <div className="col-md-2">
                                  <img
                                    src={p.p_gallery_image[0].link}
                                    alt="Avatar"
                                    width="80"
                                    height="80"
                                    className="rounded-circle customer_image my-auto"
                                  />
                                </div>
                                <div className="col-md-5">
                                  <ul className="cusData1">
                                    <li>NAME - {p.p_name}</li>
                                    <li>SKU - {p.p_sku}</li>
                                    <li>INVENTORY - {p.inventory}</li>
                                  </ul>
                                </div>
                                <div className="col-md-4">
                                  <ul className="cusData2">
                                    <li>PKG - {p.pkg}</li>
                                    <li>MPKG - {p.mpkg}</li>
                                    <li>{p.inventory}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* 
                <p className="instruction_para">
                  [Customer Name] will recieve an invitation from your Company,
                  when he will accept your invitation your customer will be
                  added.
                </p> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary orange-btn me-2 mb-2"
                  onClick={saveProduct}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <h4 className="card-header c_heading">
          <span className="headingcontent">Add new catalog</span>
        </h4>
        <div className="col-md-12 col-lg-12">
          <div className="card mb-3">
            <div className="card-body">
              <form method="POST" onSubmit={uploadFormData}>
                <div className="form-group mb-2">
                  <label htmlFor="">Catalog Name</label>
                  <input
                    type="text"
                    className="form-control search m-0"
                    name="catalog_name"
                    id="catalog_name"
                    onChange={onInputCatelogData}
                    aria-describedby="helpId"
                    placeholder="Catalog Name"
                    required
                  />
                </div>
                <div className="form-check form-switch mb-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    name="flattDiscount"
                    onChange={onDiscountFeature}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Discount in Percentage
                  </label>
                </div>

                <div className="form-group mb-2">
                  <label htmlFor="">Catalog Discount</label>
                  <input
                    type="number"
                    className="form-control search m-0"
                    name="discount"
                    id="discount"
                    onChange={onInputCatelogData}
                    aria-describedby="helpId"
                    placeholder="Catalog Discount"
                    required
                  />
                </div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setChooseModal(!choosemodal);
                  }}
                  className="btn-primary orange-btn me-2 mt-3 "
                >
                  Add Product
                </button>

                <table className="table table-hover">
                  <thead className="order_history_header">
                    <tr>
                      <th>Product Name</th>
                      <th>SKU</th>
                      <th>C. Date</th>
                      <th>Inventory</th>
                      <th>M.O.Q</th>
                      <th>Pkg.</th>
                      <th>M.Pkg</th>
                      {/* <th>Status</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0 order_history_body">
                    {newCatalog.products.map((p, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <strong>{p.p_id}</strong>
                          </td>
                          <td>sku</td>
                          <td>23/12/2022</td>
                          <td>inventory</td>
                          <td>moq</td>
                          <td>pkg</td>
                          <td>mpkg</td>
                          {/* <td>
                          <span className="badge pending_style me-1">Pending</span>
                        </td> */}
                          <td>
                            {/* {c.p_gallery_image.length === 0 && (
                              <span
                                className="badge badge-center rounded-pill bg-secondary"
                                onClick={() => openData(c._id)}
                              >
                                <i className="bx bx-image-add"></i>
                              </span>
                            )} */}

                            <span className="badge badge-center rounded-pill bg-danger">
                              <i className="bx bxs-trash-alt"></i>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <button
                  type="submit"
                  className="btn-primary orange-btn me-2 mt-3 "
                >
                  Add Catalog
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";
import ReportContext from "./ReportContext";
//require("dotenv").config();

const ReportState = (props) => {
  const host = process.env.REACT_APP_URL;

  const [saleData, setsaleData] = useState([]);

  const getSaleReport = async (time) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/order/getOrderReport/${localStorage.getItem(
        "com_id"
      )}/${time}`,
      requestOptions
    );
    const datares = await response.json();
    setsaleData(datares.data);
  };


  const [partyLedger, setPartyLedger] = useState([]);
  const getPartyReport = async (userid, time) => {    
    const userType = JSON.parse(localStorage.getItem("user_data"));
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/payment/getPartyReport/${userType._id}/${userid}/${time}`,
      requestOptions
    );
    const datares = await response.json();
    setPartyLedger(datares.data);
  };

  const dateFormate = async (date) => {
    const timePickerMomentObj = new Date(date);
    const day = timePickerMomentObj.getDate();
    const month = timePickerMomentObj.getMonth() + 1;
    const fullyear = timePickerMomentObj.getFullYear();
    return timePickerMomentObj;
  };
  return (
    <ReportContext.Provider
      value={{
        saleData,
        getSaleReport,
        dateFormate,



        partyLedger,
        getPartyReport,
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};
export default ReportState;

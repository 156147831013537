import React, { useState } from "react";

export default function SupplierDetails() {
  const [modal, setModal] = useState(false);
  const [paymentmodal, setPayModal] = useState(false);

  const style = {
    background: "#00000080",
    display: "block",
  };
  const openData = () => {
    setModal(!modal);
  };
  const openpayment = () => {
    setPayModal(!paymentmodal);
  };

  const date = new Date();
  const futureDate = date.getDate() + 3;
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString("en-IN");

  return (
    <>
      {/* modal add customer */}

      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">
                  Set Limit
                </h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3 text-center">
                    <label htmlFor="recipient-name" className="col-form-label text-left">
                      Select Date
                    </label>
                    <select className="form-control">
                        <option value="">Today</option>
                        <option value="">Last Week</option>
                        <option value="">Last Month</option>
                        <option value="">Last 6 Months</option>
                        <option value="">Custom</option>
                    </select>
                    {/* <input
                      type="date"
                      className="form-control"
                      id="review_name"
                      placeholder="Enter Amount"
                    /> */}
                  </div>
                </form>

                <p className="instruction_para">
                  Select date to search the number of orders pending from the [customername]
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary orange-btn me-2 mb-2"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* modal add customer */}

      {paymentmodal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Payment
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openpayment}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Select Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="review_name"
                      placeholder="Enter Amount"
                      defaultValue={defaultValue}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Amount
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="review_name"
                      placeholder="Enter Amount"
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Payment Mode
                    </label>
                    <select
                      type="number"
                      className="form-control"
                      id="review_name"
                    >
                      <option>Cash</option>
                      <option>Check</option>
                      <option>Online</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Collected By
                    </label>
                    <select
                      type="number"
                      className="form-control"
                      id="review_name"
                    >
                      <option>Admin</option>
                      <option>Emplyess</option>
                    </select>
                  </div>
                </form>

                {/* <p className="instruction_para">
                  
                </p> */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary orange-btn me-2 mb-2"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <div className="col-lg-7 mb-4 order-0 mx-auto">
              <div className="card">
                <div className="d-flex align-items-center row">
                  <div className="col-sm-5 p-5 text-center ">
                    <img
                      src="../assets/img/avatars/1.png"
                      alt="Avtar"
                      className=" rounded-circle "
                    />
                  </div>
                  <div className="col-sm-7 ">
                    <div className="card-body">
                      <h4 className="card-title text-primary customerDetailsCompany">
                        Mindful tech invation private limited
                      </h4>
                      <ul className="customerDetailList">
                        <li>Harshit Garg</li>
                        <li>Saket Colony Hathras utter pradesh </li>
                        <li>6396617714</li>
                        <li>vishantsoni7264@gmail.com</li>
                        <li>GST</li>
                        <li>Business Type</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 order-0">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Outstanding Balance
                      </span>
                      <h3 className="card-title ">$12,628</h3>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-6 col-md-12 col-6 mb-4"
                  onClick={openpayment}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between"></div>
                      <span className="fw-normal block-header d-block mb-1">
                        Payment
                      </span>
                      <h3 className="card-title ">$12,628</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Order Placed
                      </span>
                      <h3 className="card-title ">5</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-12 col-6 mb-4"
                  onClick={openData}
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between">
                        {/* <div className="avatar flex-shrink-0">
                          <img
                            src="../assets/img/icons/unicons/chart-success.png"
                            alt="chart success"
                            className="rounded"
                          />
                        </div> */}
                      </div>
                      <span className="fw-normal block-header d-block mb-1">
                        Orders Pending
                      </span>
                      <h3 className="card-title ">4</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <ul className="slider_button slider-white">
                    <li className="slider-active">Order History</li>
                    <li>Payment History</li>
                  </ul>
                </h5>
                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Order ID</th>
                        <th>Placed On</th>
                        <th>Order Value</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      <tr>
                        <td>
                          <strong>Angular Project</strong>
                        </td>
                        <td>Albert Cook</td>
                        <td></td>
                        <td>
                          <span class="badge pending_style me-1">Pending</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>React Project</strong>
                        </td>
                        <td>Barry Hunter</td>
                        <td></td>
                        <td>
                          <span class="badge bg-label-success me-1">
                            Completed
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>VueJs Project</strong>
                        </td>
                        <td>Trevor Baker</td>
                        <td></td>
                        <td>
                          <span class="badge bg-label-warning me-1">
                            Rejected
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Bootstrap Project</strong>
                        </td>
                        <td>Jerry Milton</td>
                        <td></td>
                        <td>
                          <span class="badge bg-label-info me-1">Accepted</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Mode</th>
                        <th>Collected By</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      <tr>
                        <td>5,00,00</td>
                        <td>30/09/2022</td>
                        <td>Cash</td>
                        <td>Collected By</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

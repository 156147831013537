import React, { useContext, useEffect } from "react";
import AdminContext from "../context/admin/AdminContext";

const AdminDashboard = () => {
  const adminContext = useContext(AdminContext);
  const { AnalyticsData, allUserList } = adminContext;

  useEffect(() => {
    allUserList();
  }, []);
  return (
    <div className="row">
      <div className="col-lg-12 col-md-12 order-1">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="card-title d-flex align-items-start justify-content-between">
                  <div className="avatar flex-shrink-0">
                    <img
                      src="../assets/img/icons/unicons/chart-success.png"
                      alt="chart success"
                      className="rounded"
                    />
                  </div>
                  <div className="dropdown">
                    <button
                      className="btn p-0"
                      type="button"
                      id="cardOpt3"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="cardOpt3"
                    >
                      <p className="dropdown-item">View More</p>
                      <p className="dropdown-item">Delete</p>
                    </div>
                  </div>
                </div>
                <span className="fw-semibold d-block mb-1">Total Users</span>
                <h3 className="card-title mb-2">
                  {AnalyticsData.total_users}
                  {/* {parseFloat(
                        getDashDatam.payable ? getDashDatam.payable : 0
                      ).toFixed(2)} */}
                </h3>
                {/* <small className="text-success fw-semibold">
                    <i className="bx bx-up-arrow-alt"></i> +72.80%
                  </small> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="card-title d-flex align-items-start justify-content-between">
                  <div className="avatar flex-shrink-0">
                    <img
                      src="../assets/img/icons/unicons/wallet-info.png"
                      alt="Credit Card"
                      className="rounded"
                    />
                  </div>
                  <div className="dropdown">
                    <button
                      className="btn p-0"
                      type="button"
                      id="cardOpt6"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="cardOpt6"
                    >
                      <p className="dropdown-item">View More</p>
                      <p className="dropdown-item">Delete</p>
                    </div>
                  </div>
                </div>
                <span>Total Suppliers</span>
                <h3 className="card-title text-nowrap mb-1">
                  {AnalyticsData.total_supplier}
                  {/* {parseFloat(
                        getDashDatam.out_standing
                          ? getDashDatam.out_standing
                          : 0
                      ).toFixed(2)} */}
                </h3>
                {/* <small className="text-success fw-semibold">
                    <i className="bx bx-up-arrow-alt"></i> +28.42%
                  </small> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import ConfirmAlert from "../componant/ConfirmAlert/ConfirmAlert";
import productContext from "../context/Product/productContext";

export default function RawMaterial(props) {
  const mycontext = useContext(productContext);
  const { product, getAllWareHouseProduct, updateProduct } = mycontext;
  const [p_id, setp_id] = useState("");
  useEffect(() => {
    getAllWareHouseProduct();
  }, []);

  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }

  const [modal, setModal] = useState(false);
  const style = {
    background: "#00000080",
    display: "block",
  };
  const openData = (id) => {
    setModal(!modal);
    setp_id(id);
  };
  // console.log(product);

  const [myfiles, setfiles] = useState({
    files: [],
  });

  const selectHandler = (e) => {
    e.preventDefault();

    setfiles({ files: [...myfiles.files, ...e.target.files] });
  };

  const uploadimage = async (e) => {
    e.preventDefault();
    console.log(p_id);
    const data = new FormData();
    for (let i = 0; i < myfiles.files.length; i++) {
      const element = myfiles.files[i];
      data.append("file", element);
    }
    const res = await updateProduct(data, p_id);
    if (res.status === "Failed") {
      props.showAlert(res.msg, "danger");
    } else if (res.status === "Success") {
      props.showAlert("Image updated", "success");
    }

    setModal(!modal);

    setfiles({ files: [] });
  };

  // search filter started form here
  const [query, setQuery] = useState("");
  const search = (data) => {
    return data.filter((item) => item.p_name.toLowerCase().includes(query));
  };

  // to edit inventory section

  const manageInventory = () => { };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const openDelete = (id) => {
    setDeleteModal(!deleteModal);
    setDeleteId(id);
  };

  const deleteProductFunction = async () => {
    // const res = await deleteProduct(deleteId);
    // if (res.status === "Success") {
    //   props.showAlert("Product Deleted", "success");
    //   setDeleteModal(!deleteModal);
    // } else {
    //   props.showAlert(res.msg, "danger");
    // }
  };

  return (
    <>
      {deleteModal && (
        <ConfirmAlert
          open={openDelete}
          style={style}
          heading="Are you Sure want to delete this raw Product"
          confirm={deleteProductFunction}
        />
      )}
      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Set Image For Product
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <form method="post" onSubmit={uploadimage}>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="">Choose Image</label>
                    <input
                      type="file"
                      multiple
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      onChange={selectHandler}
                    />
                    <small id="helpId" className="form-text text-muted">
                      Image size should be smaller than 5 MB
                    </small>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary orange-btn me-2 mb-2"
                  >
                    Upload Images
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <Link
              to="/addNewProduct"
              className="btn btn-sm fixed_button btn-outline-primary"
            >
              + Add New Product
            </Link>
          </div>

          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                Raw Material ({product.length})
              </span>

              <div className="heading_buttons"></div>
            </h4>
            {/* <input
              className="form-control search mb-4"
              placeholder="Search Customers..."
            /> */}

            {/* table layout */}
            <div className="col-lg-12 mb-4 ">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Bulk Upload Products</li>
                        <li className="slider-active mx-2">Download PDF</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search by Product Name, sku, Status, etc..."
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </h5>

                <div className="table-responsive text-nowrap mb-1">
                  <table className="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Product Pic.</th>
                        <th>P. Name</th>
                        <th>Price</th>
                        <th>P.Sku</th>
                        <th>Category</th>
                        <th>Location</th>
                        <th>Inventory</th>
                        <th>Variation</th>
                        <th>Status</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0 order_history_body">
                      {product
                        .filter((product) =>
                          product.p_name.toLowerCase().includes(query)
                        )
                        .map((pro, i) => {
                          const subCategory = pro.cat_id.sub_items.filter(
                            (sub_cat) => {
                              return sub_cat._id === pro.sub_cat_id;
                            }
                          );

                          return (
                            <tr key={pro._id}>
                              <td>
                                <img
                                  src={
                                    pro.p_gallery_image.length !== 0
                                      ? pro.p_gallery_image[0].link
                                      : "../assets/img/avatars/5.png"
                                  }
                                  alt="Avatar"
                                  width="60"
                                  height="60"
                                  className="rounded-circle customer_image my-auto"
                                />
                              </td>
                              <td>{pro.p_name}</td>
                              <td>
                                ₹ {pro.price} per{" "}
                                {pro.unit_value + " " + pro.unit_name}
                                <br />₹ {pro.price * pro.pkg} per {pro.pkg_unit}
                                <br />₹ {pro.price *
                                  pro.pkg *
                                  pro.mpkg} per {pro.mpkg_unit}
                              </td>
                              <td>{pro.p_sku}</td>
                              <td>
                                {pro.cat_id.cat_name} ({subCategory[0].name})
                              </td>
                              <td>{pro.location}</td>
                              <td>
                                {pro.inventory}{" "}
                                <span
                                  className="badge badge-secondary mx-2 my-delete"
                                  onClick={() => {
                                    manageInventory(pro);
                                  }}
                                >
                                  <i className="bx bx-edit-alt "></i>
                                </span>
                              </td>
                              <td>
                                <ul>
                                  {pro.p_price.map((attr) => {
                                    return (
                                      <li key={attr._id}>
                                        <span className="unitSpan">
                                          ({attr.name}) - ₹ {attr.value} - (
                                          {attr.inventory})
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </td>
                              <td>
                                <span className="badge rounded-pill bg-label-success">
                                  Active
                                </span>
                              </td>
                              <td className="action">
                                {/* <div className="demo-inline-spacing"> */}
                                <span className="badge badge-secondary mx-2 my-delete">
                                  <i className="bx bx-edit-alt "></i>
                                </span>
                                <span
                                  className="badge badge-secondary my-delete"
                                  onClick={() => {
                                    openDelete(pro._id);
                                  }}
                                >
                                  <i className="bx bx-trash-alt "></i>
                                </span>

                                {/* </div> */}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* table layout */}
          </div>
        </div>
      </div>
    </>
  );
}

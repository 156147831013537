import React from "react";

const OrderCard = ({ data }) => {
  // Function to format the date as '23 Oct, 2024'
  const formatDateWithComma = (createdAt) => {
    const dateObj = new Date(createdAt);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("en-US", { month: "short" });
    const year = dateObj.getFullYear();
    return `${day} ${month}, ${year}`;
  };

  // Format the date if createdAt exists
  const formattedDate = data.createdAt
    ? formatDateWithComma(data.createdAt)
    : "N/A";

  const totalOrder = parseFloat(data.total_price).toFixed(2);

  let status_color = "#273D8E";
  switch (data.order_status) {
    case "pending":
      status_color = "#273D8E";
      break;
    case "confirmed":
      status_color = "#FDD835";
      break;
    case "packed":
      status_color = "#312113";
      break;
    case "dispatched":
      status_color = "#8900CFE0";
      break;
    case "delivered":
      status_color = "#49A752";
      break;
    case "rejected":
    case "cancelled":
      status_color = "#EA2B2B";
      break;
    default:
      status_color = "#000";
  }

  return (
    <>
      <div
        className="order-section card mx-2 my-2 p-3"
        style={{ position: "relative" }}
      >
        <span
          className="statusOrd px-4 py-1"
          style={{
            position: "absolute",
            top: "10px",
            right: "0",
            backgroundColor: status_color,
            color: "white",
            fontWeight: "700",
            textTransform: "uppercase",
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            fontSize: "12px",
          }}
        >
          {data.order_status === "pending"
            ? "New Order"
            : data.order_status === "confirmed"
            ? "In Process"
            : data.order_status}
        </span>
        <div className="ord-title">{data.nick_name?.nick_name || "N/A"}</div>
        <span className="comany-title">
          {data.user_comdata?.com_name || "Company - N/A"}
        </span>
        <div className="order-container-se d-flex justify-content-between">
          <span className="order_id_div">
            <span className="or-d">Order ID</span> ({data.f_order_id})
          </span>
          <span className="t-itmes">
            <span className="t-itms">Total Items</span> (
            {data.order_list?.length || 0})
          </span>
          <span className="order-date">{formattedDate}</span>
        </div>

        <div className="order-value-con d-flex justify-content-between ">
          <span className="order-value-se d-flex align-items-center">
            <div className="or-v">Order Value :</div>₹{totalOrder}
          </span>
          <span className="created-by-s">{data.create_by}</span>
        </div>
      </div>
    </>
  );
};

export default OrderCard;

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SupplierContext from "../context/GetSupplier/SupplierContext";

export default function PrivacyPolicy(props) {
 

  return (
    <>
     
    </>
  );
}

import React, { useState } from "react";

import ComContext from "./CompanyContext";
import { Navigate } from "react-router-dom";
//require("dotenv").config();
const CompanyState = (props) => {
  const host = process.env.REACT_APP_URL;

  const getallCompany = async () => {
    const token = localStorage.getItem("token");
    if (token == null) {
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/company/getCompanyList`,
      requestOptions
    );
    const datares = await response.json();
    setCompany(datares.data);
  };

  const companies = [];
  const [company, setCompany] = useState(companies);
  const [selectedCompany, setSelectedCompany] = useState({ id: "", name: "" });
  // delete company
  const deleteCompany = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const url = `${host}/api/company/deleteCompany/${id}`;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();

      if (datares.status === "Success") {
        const newCompany = company.filter((com) => {
          return com._id !== id;
        });
        setCompany(newCompany);
      } else {
        alert("Something wentwrong");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // edit company
  const editCompany = async (id) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(id),
      };
      let url = `${host}/api/company/udpateCompany/${id._id}`;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();
      let newCompany = JSON.parse(JSON.stringify(company));

      for (let index = 0; index < newCompany.length; index++) {
        const element = newCompany[index];
        if (element._id === id._id) {
          newCompany[index] = datares.data;
          //console.log(newCompany);
          break;
        }
      }
      setCompany(newCompany);
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  // add company
  const addComapny = async (com_name) => {
    try {
      const bodydata = com_name;
      console.log(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/company/createCompany`,
        requestOptions
      );
      const datares = await response.json();

      const comp = datares.data;
      setCompany(company.concat(comp));
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  const setComImage = async (formdata, id) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
        body: formdata,
      };
      const response = await fetch(
        `${host}/api/company/uploa_image/${id}`,
        requestOptions
      );
      const datares = await response.json();

      // const comp = datares.data;
      // let oldproduct = JSON.parse(JSON.stringify(product));
      // for (let index = 0; index < oldproduct.length; index++) {
      //   const element = oldproduct[index];
      //   if (element._id === id) {
      //     oldproduct[index] = comp;
      //     //console.log(newCompany);
      //     break;
      //   }
      // }
      // setProduct(oldproduct);
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };

  const getCompanyById = async (id) => {
    const token = localStorage.getItem("token");
    if (token == null) {
      return;
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/company/getCompanyById/${id}`,
      requestOptions
    );
    const datares = await response.json();
    return datares;
  };

  const checkcompany = () => {
    if (localStorage.getItem("com_id")) {
      return <Navigate to={"/mycompanies"} />;
    }
  };

  // dashboard
  const [getDashDatam, setGetDashData] = useState({});
  const getDash = async (com_name) => {
    try {
      const bodydata = com_name;
      console.log(bodydata);
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(`${host}/api/dash/getdash`, requestOptions);
      const datares = await response.json();

      

      if (datares.status === "Success") {
        setGetDashData(datares);
      } else {
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <ComContext.Provider
      value={{
        company,
        deleteCompany,
        addComapny,
        getallCompany,
        editCompany,
        getCompanyById,
        checkcompany,
        selectedCompany,
        setSelectedCompany,
        setComImage,
        getDashDatam,
        getDash,
      }}
    >
      {props.children}
    </ComContext.Provider>
  );
};

export default CompanyState;

import React, { useState } from "react";
import SupContext from "./SupplierContext";
//require("dotenv").config();

const SupplierState = (props) => {
  const host = process.env.REACT_APP_URL;
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [allSupplierProducts, setSupplierProducts] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);

  const [totalPro, setTotalPro] = useState(0);

  const getCreateOrderCateogy = async (supplier_id, auth_token = null) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": auth_token || localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/catalog/category_by_catlog_seller/${supplier_id}`,
      requestOptions
    );
    const datares = await response.json();

    setFilterCategory(datares.category);
  };

  const getSuppliers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/customer/supplier/getAllSupplier`,
      requestOptions
    );
    const datares = await response.json();

    setAllSuppliers(datares.data);
  };

  const getSupplierproducts = async (
    loadMore = false,
    id,
    page = 1,
    cat_id = null,
    sub_cat_id = null,
    query = "",
    auth_token = null
  ) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": auth_token || localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/catalog/getCatalogBySeller-V2/${id}?page=${page}${
        cat_id !== null ? `&cat_id=${cat_id}` : ""
      }${sub_cat_id !== null ? `&sub_cat_id=${sub_cat_id}` : ""}${
        query !== null ? `&search_query=${query}` : ""
      }`,
      requestOptions
    );
    const datares = await response.json();
    const data = await datares.allproducts.map((element) => {
      let d = { ...element, price: element.product_data.price };
      if (element.product_data.p_price.length > 0) {
        d = {
          ...element,
          price: element.product_data.price,
          attr_id: element.product_data.p_price[0]._id,
        };
      }
      return d;
    });
    if (loadMore === false) {
      setSupplierProducts(data);
    } else {
      setSupplierProducts([...allSupplierProducts, ...data]);
    }
    if (datares.total_pro.length > 0) {
      setTotalPro(datares.total_pro[0].totalProducts);
    }
  };

  // add to kart options start form here

  const [cartProducts, setCartProduct] = useState([]);
  const addtoCart = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/customer/supplier/addToCart`,
      requestOptions
    );
    const datares = await response.json();
    return datares.status;
  };

  const getCartProduct = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/customer/supplier/getCart`,
      requestOptions
    );
    const datares = await response.json();

    setCartProduct(datares.data);
  };

  const removeFromCart = async (id) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/customer/supplier/deleteProductCart/${id}`,
      requestOptions
    );
    const datares = await response.json();

    if (datares.status === "Success") {
      const newCart = cartProducts.filter((com) => {
        return com._id !== id;
      });
      setCartProduct(newCart);
    }

    return datares.status;
  };

  const updateCartQty = async (id, qty) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/customer/supplier/updatecart/${id}/${qty}`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        setCartProduct(
          cartProducts.map((pro, i) => {
            return pro._id === id ? { ...pro, qty: qty } : pro;
          })
        );
      } else {
      }
    } catch (error) {
      console.log("update cart qty = ", error);
    }
  };

  // orderSection start form here
  const [orderProduct, setOrderProduct] = useState([]);

  const createOrder = async (data) => {
    console.log(data);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${host}/api/order/createOrder`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      addNotification(
        data.supplier_id,
        "order",
        "New Orders",
        "Order from supplier"
      );
    }
    return datares;
  };

  const addNotification = async (userid, path, title, message) => {
    const requestOptions2 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ userid, path, title, message }),
    };
    const response2 = await fetch(
      `${host}/notification/createNoti`,
      requestOptions2
    );
    const notires = await response2.json();
  };

  const getOrderDetails = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(`${host}/api/order/getOrders`, requestOptions);
    const datares = await response.json();

    setOrderProduct(datares.data);
  };

  // // order details by order id
  // const getOrderDetailsById = async (id) => {

  // };

  // get company data
  const getCompanyData = async (supplier_id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/company/getCompanyById/${supplier_id}`,
      requestOptions
    );
    const datares = await response.json();
    return datares;
  };

  const getToken = async (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${host}/api/auth/genToken/${id}`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      localStorage.setItem("switch_account", "s_t_c");
      localStorage.setItem("cus_token", datares.token);
      localStorage.setItem("cus_userdata", JSON.stringify(datares.data));

      return datares;
    } else {
    }
  };

  return (
    <SupContext.Provider
      value={{
        totalPro,
        filterCategory,
        getCreateOrderCateogy,
        allSuppliers,
        getSuppliers,

        allSupplierProducts,

        getSupplierproducts,
        setSupplierProducts,
        addtoCart,

        cartProducts,
        getCartProduct,
        setCartProduct,
        removeFromCart,
        updateCartQty,

        orderProduct,
        createOrder,
        getOrderDetails,
        getCompanyData,
        getToken,
        // getOrderDetailsById
      }}
    >
      {props.children}
    </SupContext.Provider>
  );
};

export default SupplierState;

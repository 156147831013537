import React, { useState } from "react";
import OrderContext from "./OrderCon";

const OrderState = (props) => {
  const [myOrders, setMyOrders] = useState([]);
  const [totalOrder, setTotalOrder] = useState(0);
  const host = process.env.REACT_APP_URL;
  const getAllOrders = async (
    loadMore = false,
    page = 1,
    query = "",
    filter = "all",
    call_back = (l) => {},
    limit = 10
  ) => {
    // console.log("load more data - " + loadMore);
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/order/getOrdersBySupplier-v1/${localStorage.getItem(
        "com_id"
      )}?page=${page}${
        query === "" ? "" : `&search_query=${query}`
      }&filter=${filter}&limit=${limit}`,
      requestOptions
    );
    const datares = await response.json();
    if (datares.status === "Success") {
      setTotalOrder(datares.totalOrder);
      if (loadMore === true) {
        // console.log("run when load more true = " + page);
        // console.log("my order lenght = " + myOrders.length);
        setMyOrders(myOrders.concat(datares.data));
      } else {
        // console.log("run when load more false = " + page);
        if (datares.data.length === 0) {
          setMyOrders([]);
          console.log(datares.data);
        } else {
          setMyOrders(datares.data);
        }
      }
      call_back(datares.data.length);

    }
  };

  const orderStatusChange = async (orderid, statusType, str_id, call_back) => {
    try {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/order/changeOrderStatus/${orderid}/${statusType}/${str_id}`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        call_back("success", datares);
      } else {
        call_back("failed", datares);
      }
    } catch (error) {
      call_back("error", { msg: "Something went wrong!" });
    }
  };

  const creatPartialOrder = async (userid, body, call_back) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // "auth-token": localStorage.getItem("token"),
        },
      };
      const response = await fetch(
        `${host}/api/auth/genToken/${userid}`,
        requestOptions
      );
      const tokenRes = await response.json();
      console.log("success - ", tokenRes);
      if (tokenRes.status === "Success") {
        console.log("success");

        const customer_token = tokenRes.token;
        const c_status = await createOrder(customer_token, body);
        call_back(c_status);
      } else {
        call_back(false);
      }
    } catch (error) {
      console.log("error in create partial - ", error);
    }
  };

  const createOrder = async (token, body) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `${host}/api/order/createOrder`,
        requestOptions
      );
      const res = await response.json();
      if (res.status !== "Failed") {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log("error in create order - ", error);
    }
  };

  return (
    <OrderContext.Provider
      value={{
        myOrders,
        setMyOrders,
        totalOrder,
        getAllOrders,
        orderStatusChange,
        creatPartialOrder,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};
export default OrderState;

import React from 'react'
export default function ManageInventory(props){
    const {data} = props;
    // console.log(data);
    const style = {
        background: "#00000080",
        display: "block",
      };
    return(
        <>
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog" style={{width:"30em"}}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Inventory
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={props.open}
                ></button>
              </div>
              <form method="post" >
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="">Main Inventory</label>
                    <input
                      type="number"
                      multiple
                      className="form-control"
                      name=""
                      id=""
                      aria-describedby="helpId"
                      placeholder=""
                      defaultValue={data.inventory}                      
                    />
                    <small id="helpId" className="form-text text-muted">
                      {/* It's  */}
                    </small>
                  </div>

                  <h5>Variant Inventory</h5>
                  {data.p_price.map((attr)=>{
                    return (
                        <div class="form-group">
                          <label for="">Variant Name ({attr.name})</label>
                          <input type="text"
                            class="form-control" name="" id="" aria-describedby="helpId" placeholder=""
                            defaultValue={attr.inventory}/>                          
                        </div>
                    )
                  })}
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary orange-btn me-2 mb-2"
                  >
                    Update Inventory
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        </>
    )
}
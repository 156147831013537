import React, { useState, useContext, useEffect } from "react";
import Alert from "../componant/Alert";
import CatContext from "../context/Category/catContext";
export default function Attribute() {
  const mycontext = useContext(CatContext);
  const { attribute, addNewAttribute, getAttr } = mycontext;

  useEffect(() => {
    getAttr();
  }, []);

  const [newAttr, updateAttr] = useState({
    name: "",
    data: []
  });
  const [attr_data, setAttrData] = useState([
    {
      value: "",
    },
  ]);

  const onChange = (e) => {
    attr_data[e.target.name].value = e.target.value;
  };
  const onnameChange = (e) => {
    updateAttr({ name: e.target.value, data: [] });
  }

  const deleteCategory = () => { };


  const handleClick = async (e) => {
    e.preventDefault();

    newAttr.data = attr_data;

    const res = await addNewAttribute(newAttr);
    if (res.status === "Success") {
      showAlert(res.msg, "success");
    } else {
      showAlert(res.msg, "danger");
    }

  };

  const addConData = (e) => {
    e.preventDefault();
    setAttrData(attr_data.concat({ value: "" }))
    // console.log(attr_data);
  }
  // showaler
  const [alert, setAlert] = useState(null);
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
      active: "alert-active",
    });
    setTimeout(() => {
      setAlert(null);
    }, 2000);
  };

  return (
    <>
      {alert && <Alert alert={alert} />}
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">My Attributes ({attribute.length})</span>

              <div className="heading_buttons"></div>
            </h4>

            {/* category add section */}
            <div className="col-md-6  my-1">
              <div className="card  p-0">
                <div className="card-header py-3">
                  <h5>Add new Attribute</h5>
                </div>
                <div className="card-body">
                  <form method="post">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control search m-0"
                        name="cat_name"
                        id=""
                        aria-describedby="helpId"
                        placeholder="Attribute Name"
                        onChange={onnameChange}
                      />
                    </div>

                    <div className="attr_data_div">
                      {attr_data.map((c, i) => {
                        return (
                          <>
                            <input
                              type="text"
                              className="form-control search my-2 mx-0"
                              name={i}
                              id=""
                              aria-describedby="helpId"
                              placeholder="ConfigName"
                              onChange={onChange}
                            />
                          </>
                        );
                      })}
                    </div>

                    <button
                      type="submit"
                      className="btn-primary orange-btn me-2 mt-3 "
                      onClick={handleClick}
                    >
                      Add
                    </button>
                    <button
                      className="btn btn-sm my_gray_button btn-outline-primary"
                      onClick={addConData}>
                      Add Configration
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {/* category add section end */}

            <div className="col-md-6 mx-auto my-1">
              <h5>Attribute List</h5>
              {attribute.length === 0 && "No Atribute found"}
              {attribute.map((c, i) => {
                return (
                  <div className="card my-2 p-0" key={i}>
                    <div className="card-header p-2">
                      <div className="mycustomerData">
                        <ul className="mycustomerlist">
                          <li>{c.name}</li>
                          <li>
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn p-0 dropdown-toggle hide-arrow"
                                data-bs-toggle="dropdown"
                              >
                                Configration ({c.data.length})
                              </button>
                              <div className="dropdown-menu">
                                {c.data.map((s, j) => {
                                  return (
                                    <>
                                      <p
                                        className="dropdown-item"
                                        key={j}
                                      // onClick={() => {
                                      //   deleteSubCategory(s._id, c._id);
                                      // }}
                                      >
                                        <i className="bx bx-trash"></i> {s.value}{" "}
                                      </p>
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="dropdown">
                              <button
                                type="button"
                                className="btn p-0 dropdown-toggle hide-arrow"
                                data-bs-toggle="dropdown"
                              >
                                <i className="bx bx-dots-vertical-rounded"></i>
                              </button>
                              <div className="dropdown-menu">
                                <p
                                  className="dropdown-item"
                                  onClick={() => {
                                    deleteCategory(c._id);
                                  }}
                                >
                                  <i className="bx bx-trash me-1"></i> Delete
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";

const CategoryCard = ({
  data,
  openEditModal,
  openSubCategoryEdit,
  showCon,
  deleteCategory,
  openSubData,
}) => {
  const [showSub, setShowSub] = useState(false);
  const showSubDiv = () => {
    setShowSub(!showSub);
  };

  return (
    <>
      <div className="card p-3 my-2">
        <div className="d-flex justify-content-between ">
          <div className="cate-name">{data.cat_name}</div>
          <div className="dro">
            <div className="dropdown">
              <button
                type="button"
                className="btn p-0 dropdown-toggle hide-arrow"
                data-bs-toggle="dropdown"
              >
                <i
                  className="bx bx-dots-vertical-rounded "
                  style={{ color: "black" }}
                ></i>
              </button>
              <div className="dropdown-menu">
                <p
                  className="dropdown-item"
                  onClick={() => {
                    openEditModal(data);
                  }}
                >
                  <i
                    className="bx bx-edit-alt me-1"
                    onClick={() => {
                      openSubCategoryEdit(data, data._id);
                    }}
                  ></i>{" "}
                  Edit
                </p>
                <p
                  className="dropdown-item"
                  onClick={() => {
                    showCon("parent", data._id);
                  }}
                >
                  <i className="bx bx-trash me-1"></i> Delete
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="borde my-2"></div>

        <div className="sub-cat-sec d-flex justify-content-between">
          <span className="sub-name" style={{ fontSize: "12px" }}>
            Sub Category({data.sub_items.length})
          </span>
          <i
            className="bx bx-down-arrow me-1"
            style={{ transform: `rotate(${showSub ? 180 : 0}deg)` }}
            onClick={showSubDiv}
          ></i>

          <span
            className="btn btn-sm btn-outline-primary sb-btn"
            onClick={openSubData}
          >
            +Add
          </span>
        </div>
        {showSub && (
          <div className="sub-itmes-container mt-3 d-flex flex-wrap justify-content-between align-items-center w-100">
            {data.sub_items.map((s, index) => {
              return (
                <div key={index} className="d-flex align-item-center justify-content-between w-100">
                  <ul className="sb-con list-style-none">
                    <li className="sub-it">{s.name}</li>
                  </ul>
                  <div className="edit-del-c d-flex justify-content-between">
                    <i className="bx bx-edit-alt me-2"></i>
                    <i
                      className="bx bx-trash me-2"
                      onClick={() => {
                        showCon("sub", data._id, s._id);
                      }}
                      style={{ color: "red" }}
                    ></i>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryCard;

import React from "react";

const PaymentsCard = ({ data }) => {
 const formatDateWithComma = (createdAt) => {
   const dateObj = new Date(createdAt);
   const day = dateObj.getDate();
   const month = dateObj.toLocaleString("en-US", { month: "short" });
   const year = dateObj.getFullYear();
   return `${day} ${month}, ${year}`;
 };

 const formattedDate = data.createAt
   ? formatDateWithComma(data.createAt)
   : "N/A";


  return (
    <>
      <div className="card p-3 mb-2">
        <div className="pay-data d-flex justify-content-between">
          <div className="name-contet">
            <div className="pay-cus-name">{data.userid.nick_name}</div>

            <div className="pay-date">{formattedDate}</div>
          </div>
          <div className="pay-s">
            <div className="amt-se">₹{data.amt}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentsCard;

import React, { useState } from "react";

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  const goToNextSlide = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <div className="slider d-flex justify-content-center align-items-center">
      <button
        className="p-2 m-2 border-0"
        style={{ borderRadius: 50, width: 40, height: 40 }}
        onClick={goToPrevSlide}
      >
        &#10094;
      </button>
      <img src={images[currentIndex].link} alt={`Slide ${currentIndex + 1}`} />
      <button
        className="p-2 m-2 border-0"
        style={{ borderRadius: 50, width: 40, height: 40 }}
        onClick={goToNextSlide}
      >
        &#10095;
      </button>
    </div>
  );
};

export default ImageSlider;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SupplierContext from "../../context/GetSupplier/SupplierContext";
import logo from "../../mylogo.png";
import Search_Query from "../../componant/search_query/Search_Query";
import { CartButton } from "../../componant/MyButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { CartContext } from "../../context/Catalog/CatalogContext";
import { v4 as uuidv4 } from "uuid";
export default function CreateOrder(props) {
  const params = useParams();
  const { type, id } = params;

  const context = useContext(SupplierContext);
  const {
    totalPro,
    filterCategory,
    getCreateOrderCateogy,
    allSupplierProducts,
    getSupplierproducts,
    setSupplierProducts,
    addtoCart,
    getCompanyData,
    getToken,
  } = context;
  const [preloader, setPreloader] = useState("");

  const navigator = useNavigate();
  useEffect(() => {
    const getData = async () => {
      if (type === "supplier") {
        const user_data = JSON.parse(localStorage.getItem("user_data"));
        console.log("user data = ", user_data);
        const res = await getToken(id);

        getSupplierproducts(false, user_data._id, 1, null, null, "", res.token);
        getCreateOrderCateogy(user_data._id, res.token);
      } else if (type === "customer") {
        getSupplierproducts(false, id);
        getCreateOrderCateogy(id);
      }
    };

    getData();
  }, []);

  const [query, setQuery] = useState("");

  const updateProductPrice = (value, id, p_id, cat_id) => {
    const updatedat = allSupplierProducts.map((pro) => {
      return pro.product_data._id === p_id && pro.catdata._id === cat_id
        ? { ...pro, price: value, attr_id: id }
        : pro;
    });
    setSupplierProducts(updatedat);
  };

  // add to card code is here

  // const [qty, setQty] = useState("2");
  const [mycart, setMycart] = useState({ pro: {}, type: "", key: "", qty: "" });
  const onQtyChange = (e) => {
    setMycart({ ...mycart, qty: e.target.value });
  };

  const setAttrPrice = async (type, key, pro) => {
    setMycart({
      ...mycart,
      product_id: pro.product_data._id,
      catlog_id: pro.catdata._id,

      type,
      key,
      price: pro.price,
    });
  };

  const addToCart = async (e, pro) => {
    e.preventDefault();
    mycart.qty === ""
      ? props.showAlert("Please enter quantity", "warning")
      : mycart.type === "" || mycart.key === ""
      ? props.showAlert("Please Choose Price", "warning")
      : afterValidationCartadd();
  };

  const afterValidationCartadd = async () => {
    setPreloader("preShow");
    console.log(mycart);
    let cart = await addtoCart(mycart);
    console.log(cart);
    if (cart === "Success") {
      props.showAlert("Product has been Added to cart", "success");
    } else {
      props.showAlert("Something Went Wrong", "danger");
    }
    setPreloader("");
  };

  const [selectedFilterCategory, setFilterCategory] = useState(null);
  const [activeCat, setActiveCat] = useState("all");
  const [activeSubCat, setActiveSubCat] = useState(null);

  useEffect(() => {
    if (selectedFilterCategory !== null) {
      setPage(1);
      getSupplierproducts(
        false,
        id,
        1,
        selectedFilterCategory._id,
        null,
        query
      );
    } else {
      getSupplierproducts(false, id, 1);
    }
  }, [selectedFilterCategory]);

  useEffect(() => {
    if (activeSubCat !== null) {
      setPage(1);
      getSupplierproducts(
        false,
        id,
        1,
        selectedFilterCategory._id,
        activeSubCat,
        query
      );
    }
  }, [activeSubCat]);

  useEffect(() => {
    setPage(1);
    getSupplierproducts(
      false,
      id,
      1,
      selectedFilterCategory ? selectedFilterCategory._id : null,
      activeSubCat,
      query
    );
  }, [query]);

  const [page, setPage] = useState(1);

  const fetchMoreData = () => {
    setPage(page + 8);
    getSupplierproducts(
      true,
      id,
      page + 8,
      selectedFilterCategory ? selectedFilterCategory._id : null,
      activeSubCat,
      query
    );
  };

  // cart function

  const [showCartBtn, setShowCartBtn] = useState(false);
  const [cartViewData, setCartViewData] = useState({
    total_items: 0,
    total_amt: 0,
  });

  const cartCon = useContext(CartContext);
  const {
    productArray,
    syncData,
    setSyncData,
    getCartData,
    syncFromServer,
    comUpdateServer,
    addCart,
  } = cartCon;

  // get old data
  const user_data = JSON.parse(localStorage.getItem("user_data"));

  useEffect(() => {
    console.log("view is attach");
    const fetchCartData = async () => {
      const sync = await syncData();
      console.log("sync status = ", sync);
      if (sync === null || sync === false) {
        await syncFromServer(localStorage.getItem("token"));
      } else {
        const res = await getCartData();
        console.log("sync data 2 - ", productArray);
        if (res !== true) {
        } else {
        }
      }
    };

    fetchCartData();
    return () => {
      console.log("view is unattached");
      // localStorage.removeItem("p_data");
    };
  }, []);

  useEffect(() => {
    if (productArray.length > 0) {
      let total = 0,
        price = 0,
        total_item = 0;

      for (let i = 0; i < productArray.length; i++) {
        const obj = productArray[i];
        if (obj.userid === user_data._id) {
          const productData = obj.product_id;
          const catlog_data = obj.catlog_id;

          let localPrice = productData.price;
          if (obj.attr_id !== undefined) {
            localPrice = parseFloat(
              productData.p_price.filter(
                (price) => price._id === obj.attr_id
              )[0].value
            );
          }
          if (obj.sp_rate !== null && obj.sp_rate !== "") {
            const spdata = obj.sp_rate;
            catlog_data.discount = parseFloat(spdata.discount);
            catlog_data.flattDiscount = spdata.flattDiscount;
          }

          if (catlog_data.flattDiscount === true) {
            price = parseFloat(localPrice) - parseFloat(catlog_data.discount);
          } else {
            price =
              (parseFloat(localPrice) *
                (100 - parseInt(catlog_data.discount))) /
              100;
          }

          switch (obj.type) {
            case "mpkg":
              price =
                price *
                parseFloat(productData.mpkg) *
                parseFloat(productData.pkg) *
                parseInt(obj.qty);
              break;
            case "pkg":
              price = price * parseFloat(productData.pkg) * parseInt(obj.qty);
              break;
            default:
              price = price * parseInt(obj.qty);
              break;
          }

          total_item += parseInt(obj.qty);
          total += price;
        }
      }

      if (total_item > 0) {
        setShowCartBtn(true);
      } else {
        setShowCartBtn(false);
      }
      setCartViewData({ total_items: total_item, total_amt: total });
    }
  }, [productArray]);

  useEffect(() => {
    if (allSupplierProducts.length > 0 && productArray.length > 0) {
      const updatedProducts = allSupplierProducts.map((pro) => {
        const matchingCart = productArray.filter(
          (cart) => cart.product_id._id === pro.product_data._id
        );
        console.log("matching  - \n", matchingCart);
        let res = { ...pro };
        if (matchingCart.length > 0) {
          matchingCart.forEach((cart, index) => {
            console.log("cart type = ", cart.type);
            if (cart.type === "pkg") {
              res.cart_qty1 = cart.qty;
              res.showBtn1 = cart.type;
            } else if (cart.type === "mpkg") {
              res.cart_qty2 = cart.qty;
              res.showBtn2 = cart.type;
            } else {
              res.cart_qty = cart.qty;
              res.showBtn = cart.type;
            }
            // return {
            //   ...pro,
            //   cart_qty: cart.qty,
            //   showBtn: cart.type,
            // };
          });
          return res;
        }
        return pro;
      });

      console.log("update products - \n", updatedProducts);

      setSupplierProducts((prevProducts) => {
        const isSame =
          JSON.stringify(prevProducts) === JSON.stringify(updatedProducts);
        return isSame ? prevProducts : updatedProducts;
      });
    }
  }, [allSupplierProducts, productArray]);

  const sendToCart = (
    type,
    price,
    key,
    qty,
    product,
    catlog,
    attr_id,
    sp_rate
  ) => {
    try {
      if (type === "") {
        props.showAlert("Please choose type", "warning");
      } else if (price === "") {
        props.showAlert("Please choose price", "warning");
      } else if (key === "") {
        props.showAlert("Please choose key", "warning");
      } else if (qty === "") {
        props.showAlert("Please choose qty", "warning");
      } else {
        const newUUid = uuidv4();
        const data = {
          catlog_id: catlog,
          product_id: product,
          attr_id: attr_id,
          sp_rate: sp_rate,
          userid: user_data._id,
          createdAt: "",
          add_to_server: false,
          update_qty: false,
          _id: newUUid,
          type: type,
          price: price,
          key: key,
          qty: qty,
        };

        addCart(data);

        // const updateCart = allSupplierProducts.map((pro, i) => {
        //   return pro.product_data._id === product._id;
        // });

        // setMycart({
        //   ...mycart,
        //   product_id: pro.product_data._id,
        //   catlog_id: pro.catdata._id,

        //   type,
        //   key,
        //   price: pro.price,
        // });
      }
    } catch (error) {
      console.log("error - ", error);
    }
  };

  // cart function

  return (
    <>
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button"></div>

          <div className="row mb-4">
            <div className="col-lg-6 ">
              <span className="supplier_name">
                {allSupplierProducts.length !== 0
                  ? allSupplierProducts[0].catdata.userid.fullname
                  : "---"}
              </span>
              <small className="p-0  product_brand">
                {" "}
                <i className="bx bxs-circle bullet"></i> Total Product ({" "}
                {totalPro} )
              </small>
            </div>
            <div className="col-lg-6 text-right">
              <input
                type="text"
                className="form-control search m-0 d-none"
                placeholder="Search product by name and category"
                onChange={null}
              />
            </div>
            <div className="col-lg-12 mt-4 category-filter">
              <div
                className="d-flex  align-items-center w-100 category_list_div"
                style={{ overflowX: "auto" }}
              >
                <small
                  className="p-0 m-0 product_brand mb-2 me-2"
                  style={{ textWrap: "nowrap" }}
                >
                  Choose Category -{" "}
                </small>

                <span
                  className={`variation me-2 mb-2 ${
                    "all" === activeCat && "active"
                  }`}
                  style={{ fontSize: "0.835em" }}
                  onClick={() => {
                    setFilterCategory(null);
                    setActiveCat("all");
                    setActiveSubCat(null);
                  }}
                >
                  All
                </span>
                {filterCategory.map((cat, index) => {
                  return (
                    <span
                      key={index}
                      className={`variation me-2 mb-2 ${
                        cat.category._id === activeCat && "active"
                      }`}
                      style={{
                        fontSize: "0.835em",
                        width: "fit-content",
                        textWrap: "nowrap",
                      }}
                      onClick={() => {
                        setFilterCategory(cat.category);
                        setActiveCat(cat.category._id);
                      }}
                    >
                      {cat.category.cat_name}
                    </span>
                  );
                })}
              </div>
              {selectedFilterCategory && (
                <div
                  className="d-flex  align-items-center w-100 my-2 category_list_div"
                  style={{ overflowX: "auto" }}
                >
                  <small
                    className="p-0 m-0 product_brand mb-2 me-2"
                    style={{ textWrap: "nowrap" }}
                  >
                    Choose Sub Category -{" "}
                  </small>

                  {selectedFilterCategory.sub_items.map((cat, index) => {
                    return (
                      <span
                        className={`variation me-2 mb-2 ${
                          cat._id === activeSubCat ? "active" : ""
                        }`}
                        style={{
                          fontSize: "0.835em",
                          width: "fit-content",
                          textWrap: "nowrap",
                        }}
                        onClick={() => {
                          setActiveSubCat(cat._id);
                        }}
                      >
                        {cat.name}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="col-lg-6 font-bold"></div>
          </div>

          {/* product table start from here */}
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div className="card">
                <h5 className="card-header">
                  <div className="row align-items-center">
                    <div className="col-lg-7 font-bold"></div>
                    <div className="col-lg-12 text-right">
                      <Search_Query
                        value={query}
                        show={query !== ""}
                        placeholder="Search by products Name etc ..."
                        onQuery={(value) => setQuery(value)}
                        clear_data={() => {
                          setQuery("");
                          // fetchMoreData();
                        }}
                        search_click={() => {
                          // fetchMoreData();
                        }}
                      />
                    </div>
                  </div>
                </h5>

                <InfiniteScroll
                  hasMore={true}
                  dataLength={allSupplierProducts.length}
                  next={fetchMoreData}
                  loader={<h4></h4>}
                >
                  <div className="table-responsive text-nowrap mb-1 ">
                    <table className="table table-hover product_table">
                      <thead className="product_table_header">
                        <tr>
                          <th>Product</th>
                          <th>Choose Variation</th>
                          <th>Choose Pricing/Packaging</th>
                        </tr>
                      </thead>
                      <tbody className="product_table_body">
                        {allSupplierProducts.length > 0 &&
                          allSupplierProducts.map((pro, index) => {
                            const m_price =
                              pro.product_data.p_price &&
                              pro.product_data.p_price.length > 0
                                ? pro.attr_id !== ""
                                  ? pro.attr_id !== undefined &&
                                    pro.product_data.p_price.some(
                                      (p) => p._id === pro.attr_id
                                    )
                                    ? pro.product_data.p_price.filter(
                                        (p) => p._id === pro.attr_id
                                      )[0].value
                                    : parseFloat(
                                        pro.product_data.p_price[0].value
                                      )
                                  : parseFloat(pro.product_data.price)
                                : parseFloat(pro.product_data.price);

                            let catlog = pro.catdata;
                            if (pro.sp !== null && pro.sp !== "") {
                              const spdata = pro.sp;
                              catlog.discount = parseFloat(spdata.discount);
                              catlog.flattDiscount = spdata.flattDiscount;
                            }

                            let pcs_mrp = m_price;
                            let pkg_mrp = m_price * pro.product_data.pkg;
                            let mpkg_mrp =
                              m_price *
                              pro.product_data.pkg *
                              pro.product_data.mpkg;
                            let pkg_level = pro.product_data.pkg_level;
                            let moq_level = "";

                            if (pkg_level == 111 || pkg_level == 0) {
                              moq_level = pro.product_data.unit_name;
                            } else if (pkg_level == 211) {
                              moq_level = pro.product_data.pkg_unit;
                            } else if (pkg_level == 221) {
                              moq_level = pro.product_data.mpkg_unit;
                            } else {
                              moq_level = "null";
                            }

                            let discounted_price = catlog.flattDiscount
                              ? m_price - parseFloat(catlog.discount)
                              : (m_price *
                                  (100 - parseFloat(catlog.discount))) /
                                100;

                            let pcs_price = discounted_price.toFixed(2);

                            let pkg_price = (
                              discounted_price * pro.product_data.pkg
                            ).toFixed(2);

                            let mpkg_price = (
                              discounted_price *
                              pro.product_data.pkg *
                              pro.product_data.mpkg
                            ).toFixed(2);

                            let showUnit = false,
                              unitCartQty = 0;
                            let showPkg = false;
                            let showMpkg = false;

                            if (productArray.length !== 0) {
                              productArray.map((cart, index) => {
                                const type = cart.type;

                                if (cart.product_id._id !== "") {
                                  if (
                                    cart.product_id._id === pro.product_data._id
                                  ) {
                                    if (type === "unit") {
                                      showUnit = true;
                                      unitCartQty = cart.qty;
                                    } else if (type === "pkg") {
                                      showPkg = true;
                                    } else if (type === "mpkg") {
                                      showMpkg = true;
                                    }
                                  }
                                }
                              });
                            }

                            return (
                              <tr>
                                <td>
                                  <div className="row ">
                                    <div className="col-lg-5">
                                      <img
                                        src={
                                          pro.product_data.p_gallery_image
                                            .length > 0
                                            ? pro.product_data
                                                .p_gallery_image[0].link
                                            : logo
                                        }
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="col-lg-7">
                                      <span className="product_title m-0">
                                        {pro.product_data.p_name}
                                      </span>
                                      <small className="p-0  product_brand">
                                        {" "}
                                        {pro.product_data.brand}
                                      </small>
                                      {/* <small className="p-0 m-0 product_mrp">
                                    MRP. <del>₹{pcs_mrp}</del>{" "}
                                    <span
                                      className="font-bold"
                                      style={{ color: "black" }}
                                    >
                                      ₹164
                                    </span>{" "}
                                    / piece
                                  </small> */}

                                      <span className="product_desc">
                                        {pro.product_data.description}
                                      </span>

                                      <span className="">
                                        MOQ ( {pro.product_data.m_o_q}{" "}
                                        {moq_level})
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td className="myattrcell">
                                  {pro.product_data.p_price.length === 0 ? (
                                    "Attribute N/A"
                                  ) : (
                                    <ul
                                      className="d-flex flex-wrap m-0 p-0"
                                      style={{ listStyle: "none" }}
                                    >
                                      {pro.product_data.p_price.map(
                                        (attprice, index) => {
                                          return (
                                            <li
                                              className={`variationList me-2 `}
                                            >
                                              <span
                                                onClick={() => {
                                                  updateProductPrice(
                                                    attprice.value,
                                                    attprice._id,
                                                    pro.product_data._id,
                                                    pro.catdata._id
                                                  );
                                                }}
                                                className={`variation  ${
                                                  attprice._id === pro.attr_id
                                                    ? "active"
                                                    : ""
                                                } `}
                                              >
                                                {attprice.name}
                                              </span>
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  )}
                                </td>
                                <td>
                                  <div className="row">
                                    <div className="col-lg-8">
                                      <ul className="pricingList">
                                        <li
                                          onClick={() => {
                                            setAttrPrice(
                                              "unit",
                                              pro.product_data.unit_name,
                                              pro
                                            );
                                          }}
                                        >
                                          <strike>
                                            ₹{parseFloat(pcs_mrp).toFixed(2)}
                                          </strike>{" "}
                                          <span
                                            className="font-bold"
                                            style={{ color: "black" }}
                                          >
                                            ₹{parseFloat(pcs_price).toFixed(2)}{" "}
                                            /{" "}
                                          </span>
                                          <small>
                                            {pro.product_data.unit_value}{" "}
                                            {pro.product_data.unit_name}
                                          </small>
                                        </li>

                                        <li
                                          onClick={() => {
                                            setAttrPrice(
                                              "pkg",
                                              pro.product_data.pkg_unit,
                                              pro
                                            );
                                          }}
                                        >
                                          <strike>
                                            ₹{parseFloat(pkg_mrp).toFixed(2)}
                                          </strike>{" "}
                                          <span
                                            className="font-bold"
                                            style={{ color: "black" }}
                                          >
                                            ₹{parseFloat(pkg_price).toFixed(2)}{" "}
                                            /{" "}
                                          </span>
                                          <small>
                                            {pro.product_data.pkg_unit +
                                              "(" +
                                              pro.product_data.pkg +
                                              " " +
                                              pro.product_data.unit_name +
                                              ")"}
                                          </small>
                                        </li>

                                        {parseInt(pro.product_data.mpkg) !==
                                          0 && (
                                          <li
                                            onClick={() => {
                                              setAttrPrice(
                                                "mpkg",
                                                pro.product_data.mpkg_unit,
                                                pro
                                              );
                                            }}
                                          >
                                            <strike>
                                              ₹{parseFloat(mpkg_mrp).toFixed(2)}
                                            </strike>{" "}
                                            <span
                                              className="font-bold"
                                              style={{ color: "black" }}
                                            >
                                              ₹
                                              {parseFloat(mpkg_price).toFixed(
                                                2
                                              )}{" "}
                                              /{" "}
                                            </span>
                                            <small>
                                              {pro.product_data.mpkg +
                                                " " +
                                                pro.product_data.mpkg_unit}
                                            </small>
                                          </li>
                                        )}
                                      </ul>
                                    </div>

                                    <div className="col-lg-4 align-items-center d-flex">
                                      <ul className="pricingList">
                                        <li style={{ background: "none" }}>
                                          <CartButton
                                            show={
                                              pro.showBtn !== undefined &&
                                              pro.showBtn === "unit"
                                            }
                                            callback={(type, value) => {
                                              if (type === "addCart") {
                                                sendToCart(
                                                  "unit",
                                                  pcs_mrp,
                                                  pro.product_data.unit_name,
                                                  1,
                                                  pro.product_data,
                                                  pro.catdata,
                                                  pro.attr_id,
                                                  pro.sp
                                                );
                                              } else if (type === "min") {
                                                unitCartQty -= 1;
                                              }
                                            }}
                                            value={pro.cart_qty}
                                          />
                                        </li>
                                        <li style={{ background: "none" }}>
                                          <CartButton
                                            show={
                                              pro.showBtn1 !== undefined &&
                                              pro.showBtn1 === "pkg"
                                            }
                                            callback={(type, value) => {
                                              sendToCart(
                                                "pkg",
                                                pcs_mrp,
                                                pro.product_data.pkg_unit,
                                                1,
                                                pro.product_data,
                                                pro.catdata,
                                                pro.attr_id,
                                                pro.sp
                                              );
                                            }}
                                            value={pro.cart_qty1}
                                          />
                                        </li>
                                        <li style={{ background: "none" }}>
                                          <CartButton
                                            show={
                                              pro.showBtn2 !== undefined &&
                                              pro.showBtn2 === "mpkg"
                                            }
                                            callback={(type, value) => {
                                              sendToCart(
                                                "mpkg",
                                                pcs_mrp,
                                                pro.product_data.mpkg_unit,
                                                1,
                                                pro.product_data,
                                                pro.catdata,
                                                pro.attr_id,
                                                pro.sp
                                              );
                                            }}
                                            value={pro.cart_qty2}
                                          />
                                        </li>
                                      </ul>

                                      <form method="post" className="d-none">
                                        <div className="input-group product_qty">
                                          <input
                                            type="number"
                                            className="form-control product_no"
                                            aria-label=""
                                            placeholder="QTY"
                                            name="qty"
                                            defaultValue={1}
                                            onChange={onQtyChange}
                                          />
                                          <button
                                            className="btn btn-outline-primary "
                                            type="submit"
                                            onClick={(e) => {
                                              addToCart(e, pro);
                                            }}
                                          >
                                            Add
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>

          {/* product table start from here */}

          {/* cart view */}
          {showCartBtn && (
            <div
              className=""
              style={{
                position: "fixed",
                top: "90%",
                zIndex: 9,
                background: "#ef6817",
                width: "80%",
                borderRadius: "5px",
                padding: "10px 15px",
                color: "white",
              }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <small className="d-block" style={{ fontSize: "12px" }}>
                    {cartViewData.total_items} items
                  </small>
                  <span style={{ fontWeight: "bold" }}>
                    ₹{parseFloat(cartViewData.total_amt).toFixed(2)}
                  </span>
                </div>

                <div className="col-lg-6 text-right d-flex justify-content-end align-items-center">
                  <span
                    style={{ fontWeight: "bold" }}
                    onClick={async () => {
                      // const res = await comUpdateServer(
                      //   localStorage.getItem("token")
                      // );
                      // if (res === true) {
                      //   localStorage.removeItem("p_data");
                      //   navigator("/mycart");
                      // } else {
                      //   props.showAlert(
                      //     "Something went wrong! Please try again.",
                      //     "warning"
                      //   );
                      // }
                      // navigator("/mycart");
                      localStorage.removeItem("p_data");
                    }}
                  >
                    View Cart >
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react";

const Profile = () => {
  const [changePasswordDiv, showChangePasswordDiv] = useState(false);

  const tooglePasswordShow = () => {
    if (changePasswordDiv) {
    } else {
      showChangePasswordDiv(true);
    }
  };

  const userData = JSON.parse(localStorage.getItem("user_data"));

  return (
    <div className="content-wrapper">
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-12">
            <h4 className="card-header c_heading px-0 pt-0">
              <div className="row">
                <div className="col-lg-3">
                  <span className="headingcontent">Profile</span>
                </div>
              </div>
            </h4>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-body ">
                <div className="row">
                  <div className="col-6">
                    <div class="form-group w-100 mb-2">
                      <label for="">FULL NAME *</label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder="Enter full name"
                        value={userData.fullname}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div class="form-group w-100 mb-3">
                      <label for="">PHONE NUMBER *</label>
                      <input
                        type="number"
                        class="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder="Enter full name"
                        value={parseInt(userData.phone)}
                      />
                      <small className="text-muted">
                        You can't edit or delete your phone number. It will lead
                        to deactivation of your account.
                      </small>
                    </div>
                  </div>
                </div>
                {changePasswordDiv && (
                  <>
                    <h5 for="">Change Password</h5>
                    <div class="form-group w-100 mb-2">
                      <label for="">OLD PASSWORD *</label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder="Enter old password"
                      />
                    </div>

                    <div class="form-group w-100 mb-2">
                      <label for="">NEW PASSWORD *</label>
                      <input
                        type="text"
                        class="form-control"
                        name=""
                        id=""
                        aria-describedby="helpId"
                        placeholder="Enter full name"
                      />
                    </div>
                  </>
                )}
                <button
                  className="my-btn orange-btn align-self-end "
                  onClick={tooglePasswordShow}
                >
                  {changePasswordDiv === true ? "Save" : "Change Password"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

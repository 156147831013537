import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MyButton from "../../componant/MyButton";
import catalogContext from "../../context/Catalog/CatalogContext";
import SupplierContext from "../../context/GetSupplier/SupplierContext";
import Createorder from "./CreateOrder";

export default function Customer_supplier(props) {
  
  const context = useContext(SupplierContext);
  const { allSuppliers, getSuppliers } = context;

  useEffect(() => {
    // getCustomerCatalog("6370c4df1200a9600d514871");
    getSuppliers();
    console.log(allSuppliers);
  }, []);

  

  return (
    <>
      
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <div className="fixed-button"></div>
            <div className="row">
              <h4 className="card-header c_heading">
                <span className="headingcontent">My Supplier</span>

                <div className="heading_buttons"></div>
              </h4>

              <div className="col-lg-12 mb-4 ">
                <div className="card">
                  <h5 className="card-header">
                    <div className="row">
                      <div className="col-lg-6 text-right">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Search by Staff Name, Roles, Phone No., Staff Id , etc..."
                        />
                      </div>

                      <div className="col-lg-6 font-bold">
                        <MyButton />
                      </div>
                    </div>
                  </h5>

                  <div className="table-responsive text-nowrap mb-1">
                    <table className="table table-hover">
                      <thead className="order_history_header">
                        <tr>
                          <th>Profile Pic.</th>
                          <th>Name</th>
                          <th>Company</th>
                          <th>Phone</th>
                          <th>Address</th>
                          <th>Out Standing Balance</th>
                          <th className="action">Action</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0 order_history_body">
                        {allSuppliers.map((sup) => {
                          return (
                            <tr key={sup._id}>
                              <td>
                                <img
                                  src="../assets/img/avatars/5.png"
                                  alt="Avatar"
                                  width="60"
                                  height="60"
                                  className="rounded-circle customer_image my-auto"
                                />
                              </td>
                              <td>{sup.fullname}</td>
                              <td>{sup.createBy}</td>
                              <td>{sup.phone}</td>
                              <td>{sup.address}</td>
                              <td>
                                <span
                                  className="badge rounded-pill bg-label-success w-100"
                                  style={{ fontSize: "1.4em" }}
                                >
                                  5600
                                </span>
                              </td>
                              <td>
                                <Link
                                to={{pathname:`/createorder/customer/${sup._id}`}}
                                  
                                  className="btn btn-primary placebutton orange-btn  me-2 mb-2"
                                >
                                  Create Order
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
}

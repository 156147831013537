import React, { useState } from "react";
import StaffContext from "./StaffContext";
//require("dotenv").config();
const StaffState = (props) => {
  const host = process.env.REACT_APP_URL;
  const Staffs = [];
  const [StaffData, setStaff] = useState(Staffs);
  const getAllStaff = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/staff/getStaff/${localStorage.getItem("com_id")}`,
      requestOptions
    );
    const datares = await response.json();

    // console.log(datares);
    // console.log(localStorage.getItem("com_id"));
    setStaff(datares.data);
  };

  //   addnew product
  const addStaff = async (com_name) => {
    try {
      const bodydata = com_name;
      //   console.log(bodydata);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/staff/createStaff`,
        requestOptions
      );
      const datares = await response.json();

      const comp = datares.data;
      setStaff(StaffData.concat(comp));
      return datares;
    } catch (e) {
      console.log(e.message);
    }
  };



  return (
    <StaffContext.Provider value={{ StaffData, getAllStaff, addStaff }}>
      {props.children}
    </StaffContext.Provider>
  );
};
export default StaffState;

import React, { useState, useContext, useEffect } from "react";

import { Link, Navigate } from "react-router-dom";
import staffContext from "../context/Staff/StaffContext";
import { AddNewStaff } from "../section/AddNewStaff";
import AddNewOffca from "./AddNewOffcanvas";

export const StaffData = (props) => {
  // data from custormercontext

  const showalert = (message, type) => {
    props.showAlert(message, type);
  };

  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }
  const context = useContext(staffContext);
  const { StaffData, getAllStaff } = context;

  const [newStaff, setNewStaff] = useState({
    phone: "",
    name: "",
    com_id: localStorage.getItem("com_id"),
    email: "",
    staff_id: "",
    password: "",
    role: "",
  });

  const [newRole, setRole] = useState({});

  useEffect(() => {
    getAllStaff();
  }, []);

  const uploadCustomer = async (e) => {
    e.preventDefault();
    // console.log("clicked");
    // const restr = await addCustomer(newCustomer);
    // console.log(restr);
    // if(restr.status === "Failed"){
    //   showalert(restr.error, "danger");
    // }else{
    //   showalert("Customer has been added", "success");
    //   openData();
    // }
  };

  const inputFuction = (e) => {
    setNewStaff({ ...newStaff, [e.target.name]: e.target.value });
  };

  const [modal, setModal] = useState(false);
  const [ManageRole, setManageRole] = useState(false);

  const style = {
    background: "#00000080",
    display: "block",
  };

  const openData = () => {
    setModal(!modal);
  };

  const openManageRole = () => {
    setManageRole(!ManageRole);
  };

  const [query, setQuery] = useState("")

  return (
    <>
      {/* modal add customer */}

      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Create Staff
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form method="POST">
                  <table width="100%">
                    <tr>
                      <td>
                        <div class="form-group mb-3">
                          <label htmlFor="name" className="col-form-label">
                            User Role :
                          </label>
                          <select class="form-control" name="" id="">
                            <option></option>
                            <option></option>
                            <option></option>
                          </select>
                        </div>
                      </td>
                      <td>
                        <div className="mb-3">
                          <label htmlFor="phone" className="col-form-label">
                            Staff Phone No.
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Customer Phone No."
                            onChange={inputFuction}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="mb-3">
                          <label htmlFor="phone" className="col-form-label">
                            Staff Email :
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Customer Phone No."
                            onChange={inputFuction}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="mb-3">
                          <label htmlFor="name" className="col-form-label">
                            Staff Name :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            placeholder="Customer Name"
                            onChange={inputFuction}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="mb-3">
                          <label htmlFor="phone" className="col-form-label">
                            Staff ID :
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Customer Phone No."
                            onChange={inputFuction}
                          />
                        </div>
                      </td>

                      <td>
                        <div className="mb-3">
                          <label htmlFor="phone" className="col-form-label">
                            Password :
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Customer Phone No."
                            onChange={inputFuction}
                          />
                        </div>
                      </td>
                    </tr>
                  </table>
                </form>

                <p className="instruction_para">Instruction</p>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary orange-btn me-2 mb-2"
                  onClick={uploadCustomer}
                >
                  Create Now
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {ManageRole && <AddNewStaff openManageRole={openManageRole} style={style} />} */}
      {ManageRole && (
        <AddNewOffca show="show" show_dalog="show" close={openManageRole} />
      )}

      {/* modal add customer */}
      <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className="btn btn-sm fixed_button btn-outline-primary"
              onClick={openManageRole}
            >
              + Add Staff
            </div>

            {/* s */}
          </div>
          <div className="row">
            <h4 className="card-header c_heading">
              <span className="headingcontent">
                My Staff ({StaffData.length})
              </span>

              <div className="heading_buttons"></div>
            </h4>
            {/* <input
              className="form-control search mb-4"
              placeholder="Search Customers..."
            /> */}

            {/* table layout */}
            <div className="col-lg-12 mb-4 ">
              <div class="card">
                <h5 class="card-header">
                  <div className="row">
                    <div className="col-lg-6 font-bold">
                      <ul className="slider_button slider-white plain-orange">
                        <li className="slider-active">Export</li>
                        <li className="slider-active mx-2">Print</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 text-right">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search by Staff Name, Roles, Phone No., Staff Id , etc..."
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>
                  </div>
                </h5>

                <div class="table-responsive text-nowrap mb-1">
                  <table class="table table-hover">
                    <thead className="order_history_header">
                      <tr>
                        <th>Profile Pic.</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Phone</th>
                        <th>Staff ID</th>
                        <th>Password</th>
                        <th>Track</th>
                        <th>Status</th>
                        <th className="action">Action</th>
                      </tr>
                    </thead>
                    <tbody class="table-border-bottom-0 order_history_body">
                      <tr>
                        <td>
                          <img
                            src="../assets/img/avatars/5.png"
                            alt="Avatar"
                            width="60"
                            height="60"
                            className="rounded-circle customer_image my-auto"
                          />
                        </td>
                        <td>Vishant Soni</td>
                        <td>Stock Manager</td>
                        <td>6396617714</td>
                        <td>vishu_5487</td>
                        <td>123456</td>
                        <td>Track</td>
                        <td>
                          <span class="badge rounded-pill bg-label-success">
                            Active
                          </span>
                        </td>
                        <td className="action">
                          {/* <div class="demo-inline-spacing"> */}
                          <span class="badge badge-secondary my-delete">
                            <i className="bx bx-block "></i>
                          </span>
                          <span class="badge badge-secondary mx-2 my-delete">
                            <i className="bx bx-edit-alt "></i>
                          </span>
                          <span class="badge badge-secondary my-delete">
                            <i className="bx bx-trash-alt "></i>
                          </span>

                          {/* </div> */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* table layout */}

            {StaffData.length === 0 && "No Staff Found"}
            {StaffData.filter((item) =>
              item.name.toLowerCase().toString().includes(query)
            )
              .map((c, i) => {
                return (
                  <div className="col-md-12 mx-auto my-1" key={i}>
                    <div className="card  p-0">
                      <div className="card-header p-2">
                        <img
                          src="../assets/img/avatars/5.png"
                          alt="Avatar"
                          width="60"
                          height="60"
                          className="rounded-circle customer_image my-auto"
                        />
                        <div className="mycustomerData">
                          <ul className="mycustomerlist">
                            <li>
                              {c.name}
                              <span className="com_name">({c.staff_id})</span>
                            </li>
                            <li>{c.phone}</li>
                            <li>{c.password}</li>
                            <li>
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="btn p-0 dropdown-toggle hide-arrow"
                                  data-bs-toggle="dropdown"
                                >
                                  <i className="bx bx-dots-vertical-rounded"></i>
                                </button>
                                <div className="dropdown-menu">
                                  <p className="dropdown-item">
                                    <i className="bx bx-file-alt me-1"></i> Create
                                    Order
                                  </p>

                                  <p className="dropdown-item">
                                    <i className="bx bx-file-alt me-1"></i>{" "}
                                    Catalog Assigned
                                  </p>

                                  <p className="dropdown-item">
                                    <i className="bx bx-file-alt me-1"></i>{" "}
                                    Payments
                                  </p>

                                  <p className="dropdown-item">
                                    <i className="bx bx-file-alt me-1"></i>{" "}
                                    Reports
                                  </p>

                                  <p className="dropdown-item">
                                    <i className="bx bx-edit-alt me-1"></i> Edit
                                  </p>

                                  <p className="dropdown-item">
                                    <i className="bx bx-block me-1"></i> Block
                                  </p>

                                  <p className="dropdown-item">
                                    <i className="bx bx-trash me-1"></i> Delete
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

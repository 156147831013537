import React from "react";
export default function ConfirmAlert({conAlert, showCon}) {

  const mystyle = {
    "content":{
      "color":"black",
      "font-family":"pop_regular"
    },
    "heading":{
      "color":"black",
      "font-family":"pop_bold"
    },
    "button_container":{
      "border-top":"1px solid gray"
    }

  }

  return (
    <>
    {conAlert.open == true &&
      <div className={`modal reviewModal`} style={{
        background: "#00000080",
        display: "block",
      }}>
        <div className="modal-dialog" style={{ width: "30em" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" style={mystyle.heading}> 
                Alert!
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=>{showCon("", false, {})}}
              ></button>
            </div>
            <div className="modal-body">
            <p className="confirmcontent p-0 m-0" style={mystyle.content}>{conAlert.heading}</p>
            </div>
            <div className="row " style={mystyle.button_container}>
              <div className="col-md-6 text-center" style={{"border-right":"1px solid gray"}}>
              <p className="my-btn btn mx-auto m-0 p-4" onClick={()=>{showCon("", false, {})}}>Cancel</p>
              </div>
              <div className="col-md-6 mr-0 text-center">
                
                <p className=" btn  m-0 p-4" style={{color:"black"}} onClick={conAlert.confirm}>Ok</p>
              </div>
            </div>
          </div>
        </div>
      </div>
}
    </>
  );

  


}


import React, { useEffect, useState } from "react";
import Preloader from "../componant/Preloader";
import { Navigate } from "react-router-dom";
//require("dotenv").config();
export default function RolesPermission(props) {
  const host = process.env.REACT_APP_URL;
  const [modal, setModal] = useState(false);
  const [myRole, setMyRoles] = useState([]);
  const [preloader, setPreloader] = useState("");
  useEffect(() => {
    getAllRoles();
  }, []);

  // if (localStorage.getItem("com_id") === null) {
  //   return <Navigate to="/mycompanies" />;
  // }
  const getAllRoles = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    };
    const response = await fetch(
      `${host}/api/roles/getRole/${localStorage.getItem("com_id")}`,
      requestOptions
    );
    const datares = await response.json();

    //console.log(datares.data);
    setMyRoles(datares.data);
  };

  const style = {
    background: "#00000080",
    display: "block",
  };
  const openData = () => {
    setModal(!modal);
  };

  const [newRole, setNewRole] = useState({
    com_id: localStorage.getItem("com_id"),
    role_name: "",
    access: [
      {
        type_name: "orders",
        read: false,
        write: false,
        create: false,
      },
      {
        type_name: "dispatcher",
        read: false,
        write: false,
        create: false,
      },
      {
        type_name: "inventory",
        read: false,
        write: false,
        create: false,
      },
      {
        type_name: "products",
        read: false,
        write: false,
        create: false,
      },
      {
        type_name: "customer",
        read: false,
        write: false,
        create: false,
      },
    ],
  });

  const onclickRight = (e) => {
    console.log(e);
    const id = e.target.attributes.id.nodeValue;
    const value = e.target.name;

    for (let i = 0; i < newRole.access.length; i++) {
      const element = newRole.access[i];
      if (element.type_name.toString() === id.toString()) {
        if (value.toString() === "read") {
          element.read = e.target.checked;
        }
        if (value.toString() === "write") {
          element.write = e.target.checked;
        }
        if (value.toString() === "create") {
          element.create = e.target.checked;
        }
        break;
      }
    }
  };

  const setFormData = (e) => {
    setNewRole({ ...newRole, [e.target.name]: e.target.value });
  };
  const onsubmitFinal = async (e) => {
    e.preventDefault();
    setPreloader("preShow");
    try {
      const bodydata = newRole;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(bodydata),
      };
      const response = await fetch(
        `${host}/api/roles/createRole`,
        requestOptions
      );
      const datares = await response.json();
      if (datares.status === "Success") {
        openData();
        props.showAlert(datares.msg, "success");
      } else {
        props.showAlert(datares.msg, "danger");
      }

      const comp = datares.data;
      setMyRoles(myRole.concat(comp));
      setPreloader("");
    } catch (e) {
      console.log(e.message);
    }
  };

  const crudFunction = (id, type) => {
    console.log(type);
    if (type === "delete") {
      deleleRole(id);
    }
    console.log(id);
  }

  // delete role function

  const deleleRole = async (id) => {
    try {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      };
      const url = `${host}/api/roles/deletRole/` + id;
      const response = await fetch(url, requestOptions);
      const datares = await response.json();

      if (datares.status === "Success") {
        const role = myRole.filter((com) => {
          return com._id !== id;
        });
        setMyRoles(role);
      } else {
        alert("Something wentwrong");
      }
    } catch (e) {
      console.log(e.message);
    }

  }

  // edit role function

  const EditRole = () => {

  }

  return (

    <>
      <Preloader show={preloader} />


      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header text-center">
                <h5 className="modal-title ">Add New Role</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form id="addRoleForm" className="row g-3" method="POSt">
                  <div className="col-12 mb-4">
                    <label className="form-label" htmlFor="role_name">
                      Role Name
                    </label>
                    <input
                      type="text"
                      id="role_name"
                      name="role_name"
                      className="form-control"
                      placeholder="Enter a role name"
                      tabIndex="-1"
                      onChange={setFormData}
                    />
                  </div>
                  <div className="col-12">
                    <h4>Role Permissions</h4>

                    <div className="table-responsive">
                      <table className="table table-flush-spacing">
                        <tbody>
                          <tr>
                            <td className="text-nowrap fw-semibold">
                              Administrator Access{" "}
                              <i
                                className="bx bx-info-circle bx-xs"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Allows a full access to the system"
                              ></i>
                            </td>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="selectAll"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="selectAll"
                                >
                                  Select All
                                </label>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-nowrap fw-semibold">
                              Orders{" "}
                              <i
                                className="bx bx-info-circle bx-xs"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Look after your payments and invoices"
                              ></i>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="orders"
                                    name="read"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="orders"
                                  >
                                    Read
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="orders"
                                    name="write"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="userManagementWrite"
                                  >
                                    Write
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="orders"
                                    name="create"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="userManagementCreate"
                                  >
                                    Create
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-nowrap fw-semibold">
                              Products{" "}
                              <i
                                className="bx bx-info-circle bx-xs"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Manages your inventory system"
                              ></i>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="products"
                                    name="read"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="products"
                                  >
                                    Read
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="products"
                                    name="write"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="products"
                                  >
                                    Write
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="products"
                                    name="create"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="apiCreate"
                                  >
                                    Create
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-nowrap fw-semibold">
                              Manage Orders Status{" "}
                              <i
                                className="bx bx-info-circle bx-xs"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Can look after your order status"
                              ></i>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="dispatcher"
                                    name="read"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="reportingRead"
                                  >
                                    Read
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="dispatcher"
                                    name="write"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="reportingWrite"
                                  >
                                    Write
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="dispatcher"
                                    name="create"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="reportingCreate"
                                  >
                                    Create
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td className="text-nowrap fw-semibold">
                              Inventory
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inventory"
                                    name="read"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="repoRead"
                                  >
                                    Read
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inventory"
                                    name="write"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="repoWrite"
                                  >
                                    Write
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inventory"
                                    name="create"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="repoCreate"
                                  >
                                    Create
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="text-nowrap fw-semibold">
                              Customers{" "}
                              <i
                                className="bx bx-info-circle bx-xs"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Can create orders on behalf of your customers"
                              ></i>
                            </td>
                            <td>
                              <div className="d-flex">
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="customer"
                                    name="read"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="payrollRead"
                                  >
                                    Read
                                  </label>
                                </div>
                                <div className="form-check me-3 me-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="customer"
                                    name="write"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="payrollWrite"
                                  >
                                    Write
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="customer"
                                    name="create"
                                    onChange={onclickRight}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="payrollCreate"
                                  >
                                    Create
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-8">
                    <small className="text-muted">Read - Can only view the features assigned.</small><br />
                    <small className="text-muted">Write - Can view, edit the features assigned.</small><br />
                    <small className="text-muted">Create - Can view, edit and create the new entry to the features assigned.</small>
                  </div>
                  <div className="col-4 text-right">

                    <input
                      type="submit"
                      className="btn  me-sm-3 me-1 orange-btn"
                      name="Submit"
                      value="Save"
                      onClick={onsubmitFinal}
                    />

                    <button className="btn btn-outline-primary my-btn me-2">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="content-wrapper payments">
        <div className="container-xxl flex-grow-1 container-p-y">
          <div className="fixed-button">
            <div
              className="btn btn-sm fixed_button btn-outline-primary"
              onClick={openData}
            >
              + Add New Role
            </div>

            {/* s */}
          </div>
          <h4 className="card-header c_heading">
            <span className="headingcontent">Role List ({myRole.length})</span>

            <div className="heading_buttons"></div>
          </h4>

          <p>
            A role provided access to predefined menus and features so that
            depending on assigned
            <br /> role an administrator can have access to what user needs.
          </p>

          <div className="row g-4">
            {myRole.map((role) => {
              return (
                <div className="col-xl-3 col-lg-6 col-md-6" key={role._id}>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <h6 className="fw-normal">Total 4 users</h6>
                      </div>
                      <div className="d-flex justify-content-between align-items-end">
                        <div className="role-heading">
                          <h4 className="mb-2 role_name">{role.role_name}</h4>
                          <p
                            className="role-edit-modal mt-3"
                            onClick={(e) => {
                              crudFunction(role._id, "edit")
                            }}
                          >
                            <small>Edit</small>
                          </p>
                        </div>
                        <p className="role-edit-modal mt-3"
                          onClick={(e) => {
                            crudFunction(role._id, "delete")
                          }}>
                          <small>Delete</small>
                          {/* <i className="bx bx-trash"></i> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
